@import "variables";

.table {
  border-collapse: collapse;
  & td {
    padding: 4px;
    padding-left: 15px;
    color: #7A728D !important;
    font-weight: 500;
  }
  & thead.list-table-color td {
    background-color: #54585d;
    color: $ocean-light !important;
    font-weight: bold;
    font-size: 13px;
  }
  & thead.list-table-color td {
    color: #3F394E !important;
    font-weight: 500 !important;
  }
  & thead th {
    color: $ocean-light !important;
  }
  & tbody td {
    color: #636363;
  }
  & tbody tr {
    background-color: $white;
  }

  mat-row{
    background-color: white;
    &:hover{
      background-color: rgba(195, 195, 195, 0.096) !important;
    }
  }
  
  mat-header-cell {
    border-left: 1px solid #D7D1E3 !important;
    border-top: 1px solid #D7D1E3 !important;
    border-bottom: 1px solid #D7D1E3 !important;
    font-weight: 500;
    color: $ocean-light;
    font-size: 13px;

    &:last-child {
      border-right: 1px solid #D7D1E3 !important;

    } 
    background-color: #F3EEFF !important;
    min-height: 55px;
  }
  
  mat-cell {
    min-height: 65px;
    color: #7A728D;
    font-size: 13px;
    border-left: 1px solid #D7D1E3 !important;
    border-bottom: 1px solid #D7D1E3 !important;

    &:last-child {
      border-right: 1px solid #D7D1E3 !important;

    }
  }
  thead {
    tr {
      th {
        border-left: 1px solid #D7D1E3 !important;
        border-top: 1px solid #D7D1E3 !important;
        border-bottom: 1px solid #D7D1E3 !important;
        &:last-child {
          border-right: 1px solid #D7D1E3 !important;
    
        }
        background-color: #F3EEFF !important;
        height: 55px;
      }
    }
  }
  tbody {
    tr {
      td {
        min-height: 65px;
        border-left: 1px solid #D7D1E3 !important;
        border-bottom: 1px solid #D7D1E3 !important;
    
        &:last-child {
          border-right: 1px solid #D7D1E3 !important;
    
        }
      }
    }
  }
}
.table-order {
    thead {
      tr {
        th {
          border-left: 1px solid #D7D1E3 !important;
          border-top: 1px solid #D7D1E3 !important;
          border-bottom: 1px solid #D7D1E3 !important;
          &:last-child {
            border-right: 1px solid #D7D1E3 !important;
      
          }
          background-color: #F3EEFF !important;
          height: 55px;
        }
      }
    }
    tbody {
      tr {
        td {
          min-height: 65px;
          border-left: 1px solid #D7D1E3 !important;
          border-bottom: 1px solid #D7D1E3 !important;
      
          &:last-child {
            border-right: 1px solid #D7D1E3 !important;
      
          }
        }
      }
    }
}

mat-table.table {
  flex: 1;
  width: auto;
  overflow-y: auto;
  
  mat-cell:first-of-type, mat-header-cell:first-of-type, mat-footer-cell:first-of-type{
    padding-left: 16px !important;
  }
  .table__header {
    &-row {
      border: 0;
    }

    &-cell {
      font-size: 1rem;
      font-weight: 500;
      color: #3F394E;
    }
  }

  .table__row {
    border: 0;
    transition: background-color 0.23s ease;

    &--link {
      cursor: pointer;
    }

    &:hover {
      background-color: rgba(0, 0, 0, 0.02);
    }
  }
}

.mat-sort-header {
  &.center {
    .mat-sort-header-container {
      justify-content: center;
      padding-left: 12px;
    }
  }
}

mat-table {
  &.clear-table {
    overflow: auto;

    &::-webkit-scrollbar {
      width: 5px;
      height: 5px;
      background-color: rgba(0, 0, 0, 0.2);
    }

    &::-webkit-scrollbar-thumb {
      background-color: #9F74F6;
    }

    mat-row,
    mat-header-row,
    mat-footer-row {
      border: 0px !important;
      border-bottom-width: 0px !important;
    }

    mat-header-cell {
      background-color: white !important;
      border: 0px !important;
      &:last-child {
        padding-right: 0;
      }

      font-weight: 700;
      font-size: 15px;
      line-height: 24px;
      color: rgba(0, 0, 0, 0.87);
    }

    mat-cell {
      border: 0px !important;
      font-size: 14px;

      &:last-child {
        flex-direction: reverse;
      }
    }
  }
}

.circle-status {
  width: 24px;
  height: 24px;
  border-radius: 100%;

  &-green {
    background-color: #8be37c;
  }

  &-red {
    background: #F7AEA4;
  }
}

.small-field {
  .table,.mat-table{
    .mdc-text-field--outlined {
      --mdc-shape-small: 22px !important;
    }

    tbody tr {
      mat-form-field {
        *{
          border-color: transparent !important;
        }
        &.mat-form-field-invalid * {
          border-color: rgba(243, 84, 84, 0.5803921569) !important;
        }
        input {
          position: relative;
          color: #A097B5 !important;
          bottom: 2px;
        }
        mat-select .mat-mdc-select-trigger{
          color: #A097B5 !important;
          margin-top: 0px !important;
        }
        input,
        mat-select {
          &::placeholder {
            color: #A097B5 !important;
            font-weight: 400;
          }
        }

        height: 45px;

        .mdc-text-field--outlined {
          border-color: transparent;
          outline: none !important;
          border-radius: 22px;
        }

        .mat-mdc-text-field-wrapper {
          background-color: #F8F5FF !important;
        }
      }
    }

  }
}
