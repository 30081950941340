$accent-color: #00A3FF;
$accent-green: #34CA1C;
$primary-color: #7E54D4;
$error-color: #f44336;
$disabled-color: #a3a3a3;
$info-color: #FF7B52;
$cancel-color: #F2ECFF;

$white: white;
$black: black;
$hover-grey: #F7F7F7;
$grey: #999;
$grey2: #6a7d88;
$medium-grey: #8C8C8C;
$ocean: #383256;
$ocean-light: #383256;
$ocean-gray: #8A809F;
$slate: #9E98AE;
$yellow: #ffe922;
$red: #e80000;
$green: #40b453;
$blue: royalblue;
$slate-gray: #6B7280;
$midnight: #1F2937;

$background-light-gray: #FAFAFA;
$spinner-overlay: rgba(163, 163, 163, 0.342);
$underline: #8b8b8b;
$shadow: rgba(0, 0, 0, 0.1);

$not-active: rgba(255, 255, 255, 0.3);
$font-color: rgba(0,0,0,0.87);
$font-color-2: rgba(0,0,0,0.6);


:root {
  --primary-color: #{$primary-color};
  --not-active: #{$not-active};
  --font-color: #{$font-color};
  --accent-color: #{$accent-color};
  --error-color: #{$error-color};
  --disabled-color: #{$disabled-color};
  --slate-color: #{$slate};
  --green-color: #{$green};
  --cancel-color: #{$cancel-color};
  --slate-gray-color: #{$slate-gray};
  --midnight-color: #{$midnight};
  --ocean-color: #{$ocean};
}
