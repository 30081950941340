@import "variables";
.small-field {
  .mdc-checkbox {
    .mdc-checkbox__native-control:enabled:not(:checked):not(:indeterminate):not([data-indeterminate=true])~.mdc-checkbox__background{
      border-radius: 5px;
      width: 19px !important;
      height: 19px !important;
      border: 1.5px solid #9E98AE !important;
    }
    margin-right: 10px;
  }
  mat-form-field {
    * {
      border-color: #ECEAF1;
    }
    mat-select {

      .mdc-line-ripple::before {
        border: unset;
      }

      .mat-mdc-select-arrow {
        color: transparent !important;
      }

      .mat-mdc-select-arrow::after {
        position: relative;
        bottom: 5px;
        content: url(../icons/svg/arrow-down.svg);
      }
    }
    .mat-mdc-form-field-icon-prefix, .mat-mdc-form-field-icon-suffix{
      padding: 4px 4px 0 0;
    }

    &.mat-form-field-invalid {
      * {
        border-color: #f3545494 !important;
      }
    }

    &.mat-focused {
      * {
        border-color: var(--primary-color) !important;
      }

      mat-label, .mdc-floating-label {
        color: var(--primary-color) !important;
      }
    }
    .mdc-text-field--outlined.mdc-notched-outline--upgraded .mdc-floating-label--float-above, .mdc-text-field--outlined .mdc-notched-outline--upgraded .mdc-floating-label--float-above {
      transform: translateY(-34.75px) scale(0.75) !important
    }
    .mdc-text-field .mdc-floating-label{
      top: 55% !important;
      transform: translateY(-40%);
    }

    mat-select {
      bottom: 4px;
      position: relative;

      .mat-mdc-select-trigger {
        margin-top: 2px !important;
      }
    }

    .mat-mdc-form-field-error-wrapper{
      padding: 0px;
    }
    .mat-mdc-form-field-error{
      color: red;
      font-size: 10px;
    }
    .mat-mdc-form-field-bottom-align::before{
      height: 13px;
    }
    input,
    mat-select {
      &::placeholder{
        color: #A7ADBE !important;
      }
      display: block !important;
      height: 100% !important;
    }
    .mat-mdc-icon-button svg {
      padding-bottom: 4px;
    }
    .mat-mdc-text-field-wrapper {
      mat-icon {
        padding: 8px;
      }
      background-color: white;
      border: 0px;
      height: 50px;
      .mat-mdc-form-field-infix {
        margin-top: 13px;
        min-width: 60px !important;
        width: 100%;
      }
    }
  }
  .large-checkbox {
    .mdc-checkbox {
      .mdc-checkbox__native-control:enabled:not(:checked):not(:indeterminate):not([data-indeterminate=true])~.mdc-checkbox__background{
        border-radius: 8px;
        width: 50px !important;
        height: 50px !important;
        border: 1.5px solid #9E98AE !important;
      }
      margin-right: 10px;
    }
    .mdc-checkbox {
      // margin-right: 48px;
      width: 50px !important;
      height: 50px !important;
      .mdc-checkbox__background {
        width: 50px !important;
        height: 50px !important;
      }

    }
    .mdc-checkbox .mdc-checkbox__native-control:enabled:checked ~ .mdc-checkbox__background {
      background-color: #fff !important;
    }
    .mdc-checkbox .mdc-checkbox__native-control:enabled~.mdc-checkbox__background .mdc-checkbox__checkmark{
      color: #7E54D4;
    }
    label.mdc-label {
      font-weight: 600 !important;
      color: #3F394E !important;
      font-size: 13px;
      margin-left: 28px;
    }
    .mdc-checkbox__checkmark {
      width: 42px;
      top: 2px;
      left: 2px;
    }
    .mat-mdc-checkbox-touch-target {
      left: 25px !important;
    }
  }

  .medium-checkbox {
    .mdc-checkbox {
      .mdc-checkbox__native-control:enabled:not(:checked):not(:indeterminate):not([data-indeterminate=true])~.mdc-checkbox__background{
        border-radius: 5px;
        width: 30px !important;
        height: 30px !important;
        border: 1.5px solid #9E98AE !important;
      }
      margin-right: 22px;
    }
    .mdc-checkbox {
      // margin-right: 48px;
      width: 30px !important;
      height: 30px !important;
      .mdc-checkbox__background {
        width: 30px !important;
        height: 30px !important;
      }

    }
    .mdc-checkbox .mdc-checkbox__native-control:enabled:checked ~ .mdc-checkbox__background {
      background-color: #fff !important;
    }
    .mdc-checkbox .mdc-checkbox__native-control:enabled~.mdc-checkbox__background .mdc-checkbox__checkmark{
      color: #7E54D4;
    }
    label.mdc-label {
      font-weight: 500 !important;
      color: #3F394E !important;
    }
    .mdc-checkbox__checkmark {
      width: 24px;
      top: 2px;
      left: 2px;
    }
  }
}

.big-field {
  mat-form-field {
    * {
      border-color: #ECEAF1;
      &::placeholder{
        color: #59516b6f;
      }
    }

    .mat-mdc-text-field-wrapper {
      height: 74px;
      background-color: white;
      border-radius: 10px;

      .mdc-notched-outline--notched .mdc-notched-outline__notch {
        border-top: 1px solid #ECEAF1;
      }

      .mat-mdc-form-field-flex,
      .mat-mdc-form-field-infix,
      .mat-mdc-select,
      .mat-mdc-select-trigger,
      .mdc-text-field__input {
        height: 100%;
      }

      .mdc-text-field__input {
        margin-top: 12px;
        color: #59516B;
      }

      .mat-mdc-select-value {
        margin-top: 25px;
        color: #59516B;
      }
    }
    &.mat-form-field-invalid{

      * {
        border-color: #FF4D4D !important;
      }
      .mat-mdc-text-field-wrapper {
        background-color: #FFF0F0 !important;
        .mdc-notched-outline--notched .mdc-notched-outline__notch {
          border-top: 2px solid #FF4D4D !important;
        }
      }
    }
    mat-select {

      .mdc-line-ripple::before {
        border: unset;
      }

      .mat-mdc-select-arrow {
        color: transparent !important;
      }

      .mat-mdc-select-arrow::after {
        content: '';
        position: relative;
        bottom: 5px;
        right: 8px;
        transform: scale(1.7);
        display: block;
        content: url(../icons/svg/arrow-down.svg);
      }
    }
    .mdc-text-field--outlined .mdc-notched-outline--upgraded .mdc-floating-label--float-above {
      transform: translateY(-24.75px) !important;
    }
  }

  .mdc-floating-label {
    font-size: 13px !important;
    font-weight: 400 !important;
    line-height: 13px !important;
    color: #A6A1B2 !important;
  }

  .textarea {
    mat-form-field {
      .mdc-text-field--outlined .mdc-notched-outline--upgraded .mdc-floating-label {
        transform: translateY(-54.75px) !important;
      }

      .mat-mdc-text-field-wrapper {
        height: 150px !important;
      }

      .mdc-text-field__input {
        height: 95px;
        margin-top: 50px;
        padding-left: 7px;
        overflow: auto;
      }

      textarea {
        resize: none;
      }
    }
  }
}

.forms-position-top {
  mat-form-field {
    .mat-mdc-icon-button svg {
      padding-bottom: 4px;
    }
    .mat-mdc-text-field-wrapper {
      .mat-mdc-form-field-infix {
        margin-top: 16px !important;
      }
    }
  }
  .date-range-wrapper .date-label{
    bottom: 4px !important;
  }
}

.forms-position-bottom {
  mat-form-field {
    .mat-mdc-text-field-wrapper {
      .mat-mdc-form-field-infix {
        margin-top: 13px;
      }
    }
  }
}

.cdk-overlay-container {
  .cdk-overlay-pane {
    .mat-mdc-select-panel, .mat-mdc-menu-content{
      background-color: white !important;
    }
  }
}

.textarea {
  mat-form-field {
    textarea{
      overflow: hidden;
    }
    .mat-mdc-text-field-wrapper {
      height: auto !important;
      padding: 0 10px 10px 10px !important;
    }
  }
}

.gray-field {
  &.bordered {
    mat-form-field {
      .mat-mdc-text-field-wrapper {
        background-color: transparent;
        ;
        border: 1px solid #ECEAF1;
        border-radius: 8px;
      }
      .mdc-line-ripple::before {
        border: unset;
      }
      .mat-mdc-select-arrow {
        color: transparent;
      }
      .mat-mdc-select-arrow::after {
        position: relative;
        top: 7px;    
        content: url(../icons/svg/arrow-down.svg);
      }
      .mdc-line-ripple::after {
        border-bottom-width: 0;
      }
    }
  }
  mat-form-field {
    * {
      border-color: #0000001f;
    }
    mat-select {
      bottom: 5px;
      position: relative;
    }
    .mat-mdc-form-field-subscript-wrapper {
      display: none;
    }

    input,
    mat-select {
      display: block !important;
      height: 100% !important;
    }

    .mat-mdc-select-arrow svg {
      margin-top: 12px;
    }

    .mat-mdc-icon-button {
      svg {

        padding-bottom: 4px;
      }

    }
    .mat-mdc-select-min-line{
      font-size: 15px;
    }
    .mdc-floating-label{
      font-size: 13px;
      color: #0009;
    }
    .mat-mdc-select-value {
      margin-top: 30px;

    }

    .mat-mdc-text-field-wrapper {

      mat-icon {
        padding: 8px;
      }
      background-color: #0000000a;
      ;
      border: 0px;
      height: 50px;

      .mat-mdc-form-field-infix {
        margin-top: 0px;
      }
    }
  }

}

.def-field {
  mat-form-field {
    * {
      border-color: #0000001f;
    }
    mat-select {
      bottom: 5px;
      position: relative;
    }
    .mat-mdc-form-field-subscript-wrapper {
      display: none;
    }
    .mdc-text-field{
      padding: 0px 5px;
    }

    input,
    mat-select {
      display: block !important;
      height: 100% !important;
    }

    .mat-mdc-select-arrow svg {
      margin-top: 12px;
    }

    .mat-mdc-icon-button {
      svg {

        padding-bottom: 4px;
      }

    }
    .mat-mdc-select-min-line{
      font-size: 15px;
    }
    .mdc-floating-label{
      font-size: 15px;
      color: #0009;
    }
    .mat-mdc-select-value {
      margin-top: 30px;

    }

    .mat-mdc-text-field-wrapper {
      mat-icon {
        padding: 8px;
      }
      ;
      border: 0px;
      height: 50px;

      .mat-mdc-form-field-infix {
        margin-top: 0px;
      }
    }
  }

}
  .date-range-wrapper {
      .mdc-notched-outline--notched {
        .date-label {
          bottom: 2px;
      }
      }
      .date-label {
        font-size: 16px;
        position: relative;
        bottom: 7px;
        color: #A6A1B2;
    }
  }

  

.font-size-xs{
  mat-form-field input,
  mat-form-field mat-select{  
    font-size: 13px;
  }
}
.no-error{
  .mat-mdc-form-field-subscript-wrapper{
    display: none !important;
  }
}
.mdc-text-field--outlined .mdc-notched-outline .mdc-notched-outline__leading{
  border-top-left-radius: var(--mdc-shape-small, 8px) !important;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: var(--mdc-shape-small, 8px) !important;
}

.mdc-text-field--outlined .mdc-notched-outline .mdc-notched-outline__trailing {
  border-top-left-radius: 0;
  border-top-right-radius: var(--mdc-shape-small, 8px) !important;
  border-bottom-right-radius: var(--mdc-shape-small, 8px) !important;
  border-bottom-left-radius: 0;
}
mat-button-toggle-group.mat-button-toggle-group {
  height: 50px;
  border-radius: 8px;
  .mat-button-toggle-checked {
    background-color: #7E54D4 !important;
  }
}
.button-product {
  &:not([disabled]){
    background: linear-gradient(98.81deg, #A77EFD -0.82%, #7D54D4 101.53%) !important;
  }
  &[disabled]{
    background-color: #D3D3D3 !important;
    color: #7A7A7A !important;
  }
  border-radius: 5px !important;
  height: 50px !important;
  color: #fff !important;
  font-weight: 600;
  font-size: 16px;
  line-height: 25.5px;
}

  .mat-primary .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full, .mat-primary .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full{
    border-radius: 5px;
    width: 19px !important;
    height: 19px !important;
    border: 1.5px solid #9E98AE !important;
    color: #7E54D4;
    background-color: #7E54D4;
  }
