:root {
  --primary-color: #7E54D4;
  --not-active: rgba(255, 255, 255, 0.3);
  --font-color: rgba(0, 0, 0, 0.87);
  --accent-color: #00A3FF;
  --error-color: #f44336;
  --disabled-color: #a3a3a3;
  --slate-color: #9E98AE;
  --green-color: #40b453;
  --cancel-color: #F2ECFF;
  --slate-gray-color: #6B7280;
  --midnight-color: #1F2937;
  --ocean-color: #383256;
}

.text-center {
  text-align: center;
}

*:not(mat-icon) {
  font-family: "Poppins", sans-serif;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.underline {
  border-bottom: 1px solid #8b8b8b;
}

.title {
  font-size: 30px;
}

.page-title {
  font-weight: 500;
  font-size: 24px;
  line-height: 32px;
  margin-bottom: 10px;
}

.subtitle {
  font-size: 18px;
  font-weight: bold;
}
.subtitle_underline {
  border-bottom: 1px solid black;
}
.subtitle_padding {
  padding-bottom: 15px;
}

.small-title {
  font-size: 14px;
  font-weight: bold;
}

h2 {
  font-weight: 500;
}

.word-break {
  word-break: break-word;
}

.slate-text-color {
  color: var(--slate-color);
}

.primary-text-color {
  color: var(--primary-color);
}

.list-table-color {
  color: #3F394E;
}

.slate-gray-text {
  color: var(--slate-gray-color);
}

.midnight-text {
  color: var(--midnight-color);
}

.ocean-text {
  color: var(--ocean-color);
}

:root {
  --primary-color: #7E54D4;
  --not-active: rgba(255, 255, 255, 0.3);
  --font-color: rgba(0, 0, 0, 0.87);
  --accent-color: #00A3FF;
  --error-color: #f44336;
  --disabled-color: #a3a3a3;
  --slate-color: #9E98AE;
  --green-color: #40b453;
  --cancel-color: #F2ECFF;
  --slate-gray-color: #6B7280;
  --midnight-color: #1F2937;
  --ocean-color: #383256;
}

* {
  overscroll-behavior: none;
  /* Track */
  /* Handle */
  /* Handle on hover */
}
* ::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}
* ::-webkit-scrollbar-track {
  background: #f1f1f1;
}
* ::-webkit-scrollbar-thumb {
  background: #888;
}
* ::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.dialog-rounded-xl .mat-mdc-dialog-surface {
  border-radius: 10px !important;
}

.circle {
  border-radius: 50%;
  width: 18px;
  height: 18px;
}

.fit-cover {
  object-fit: cover;
}

.fit-contain {
  object-fit: contain;
}

.small-circle {
  border-radius: 50%;
  width: 10px;
  height: 10px;
}

.container {
  display: flex;
  padding: 40px;
}
.container_column {
  flex-direction: column;
}
.container_center {
  justify-content: center;
}

.loader-container {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(163, 163, 163, 0.342);
}
.loader-container .css-loader {
  width: 55px;
  height: 55px;
  border: 5px solid #9F74F6;
  border-bottom-color: transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}
@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.list-container {
  padding: 24px 24px 0 32px;
  background-color: #FAF8FF !important;
  position: sticky;
  top: 25px;
}

.default-tabs {
  background-color: #e2e2e2;
  border: none;
  padding: 0 14px;
}
.default-tabs mat-ink-bar {
  background-color: #ffffff !important;
}
.default-tabs .mat-tab-label-active {
  background-color: #cecece;
}
.default-tabs .mat-tab-link {
  color: black;
  min-width: fit-content;
  text-transform: uppercase;
}

.page-container {
  max-width: 1152px;
  padding: 0 24px;
  padding-top: 32px;
  margin: auto;
}

.border {
  border: 1px solid #e2e2e2;
}

.set-categories-wrap {
  border: 1px solid #ECEAF1 !important;
  border-top-left-radius: 0px !important;
  border-top-right-radius: 0px !important;
  box-shadow: none !important;
}

.background-opacity-white {
  background: rgba(255, 255, 255, 0.7);
}

.hidden {
  width: 0;
  margin: 0;
  padding: 0;
  overflow: hidden;
  height: 0;
}

.overlay-pane-select-style {
  width: auto !important;
  min-width: 200px !important;
}

.confirm-container {
  border-radius: 20px !important;
}

.confirm-dialog {
  width: 455px !important;
  height: 232px !important;
  border-radius: 20px !important;
  box-shadow: 0px 0px 20px 0px #737373;
  overflow: hidden;
}

.custom-tooltip {
  background-color: #fff !important;
  border-radius: 5px;
  color: #3F394E;
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.2);
  width: 415px !important;
  right: 200px !important;
  padding: 6px;
}
.custom-tooltip * {
  width: 100% !important;
  max-width: 100% !important;
}

.import-dialog {
  border: 1px solid #ECEAF1;
  border-radius: 20px !important;
  width: 510px !important;
  max-height: 464px !important;
  overflow: hidden;
}

.import-dialog-backdrop {
  background: rgba(0, 0, 0, 0.568627451);
}

.outlet {
  background-color: white;
}

.small-star .medium {
  width: 30px !important;
}
.small-star .rating {
  justify-content: start !important;
}

.status-item {
  border-radius: 2px;
  font-size: 14px;
  font-weight: 500;
  padding: 4px;
}
.status-item.status-red {
  background-color: #FFDADA;
  color: #FA3838;
}
.status-item.status-green {
  background-color: #E5FFE0;
  color: #34A621;
}

:root {
  --primary-color: #7E54D4;
  --not-active: rgba(255, 255, 255, 0.3);
  --font-color: rgba(0, 0, 0, 0.87);
  --accent-color: #00A3FF;
  --error-color: #f44336;
  --disabled-color: #a3a3a3;
  --slate-color: #9E98AE;
  --green-color: #40b453;
  --cancel-color: #F2ECFF;
  --slate-gray-color: #6B7280;
  --midnight-color: #1F2937;
  --ocean-color: #383256;
}

.small-field .mdc-checkbox {
  margin-right: 10px;
}
.small-field .mdc-checkbox .mdc-checkbox__native-control:enabled:not(:checked):not(:indeterminate):not([data-indeterminate=true]) ~ .mdc-checkbox__background {
  border-radius: 5px;
  width: 19px !important;
  height: 19px !important;
  border: 1.5px solid #9E98AE !important;
}
.small-field mat-form-field * {
  border-color: #ECEAF1;
}
.small-field mat-form-field mat-select .mdc-line-ripple::before {
  border: unset;
}
.small-field mat-form-field mat-select .mat-mdc-select-arrow {
  color: transparent !important;
}
.small-field mat-form-field mat-select .mat-mdc-select-arrow::after {
  position: relative;
  bottom: 5px;
  content: url(../icons/svg/arrow-down.svg);
}
.small-field mat-form-field .mat-mdc-form-field-icon-prefix, .small-field mat-form-field .mat-mdc-form-field-icon-suffix {
  padding: 4px 4px 0 0;
}
.small-field mat-form-field.mat-form-field-invalid * {
  border-color: rgba(243, 84, 84, 0.5803921569) !important;
}
.small-field mat-form-field.mat-focused * {
  border-color: var(--primary-color) !important;
}
.small-field mat-form-field.mat-focused mat-label, .small-field mat-form-field.mat-focused .mdc-floating-label {
  color: var(--primary-color) !important;
}
.small-field mat-form-field .mdc-text-field--outlined.mdc-notched-outline--upgraded .mdc-floating-label--float-above, .small-field mat-form-field .mdc-text-field--outlined .mdc-notched-outline--upgraded .mdc-floating-label--float-above {
  transform: translateY(-34.75px) scale(0.75) !important;
}
.small-field mat-form-field .mdc-text-field .mdc-floating-label {
  top: 55% !important;
  transform: translateY(-40%);
}
.small-field mat-form-field mat-select {
  bottom: 4px;
  position: relative;
}
.small-field mat-form-field mat-select .mat-mdc-select-trigger {
  margin-top: 2px !important;
}
.small-field mat-form-field .mat-mdc-form-field-error-wrapper {
  padding: 0px;
}
.small-field mat-form-field .mat-mdc-form-field-error {
  color: red;
  font-size: 10px;
}
.small-field mat-form-field .mat-mdc-form-field-bottom-align::before {
  height: 13px;
}
.small-field mat-form-field input,
.small-field mat-form-field mat-select {
  display: block !important;
  height: 100% !important;
}
.small-field mat-form-field input::placeholder,
.small-field mat-form-field mat-select::placeholder {
  color: #A7ADBE !important;
}
.small-field mat-form-field .mat-mdc-icon-button svg {
  padding-bottom: 4px;
}
.small-field mat-form-field .mat-mdc-text-field-wrapper {
  background-color: white;
  border: 0px;
  height: 50px;
}
.small-field mat-form-field .mat-mdc-text-field-wrapper mat-icon {
  padding: 8px;
}
.small-field mat-form-field .mat-mdc-text-field-wrapper .mat-mdc-form-field-infix {
  margin-top: 13px;
  min-width: 60px !important;
  width: 100%;
}
.small-field .large-checkbox .mdc-checkbox {
  margin-right: 10px;
}
.small-field .large-checkbox .mdc-checkbox .mdc-checkbox__native-control:enabled:not(:checked):not(:indeterminate):not([data-indeterminate=true]) ~ .mdc-checkbox__background {
  border-radius: 8px;
  width: 50px !important;
  height: 50px !important;
  border: 1.5px solid #9E98AE !important;
}
.small-field .large-checkbox .mdc-checkbox {
  width: 50px !important;
  height: 50px !important;
}
.small-field .large-checkbox .mdc-checkbox .mdc-checkbox__background {
  width: 50px !important;
  height: 50px !important;
}
.small-field .large-checkbox .mdc-checkbox .mdc-checkbox__native-control:enabled:checked ~ .mdc-checkbox__background {
  background-color: #fff !important;
}
.small-field .large-checkbox .mdc-checkbox .mdc-checkbox__native-control:enabled ~ .mdc-checkbox__background .mdc-checkbox__checkmark {
  color: #7E54D4;
}
.small-field .large-checkbox label.mdc-label {
  font-weight: 600 !important;
  color: #3F394E !important;
  font-size: 13px;
  margin-left: 28px;
}
.small-field .large-checkbox .mdc-checkbox__checkmark {
  width: 42px;
  top: 2px;
  left: 2px;
}
.small-field .large-checkbox .mat-mdc-checkbox-touch-target {
  left: 25px !important;
}
.small-field .medium-checkbox .mdc-checkbox {
  margin-right: 22px;
}
.small-field .medium-checkbox .mdc-checkbox .mdc-checkbox__native-control:enabled:not(:checked):not(:indeterminate):not([data-indeterminate=true]) ~ .mdc-checkbox__background {
  border-radius: 5px;
  width: 30px !important;
  height: 30px !important;
  border: 1.5px solid #9E98AE !important;
}
.small-field .medium-checkbox .mdc-checkbox {
  width: 30px !important;
  height: 30px !important;
}
.small-field .medium-checkbox .mdc-checkbox .mdc-checkbox__background {
  width: 30px !important;
  height: 30px !important;
}
.small-field .medium-checkbox .mdc-checkbox .mdc-checkbox__native-control:enabled:checked ~ .mdc-checkbox__background {
  background-color: #fff !important;
}
.small-field .medium-checkbox .mdc-checkbox .mdc-checkbox__native-control:enabled ~ .mdc-checkbox__background .mdc-checkbox__checkmark {
  color: #7E54D4;
}
.small-field .medium-checkbox label.mdc-label {
  font-weight: 500 !important;
  color: #3F394E !important;
}
.small-field .medium-checkbox .mdc-checkbox__checkmark {
  width: 24px;
  top: 2px;
  left: 2px;
}

.big-field mat-form-field * {
  border-color: #ECEAF1;
}
.big-field mat-form-field *::placeholder {
  color: rgba(89, 81, 107, 0.4352941176);
}
.big-field mat-form-field .mat-mdc-text-field-wrapper {
  height: 74px;
  background-color: white;
  border-radius: 10px;
}
.big-field mat-form-field .mat-mdc-text-field-wrapper .mdc-notched-outline--notched .mdc-notched-outline__notch {
  border-top: 1px solid #ECEAF1;
}
.big-field mat-form-field .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex,
.big-field mat-form-field .mat-mdc-text-field-wrapper .mat-mdc-form-field-infix,
.big-field mat-form-field .mat-mdc-text-field-wrapper .mat-mdc-select,
.big-field mat-form-field .mat-mdc-text-field-wrapper .mat-mdc-select-trigger,
.big-field mat-form-field .mat-mdc-text-field-wrapper .mdc-text-field__input {
  height: 100%;
}
.big-field mat-form-field .mat-mdc-text-field-wrapper .mdc-text-field__input {
  margin-top: 12px;
  color: #59516B;
}
.big-field mat-form-field .mat-mdc-text-field-wrapper .mat-mdc-select-value {
  margin-top: 25px;
  color: #59516B;
}
.big-field mat-form-field.mat-form-field-invalid * {
  border-color: #FF4D4D !important;
}
.big-field mat-form-field.mat-form-field-invalid .mat-mdc-text-field-wrapper {
  background-color: #FFF0F0 !important;
}
.big-field mat-form-field.mat-form-field-invalid .mat-mdc-text-field-wrapper .mdc-notched-outline--notched .mdc-notched-outline__notch {
  border-top: 2px solid #FF4D4D !important;
}
.big-field mat-form-field mat-select .mdc-line-ripple::before {
  border: unset;
}
.big-field mat-form-field mat-select .mat-mdc-select-arrow {
  color: transparent !important;
}
.big-field mat-form-field mat-select .mat-mdc-select-arrow::after {
  content: "";
  position: relative;
  bottom: 5px;
  right: 8px;
  transform: scale(1.7);
  display: block;
  content: url(../icons/svg/arrow-down.svg);
}
.big-field mat-form-field .mdc-text-field--outlined .mdc-notched-outline--upgraded .mdc-floating-label--float-above {
  transform: translateY(-24.75px) !important;
}
.big-field .mdc-floating-label {
  font-size: 13px !important;
  font-weight: 400 !important;
  line-height: 13px !important;
  color: #A6A1B2 !important;
}
.big-field .textarea mat-form-field .mdc-text-field--outlined .mdc-notched-outline--upgraded .mdc-floating-label {
  transform: translateY(-54.75px) !important;
}
.big-field .textarea mat-form-field .mat-mdc-text-field-wrapper {
  height: 150px !important;
}
.big-field .textarea mat-form-field .mdc-text-field__input {
  height: 95px;
  margin-top: 50px;
  padding-left: 7px;
  overflow: auto;
}
.big-field .textarea mat-form-field textarea {
  resize: none;
}

.forms-position-top mat-form-field .mat-mdc-icon-button svg {
  padding-bottom: 4px;
}
.forms-position-top mat-form-field .mat-mdc-text-field-wrapper .mat-mdc-form-field-infix {
  margin-top: 16px !important;
}
.forms-position-top .date-range-wrapper .date-label {
  bottom: 4px !important;
}

.forms-position-bottom mat-form-field .mat-mdc-text-field-wrapper .mat-mdc-form-field-infix {
  margin-top: 13px;
}

.cdk-overlay-container .cdk-overlay-pane .mat-mdc-select-panel, .cdk-overlay-container .cdk-overlay-pane .mat-mdc-menu-content {
  background-color: white !important;
}

.textarea mat-form-field textarea {
  overflow: hidden;
}
.textarea mat-form-field .mat-mdc-text-field-wrapper {
  height: auto !important;
  padding: 0 10px 10px 10px !important;
}

.gray-field.bordered mat-form-field .mat-mdc-text-field-wrapper {
  background-color: transparent;
  border: 1px solid #ECEAF1;
  border-radius: 8px;
}
.gray-field.bordered mat-form-field .mdc-line-ripple::before {
  border: unset;
}
.gray-field.bordered mat-form-field .mat-mdc-select-arrow {
  color: transparent;
}
.gray-field.bordered mat-form-field .mat-mdc-select-arrow::after {
  position: relative;
  top: 7px;
  content: url(../icons/svg/arrow-down.svg);
}
.gray-field.bordered mat-form-field .mdc-line-ripple::after {
  border-bottom-width: 0;
}
.gray-field mat-form-field * {
  border-color: rgba(0, 0, 0, 0.1215686275);
}
.gray-field mat-form-field mat-select {
  bottom: 5px;
  position: relative;
}
.gray-field mat-form-field .mat-mdc-form-field-subscript-wrapper {
  display: none;
}
.gray-field mat-form-field input,
.gray-field mat-form-field mat-select {
  display: block !important;
  height: 100% !important;
}
.gray-field mat-form-field .mat-mdc-select-arrow svg {
  margin-top: 12px;
}
.gray-field mat-form-field .mat-mdc-icon-button svg {
  padding-bottom: 4px;
}
.gray-field mat-form-field .mat-mdc-select-min-line {
  font-size: 15px;
}
.gray-field mat-form-field .mdc-floating-label {
  font-size: 13px;
  color: rgba(0, 0, 0, 0.6);
}
.gray-field mat-form-field .mat-mdc-select-value {
  margin-top: 30px;
}
.gray-field mat-form-field .mat-mdc-text-field-wrapper {
  background-color: rgba(0, 0, 0, 0.0392156863);
  border: 0px;
  height: 50px;
}
.gray-field mat-form-field .mat-mdc-text-field-wrapper mat-icon {
  padding: 8px;
}
.gray-field mat-form-field .mat-mdc-text-field-wrapper .mat-mdc-form-field-infix {
  margin-top: 0px;
}

.def-field mat-form-field * {
  border-color: rgba(0, 0, 0, 0.1215686275);
}
.def-field mat-form-field mat-select {
  bottom: 5px;
  position: relative;
}
.def-field mat-form-field .mat-mdc-form-field-subscript-wrapper {
  display: none;
}
.def-field mat-form-field .mdc-text-field {
  padding: 0px 5px;
}
.def-field mat-form-field input,
.def-field mat-form-field mat-select {
  display: block !important;
  height: 100% !important;
}
.def-field mat-form-field .mat-mdc-select-arrow svg {
  margin-top: 12px;
}
.def-field mat-form-field .mat-mdc-icon-button svg {
  padding-bottom: 4px;
}
.def-field mat-form-field .mat-mdc-select-min-line {
  font-size: 15px;
}
.def-field mat-form-field .mdc-floating-label {
  font-size: 15px;
  color: rgba(0, 0, 0, 0.6);
}
.def-field mat-form-field .mat-mdc-select-value {
  margin-top: 30px;
}
.def-field mat-form-field .mat-mdc-text-field-wrapper {
  border: 0px;
  height: 50px;
}
.def-field mat-form-field .mat-mdc-text-field-wrapper mat-icon {
  padding: 8px;
}
.def-field mat-form-field .mat-mdc-text-field-wrapper .mat-mdc-form-field-infix {
  margin-top: 0px;
}

.date-range-wrapper .mdc-notched-outline--notched .date-label {
  bottom: 2px;
}
.date-range-wrapper .date-label {
  font-size: 16px;
  position: relative;
  bottom: 7px;
  color: #A6A1B2;
}

.font-size-xs mat-form-field input,
.font-size-xs mat-form-field mat-select {
  font-size: 13px;
}

.no-error .mat-mdc-form-field-subscript-wrapper {
  display: none !important;
}

.mdc-text-field--outlined .mdc-notched-outline .mdc-notched-outline__leading {
  border-top-left-radius: var(--mdc-shape-small, 8px) !important;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: var(--mdc-shape-small, 8px) !important;
}

.mdc-text-field--outlined .mdc-notched-outline .mdc-notched-outline__trailing {
  border-top-left-radius: 0;
  border-top-right-radius: var(--mdc-shape-small, 8px) !important;
  border-bottom-right-radius: var(--mdc-shape-small, 8px) !important;
  border-bottom-left-radius: 0;
}

mat-button-toggle-group.mat-button-toggle-group {
  height: 50px;
  border-radius: 8px;
}
mat-button-toggle-group.mat-button-toggle-group .mat-button-toggle-checked {
  background-color: #7E54D4 !important;
}

.button-product {
  border-radius: 5px !important;
  height: 50px !important;
  color: #fff !important;
  font-weight: 600;
  font-size: 16px;
  line-height: 25.5px;
}
.button-product:not([disabled]) {
  background: linear-gradient(98.81deg, #A77EFD -0.82%, #7D54D4 101.53%) !important;
}
.button-product[disabled] {
  background-color: #D3D3D3 !important;
  color: #7A7A7A !important;
}

.mat-primary .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full, .mat-primary .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full {
  border-radius: 5px;
  width: 19px !important;
  height: 19px !important;
  border: 1.5px solid #9E98AE !important;
  color: #7E54D4;
  background-color: #7E54D4;
}

:root {
  --primary-color: #7E54D4;
  --not-active: rgba(255, 255, 255, 0.3);
  --font-color: rgba(0, 0, 0, 0.87);
  --accent-color: #00A3FF;
  --error-color: #f44336;
  --disabled-color: #a3a3a3;
  --slate-color: #9E98AE;
  --green-color: #40b453;
  --cancel-color: #F2ECFF;
  --slate-gray-color: #6B7280;
  --midnight-color: #1F2937;
  --ocean-color: #383256;
}

.table {
  border-collapse: collapse;
}
.table td {
  padding: 4px;
  padding-left: 15px;
  color: #7A728D !important;
  font-weight: 500;
}
.table thead.list-table-color td {
  background-color: #54585d;
  color: #383256 !important;
  font-weight: bold;
  font-size: 13px;
}
.table thead.list-table-color td {
  color: #3F394E !important;
  font-weight: 500 !important;
}
.table thead th {
  color: #383256 !important;
}
.table tbody td {
  color: #636363;
}
.table tbody tr {
  background-color: white;
}
.table mat-row {
  background-color: white;
}
.table mat-row:hover {
  background-color: rgba(195, 195, 195, 0.096) !important;
}
.table mat-header-cell {
  border-left: 1px solid #D7D1E3 !important;
  border-top: 1px solid #D7D1E3 !important;
  border-bottom: 1px solid #D7D1E3 !important;
  font-weight: 500;
  color: #383256;
  font-size: 13px;
  background-color: #F3EEFF !important;
  min-height: 55px;
}
.table mat-header-cell:last-child {
  border-right: 1px solid #D7D1E3 !important;
}
.table mat-cell {
  min-height: 65px;
  color: #7A728D;
  font-size: 13px;
  border-left: 1px solid #D7D1E3 !important;
  border-bottom: 1px solid #D7D1E3 !important;
}
.table mat-cell:last-child {
  border-right: 1px solid #D7D1E3 !important;
}
.table thead tr th {
  border-left: 1px solid #D7D1E3 !important;
  border-top: 1px solid #D7D1E3 !important;
  border-bottom: 1px solid #D7D1E3 !important;
  background-color: #F3EEFF !important;
  height: 55px;
}
.table thead tr th:last-child {
  border-right: 1px solid #D7D1E3 !important;
}
.table tbody tr td {
  min-height: 65px;
  border-left: 1px solid #D7D1E3 !important;
  border-bottom: 1px solid #D7D1E3 !important;
}
.table tbody tr td:last-child {
  border-right: 1px solid #D7D1E3 !important;
}

.table-order thead tr th {
  border-left: 1px solid #D7D1E3 !important;
  border-top: 1px solid #D7D1E3 !important;
  border-bottom: 1px solid #D7D1E3 !important;
  background-color: #F3EEFF !important;
  height: 55px;
}
.table-order thead tr th:last-child {
  border-right: 1px solid #D7D1E3 !important;
}
.table-order tbody tr td {
  min-height: 65px;
  border-left: 1px solid #D7D1E3 !important;
  border-bottom: 1px solid #D7D1E3 !important;
}
.table-order tbody tr td:last-child {
  border-right: 1px solid #D7D1E3 !important;
}

mat-table.table {
  flex: 1;
  width: auto;
  overflow-y: auto;
}
mat-table.table mat-cell:first-of-type, mat-table.table mat-header-cell:first-of-type, mat-table.table mat-footer-cell:first-of-type {
  padding-left: 16px !important;
}
mat-table.table .table__header-row {
  border: 0;
}
mat-table.table .table__header-cell {
  font-size: 1rem;
  font-weight: 500;
  color: #3F394E;
}
mat-table.table .table__row {
  border: 0;
  transition: background-color 0.23s ease;
}
mat-table.table .table__row--link {
  cursor: pointer;
}
mat-table.table .table__row:hover {
  background-color: rgba(0, 0, 0, 0.02);
}

.mat-sort-header.center .mat-sort-header-container {
  justify-content: center;
  padding-left: 12px;
}

mat-table.clear-table {
  overflow: auto;
}
mat-table.clear-table::-webkit-scrollbar {
  width: 5px;
  height: 5px;
  background-color: rgba(0, 0, 0, 0.2);
}
mat-table.clear-table::-webkit-scrollbar-thumb {
  background-color: #9F74F6;
}
mat-table.clear-table mat-row,
mat-table.clear-table mat-header-row,
mat-table.clear-table mat-footer-row {
  border: 0px !important;
  border-bottom-width: 0px !important;
}
mat-table.clear-table mat-header-cell {
  background-color: white !important;
  border: 0px !important;
  font-weight: 700;
  font-size: 15px;
  line-height: 24px;
  color: rgba(0, 0, 0, 0.87);
}
mat-table.clear-table mat-header-cell:last-child {
  padding-right: 0;
}
mat-table.clear-table mat-cell {
  border: 0px !important;
  font-size: 14px;
}
mat-table.clear-table mat-cell:last-child {
  flex-direction: reverse;
}

.circle-status {
  width: 24px;
  height: 24px;
  border-radius: 100%;
}
.circle-status-green {
  background-color: #8be37c;
}
.circle-status-red {
  background: #F7AEA4;
}

.small-field .table .mdc-text-field--outlined, .small-field .mat-table .mdc-text-field--outlined {
  --mdc-shape-small: 22px !important;
}
.small-field .table tbody tr mat-form-field, .small-field .mat-table tbody tr mat-form-field {
  height: 45px;
}
.small-field .table tbody tr mat-form-field *, .small-field .mat-table tbody tr mat-form-field * {
  border-color: transparent !important;
}
.small-field .table tbody tr mat-form-field.mat-form-field-invalid *, .small-field .mat-table tbody tr mat-form-field.mat-form-field-invalid * {
  border-color: rgba(243, 84, 84, 0.5803921569) !important;
}
.small-field .table tbody tr mat-form-field input, .small-field .mat-table tbody tr mat-form-field input {
  position: relative;
  color: #A097B5 !important;
  bottom: 2px;
}
.small-field .table tbody tr mat-form-field mat-select .mat-mdc-select-trigger, .small-field .mat-table tbody tr mat-form-field mat-select .mat-mdc-select-trigger {
  color: #A097B5 !important;
  margin-top: 0px !important;
}
.small-field .table tbody tr mat-form-field input::placeholder,
.small-field .table tbody tr mat-form-field mat-select::placeholder, .small-field .mat-table tbody tr mat-form-field input::placeholder,
.small-field .mat-table tbody tr mat-form-field mat-select::placeholder {
  color: #A097B5 !important;
  font-weight: 400;
}
.small-field .table tbody tr mat-form-field .mdc-text-field--outlined, .small-field .mat-table tbody tr mat-form-field .mdc-text-field--outlined {
  border-color: transparent;
  outline: none !important;
  border-radius: 22px;
}
.small-field .table tbody tr mat-form-field .mat-mdc-text-field-wrapper, .small-field .mat-table tbody tr mat-form-field .mat-mdc-text-field-wrapper {
  background-color: #F8F5FF !important;
}

.default {
  border-radius: 15px !important;
  border-color: transparent;
  height: 50px !important;
  color: #fff !important;
  font-weight: 600;
  font-size: 16px;
  line-height: 25.5px;
}
.default[disabled] {
  background-color: #D3D3D3 !important;
  color: #7A7A7A !important;
}

.medium {
  height: 40px;
  font-size: 12px;
  font-weight: 400;
  min-width: 90px !important;
  border-radius: 4px !important;
  white-space: nowrap;
}

.link {
  font-weight: 500;
  color: #00A3FF;
}

button.mat-ocean {
  background: #383256;
  color: white;
}

button.mat-accent-green {
  background: #34CA1C;
  color: white;
}

button.clear {
  background: none;
  padding: 0;
  outline: none;
  border: none;
  cursor: pointer;
}

mat-slide-toggle button.mdc-switch .mdc-switch__track {
  height: 20px !important;
  width: 35px !important;
  border-radius: 12px !important;
}
mat-slide-toggle button.mdc-switch:enabled .mdc-switch__track::after {
  background: var(--mdc-switch-selected-track-color, #29B912) !important;
}
mat-slide-toggle button.mdc-switch:enabled .mdc-switch__track::before {
  background: var(--mdc-switch-selected-track-color, #CBCBCB) !important;
}
mat-slide-toggle button.mdc-switch.mdc-switch--selected:enabled .mdc-switch__handle::after {
  background: #ffffff !important;
}
mat-slide-toggle button.mdc-switch.mdc-switch--selected:enabled .mdc-switch__handle::before {
  background: #ffffff !important;
}
mat-slide-toggle button.mdc-switch.mdc-switch--unselected:enabled .mdc-switch__handle::after {
  background: #ffffff !important;
}
mat-slide-toggle button.mdc-switch .mdc-switch__handle {
  width: 16px !important;
  height: 16px !important;
  left: 2px !important;
}

.button {
  height: 48px !important;
  border-radius: 12px !important;
  min-width: 188px !important;
  font-size: 12px !important;
  font-weight: 500 !important;
  line-height: 16.88px !important;
  letter-spacing: 0.375px !important;
  text-align: center !important;
}

button.set-category-btn.mat-mdc-raised-button.mat-accent {
  height: 50px !important;
  min-width: 200px !important;
  border: 1px solid #7E54D4 !important;
  color: #7E54D4 !important;
  background-color: #fff !important;
  border-radius: 8px !important;
}
button.set-category-btn.mat-mdc-raised-button.mat-accent p {
  line-height: 14px;
  font-size: 14px;
}
button.set-category-btn.mat-mdc-raised-button.mat-accent .mdc-button__label {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  justify-content: space-between;
}

button.categorys-btn {
  color: #7A728D;
  background: rgba(189, 165, 245, 0.2117647059);
  min-width: 115px;
  height: 25px;
  border-radius: 2px;
  font-weight: 500;
  border: none;
  font-size: 14px;
}

.large-checkbox {
  width: 50px;
  height: 50px;
  border-radius: 8px;
}

:root {
  --primary-color: #7E54D4;
  --not-active: rgba(255, 255, 255, 0.3);
  --font-color: rgba(0, 0, 0, 0.87);
  --accent-color: #00A3FF;
  --error-color: #f44336;
  --disabled-color: #a3a3a3;
  --slate-color: #9E98AE;
  --green-color: #40b453;
  --cancel-color: #F2ECFF;
  --slate-gray-color: #6B7280;
  --midnight-color: #1F2937;
  --ocean-color: #383256;
}

.w-full {
  width: 100%;
}

.w-3\/4 {
  width: 75%;
}

.w-2\/3 {
  width: 66%;
}

.w-1\/2 {
  width: 50%;
}

.w-1\/3 {
  width: 33%;
}

.w-1\/4 {
  width: 25%;
}

.w-1\/5 {
  width: 20%;
}

.w-2\/5 {
  width: 40%;
}

.w-3\/5 {
  width: 60%;
}

.w-1\/6 {
  width: 16.6%;
}

.max-w-fit {
  max-width: fit-content !important;
}

.w-fit {
  width: fit-content;
}

.break-word {
  word-break: break-all;
}

.border-rounded {
  border-radius: 5px;
}

.border-rounded-xl {
  border-radius: 10px !important;
}

.border-rounded-xxl {
  border-radius: 15px !important;
}

.text-center {
  text-align: center;
}

.text-center-i {
  text-align: center !important;
}

.text-right {
  text-align: right !important;
}

.text-underline {
  text-decoration: underline;
}

.txt-error {
  color: rgba(243, 84, 84, 0.5803921569) !important;
}

.border-error {
  border-radius: 5px;
  border: 1px solid rgba(243, 84, 84, 0.5803921569) !important;
}

.w-auto {
  width: auto !important;
}

.max-height-full-screen {
  max-height: 100vh;
}

.cursor-pointer {
  cursor: pointer;
}

.cursor-default {
  cursor: default !important;
}

.h-fit {
  height: fit-content;
}

.h-full {
  height: 100%;
}

.h-full-without-header-footer {
  height: calc(100% - 48px - 80px);
}

.min-h-full {
  min-height: 100%;
}

.min-w-unset {
  min-width: unset !important;
}

.shadow {
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.text-bold {
  font-weight: 600;
}

.m-auto {
  margin: auto;
}

.absolute {
  position: absolute;
}

.absolute-i {
  position: absolute !important;
}

.relative {
  position: relative;
}

.relative-i {
  position: relative !important;
}

.cursor-pointer {
  cursor: pointer;
}

.overflow-hidden {
  overflow: hidden;
}

.overflow-auto {
  overflow: auto;
}

.overflow-y-auto {
  overflow-y: auto;
}

.overflow-y-hidden {
  overflow-y: hidden;
}

.overflow-x-auto {
  overflow-x: auto;
}

.overflow-x-hidden {
  overflow-x: hidden;
}

.fixed {
  position: fixed;
}

.bottom-0 {
  bottom: 0px;
}

.right-0 {
  right: 0px;
}

.text-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
}

.bg-white {
  background-color: white !important;
}

.bg-ocean-gray {
  background-color: #8A809F !important;
}

.bg-primary-color {
  background-color: #7E54D4 !important;
}

.no-wrap {
  white-space: nowrap;
}

.text-capitalize {
  text-transform: capitalize;
}

.text-uppercase {
  text-transform: uppercase;
}

.no-shadow {
  box-shadow: none !important;
}

.txt-ocean-color {
  color: #383256;
}

.txt-ocean-light-color {
  color: #383256;
}

.text-slate {
  color: #9E98AE;
}

.text-white {
  color: white;
}

.text-grey {
  color: #999;
}

.text-info {
  color: #FF7B52;
}

.text-accent-color {
  color: #00A3FF;
}

.text-grey-important {
  color: #999 !important;
}

.color-medium-grey {
  color: #8C8C8C;
}

.color-grey2 {
  color: #6a7d88;
}

.color-ocean {
  color: #383256;
}

.font-weight-500 {
  font-weight: 500;
}

.font-weight-600 {
  font-weight: 600;
}

.font-weight-700 {
  font-weight: 700;
}

.font-weight-800 {
  font-weight: 800;
}

.pointer-events-none {
  pointer-events: none;
}

.text-decoration-none {
  text-decoration: none;
}
.text-decoration-none * {
  text-decoration: none;
}

@media (min-width: 0em) {
  .icon-size-0 {
    font-size: 0px;
    line-height: 0px;
    height: 0px !important;
    width: 0px !important;
  }
  .icon-size-1 {
    font-size: 2px;
    line-height: 2px;
    height: 2px !important;
    width: 2px !important;
  }
  .icon-size-2 {
    font-size: 4px;
    line-height: 4px;
    height: 4px !important;
    width: 4px !important;
  }
  .icon-size-3 {
    font-size: 6px;
    line-height: 6px;
    height: 6px !important;
    width: 6px !important;
  }
  .icon-size-4 {
    font-size: 8px;
    line-height: 8px;
    height: 8px !important;
    width: 8px !important;
  }
  .icon-size-5 {
    font-size: 10px;
    line-height: 10px;
    height: 10px !important;
    width: 10px !important;
  }
  .icon-size-6 {
    font-size: 12px;
    line-height: 12px;
    height: 12px !important;
    width: 12px !important;
  }
  .icon-size-7 {
    font-size: 14px;
    line-height: 14px;
    height: 14px !important;
    width: 14px !important;
  }
  .icon-size-8 {
    font-size: 16px;
    line-height: 16px;
    height: 16px !important;
    width: 16px !important;
  }
  .icon-size-9 {
    font-size: 18px;
    line-height: 18px;
    height: 18px !important;
    width: 18px !important;
  }
  .icon-size-10 {
    font-size: 20px;
    line-height: 20px;
    height: 20px !important;
    width: 20px !important;
  }
  .icon-size-11 {
    font-size: 22px;
    line-height: 22px;
    height: 22px !important;
    width: 22px !important;
  }
  .icon-size-12 {
    font-size: 24px;
    line-height: 24px;
    height: 24px !important;
    width: 24px !important;
  }
  .icon-size-13 {
    font-size: 26px;
    line-height: 26px;
    height: 26px !important;
    width: 26px !important;
  }
  .icon-size-14 {
    font-size: 28px;
    line-height: 28px;
    height: 28px !important;
    width: 28px !important;
  }
  .icon-size-15 {
    font-size: 30px;
    line-height: 30px;
    height: 30px !important;
    width: 30px !important;
  }
  .icon-size-16 {
    font-size: 32px;
    line-height: 32px;
    height: 32px !important;
    width: 32px !important;
  }
  .icon-size-17 {
    font-size: 34px;
    line-height: 34px;
    height: 34px !important;
    width: 34px !important;
  }
  .icon-size-18 {
    font-size: 36px;
    line-height: 36px;
    height: 36px !important;
    width: 36px !important;
  }
  .icon-size-19 {
    font-size: 38px;
    line-height: 38px;
    height: 38px !important;
    width: 38px !important;
  }
  .icon-size-20 {
    font-size: 40px;
    line-height: 40px;
    height: 40px !important;
    width: 40px !important;
  }
  .icon-size-25 {
    font-size: 50px;
    line-height: 50px;
    height: 50px !important;
    width: 50px !important;
  }
  .icon-size-30 {
    font-size: 60px;
    line-height: 60px;
    height: 60px !important;
    width: 60px !important;
  }
  .icon-size-35 {
    font-size: 70px;
    line-height: 70px;
    height: 70px !important;
    width: 70px !important;
  }
  .icon-size-50 {
    font-size: 100px;
    line-height: 100px;
    height: 100px !important;
    width: 100px !important;
  }
  .icon-size-65 {
    font-size: 130px;
    line-height: 130px;
    height: 130px !important;
    width: 130px !important;
  }
  .icon-size-85 {
    font-size: 170px;
    line-height: 170px;
    height: 170px !important;
    width: 170px !important;
  }
  .icon-size-100 {
    font-size: 200px;
    line-height: 200px;
    height: 200px !important;
    width: 200px !important;
  }
  .text-size-0 {
    font-size: 0px;
  }
  .text-size-1 {
    font-size: 2px;
  }
  .text-size-2 {
    font-size: 4px;
  }
  .text-size-3 {
    font-size: 6px;
  }
  .text-size-4 {
    font-size: 8px;
  }
  .text-size-5 {
    font-size: 10px;
  }
  .text-size-6 {
    font-size: 12px;
  }
  .text-size-7 {
    font-size: 14px;
  }
  .text-size-8 {
    font-size: 16px;
  }
  .text-size-9 {
    font-size: 18px;
  }
  .text-size-10 {
    font-size: 20px;
  }
  .text-size-11 {
    font-size: 22px;
  }
  .text-size-12 {
    font-size: 24px;
  }
  .text-size-13 {
    font-size: 26px;
  }
  .text-size-14 {
    font-size: 28px;
  }
  .text-size-15 {
    font-size: 30px;
  }
  .text-size-16 {
    font-size: 32px;
  }
  .text-size-17 {
    font-size: 34px;
  }
  .text-size-18 {
    font-size: 36px;
  }
  .text-size-19 {
    font-size: 38px;
  }
  .text-size-20 {
    font-size: 40px;
  }
  .text-size-25 {
    font-size: 50px;
  }
  .text-size-30 {
    font-size: 60px;
  }
  .text-size-35 {
    font-size: 70px;
  }
  .text-size-50 {
    font-size: 100px;
  }
  .text-size-65 {
    font-size: 130px;
  }
  .text-size-85 {
    font-size: 170px;
  }
  .text-size-100 {
    font-size: 200px;
  }
  .w-0 {
    width: 0px;
  }
  .h-0 {
    height: 0px;
  }
  .h-0-i {
    height: 0px !important;
  }
  .min-h-0 {
    min-height: 0px;
  }
  .min-w-0 {
    min-width: 0px !important;
  }
  .w-1 {
    width: 4px;
  }
  .h-1 {
    height: 4px;
  }
  .h-1-i {
    height: 4px !important;
  }
  .min-h-1 {
    min-height: 4px;
  }
  .min-w-1 {
    min-width: 4px !important;
  }
  .w-2 {
    width: 8px;
  }
  .h-2 {
    height: 8px;
  }
  .h-2-i {
    height: 8px !important;
  }
  .min-h-2 {
    min-height: 8px;
  }
  .min-w-2 {
    min-width: 8px !important;
  }
  .w-3 {
    width: 12px;
  }
  .h-3 {
    height: 12px;
  }
  .h-3-i {
    height: 12px !important;
  }
  .min-h-3 {
    min-height: 12px;
  }
  .min-w-3 {
    min-width: 12px !important;
  }
  .w-4 {
    width: 16px;
  }
  .h-4 {
    height: 16px;
  }
  .h-4-i {
    height: 16px !important;
  }
  .min-h-4 {
    min-height: 16px;
  }
  .min-w-4 {
    min-width: 16px !important;
  }
  .w-5 {
    width: 20px;
  }
  .h-5 {
    height: 20px;
  }
  .h-5-i {
    height: 20px !important;
  }
  .min-h-5 {
    min-height: 20px;
  }
  .min-w-5 {
    min-width: 20px !important;
  }
  .w-6 {
    width: 24px;
  }
  .h-6 {
    height: 24px;
  }
  .h-6-i {
    height: 24px !important;
  }
  .min-h-6 {
    min-height: 24px;
  }
  .min-w-6 {
    min-width: 24px !important;
  }
  .w-7 {
    width: 28px;
  }
  .h-7 {
    height: 28px;
  }
  .h-7-i {
    height: 28px !important;
  }
  .min-h-7 {
    min-height: 28px;
  }
  .min-w-7 {
    min-width: 28px !important;
  }
  .w-8 {
    width: 32px;
  }
  .h-8 {
    height: 32px;
  }
  .h-8-i {
    height: 32px !important;
  }
  .min-h-8 {
    min-height: 32px;
  }
  .min-w-8 {
    min-width: 32px !important;
  }
  .w-9 {
    width: 36px;
  }
  .h-9 {
    height: 36px;
  }
  .h-9-i {
    height: 36px !important;
  }
  .min-h-9 {
    min-height: 36px;
  }
  .min-w-9 {
    min-width: 36px !important;
  }
  .w-10 {
    width: 40px;
  }
  .h-10 {
    height: 40px;
  }
  .h-10-i {
    height: 40px !important;
  }
  .min-h-10 {
    min-height: 40px;
  }
  .min-w-10 {
    min-width: 40px !important;
  }
  .w-11 {
    width: 44px;
  }
  .h-11 {
    height: 44px;
  }
  .h-11-i {
    height: 44px !important;
  }
  .min-h-11 {
    min-height: 44px;
  }
  .min-w-11 {
    min-width: 44px !important;
  }
  .w-12 {
    width: 48px;
  }
  .h-12 {
    height: 48px;
  }
  .h-12-i {
    height: 48px !important;
  }
  .min-h-12 {
    min-height: 48px;
  }
  .min-w-12 {
    min-width: 48px !important;
  }
  .w-13 {
    width: 52px;
  }
  .h-13 {
    height: 52px;
  }
  .h-13-i {
    height: 52px !important;
  }
  .min-h-13 {
    min-height: 52px;
  }
  .min-w-13 {
    min-width: 52px !important;
  }
  .w-14 {
    width: 56px;
  }
  .h-14 {
    height: 56px;
  }
  .h-14-i {
    height: 56px !important;
  }
  .min-h-14 {
    min-height: 56px;
  }
  .min-w-14 {
    min-width: 56px !important;
  }
  .w-15 {
    width: 60px;
  }
  .h-15 {
    height: 60px;
  }
  .h-15-i {
    height: 60px !important;
  }
  .min-h-15 {
    min-height: 60px;
  }
  .min-w-15 {
    min-width: 60px !important;
  }
  .w-16 {
    width: 64px;
  }
  .h-16 {
    height: 64px;
  }
  .h-16-i {
    height: 64px !important;
  }
  .min-h-16 {
    min-height: 64px;
  }
  .min-w-16 {
    min-width: 64px !important;
  }
  .w-17 {
    width: 68px;
  }
  .h-17 {
    height: 68px;
  }
  .h-17-i {
    height: 68px !important;
  }
  .min-h-17 {
    min-height: 68px;
  }
  .min-w-17 {
    min-width: 68px !important;
  }
  .w-18 {
    width: 72px;
  }
  .h-18 {
    height: 72px;
  }
  .h-18-i {
    height: 72px !important;
  }
  .min-h-18 {
    min-height: 72px;
  }
  .min-w-18 {
    min-width: 72px !important;
  }
  .w-19 {
    width: 76px;
  }
  .h-19 {
    height: 76px;
  }
  .h-19-i {
    height: 76px !important;
  }
  .min-h-19 {
    min-height: 76px;
  }
  .min-w-19 {
    min-width: 76px !important;
  }
  .w-20 {
    width: 80px;
  }
  .h-20 {
    height: 80px;
  }
  .h-20-i {
    height: 80px !important;
  }
  .min-h-20 {
    min-height: 80px;
  }
  .min-w-20 {
    min-width: 80px !important;
  }
  .w-25 {
    width: 100px;
  }
  .h-25 {
    height: 100px;
  }
  .h-25-i {
    height: 100px !important;
  }
  .min-h-25 {
    min-height: 100px;
  }
  .min-w-25 {
    min-width: 100px !important;
  }
  .w-30 {
    width: 120px;
  }
  .h-30 {
    height: 120px;
  }
  .h-30-i {
    height: 120px !important;
  }
  .min-h-30 {
    min-height: 120px;
  }
  .min-w-30 {
    min-width: 120px !important;
  }
  .w-35 {
    width: 140px;
  }
  .h-35 {
    height: 140px;
  }
  .h-35-i {
    height: 140px !important;
  }
  .min-h-35 {
    min-height: 140px;
  }
  .min-w-35 {
    min-width: 140px !important;
  }
  .w-50 {
    width: 200px;
  }
  .h-50 {
    height: 200px;
  }
  .h-50-i {
    height: 200px !important;
  }
  .min-h-50 {
    min-height: 200px;
  }
  .min-w-50 {
    min-width: 200px !important;
  }
  .w-65 {
    width: 260px;
  }
  .h-65 {
    height: 260px;
  }
  .h-65-i {
    height: 260px !important;
  }
  .min-h-65 {
    min-height: 260px;
  }
  .min-w-65 {
    min-width: 260px !important;
  }
  .w-85 {
    width: 340px;
  }
  .h-85 {
    height: 340px;
  }
  .h-85-i {
    height: 340px !important;
  }
  .min-h-85 {
    min-height: 340px;
  }
  .min-w-85 {
    min-width: 340px !important;
  }
  .w-100 {
    width: 400px;
  }
  .h-100 {
    height: 400px;
  }
  .h-100-i {
    height: 400px !important;
  }
  .min-h-100 {
    min-height: 400px;
  }
  .min-w-100 {
    min-width: 400px !important;
  }
  .m-0 {
    margin: 0px;
  }
  .mx-0 {
    margin-left: 0px;
    margin-right: 0px;
  }
  .my-0 {
    margin-top: 0px;
    margin-bottom: 0px;
  }
  .ml-0 {
    margin-left: 0px;
  }
  .ml-0-i {
    margin-left: 0px !important;
  }
  .mr-0 {
    margin-right: 0px;
  }
  .mr-0-i {
    margin-right: 0px !important;
  }
  .mb-0 {
    margin-bottom: 0px;
  }
  .mt-0 {
    margin-top: 0px;
  }
  .p-0 {
    padding: 0px;
  }
  .p-0-i {
    padding: 0px !important;
  }
  .px-0 {
    padding-left: 0px;
    padding-right: 0px;
  }
  .px-0-i {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
  .py-0 {
    padding-top: 0px;
    padding-bottom: 0px;
  }
  .pl-0 {
    padding-left: 0px;
  }
  .pr-0 {
    padding-right: 0px;
  }
  .pl-0-i {
    padding-left: 0px !important;
  }
  .pr-0-i {
    padding-right: 0px !important;
  }
  .pb-0 {
    padding-bottom: 0px;
  }
  .pb-0-i {
    padding-bottom: 0px !important;
  }
  .pt-0 {
    padding-top: 0px;
  }
  .m-1 {
    margin: 4px;
  }
  .mx-1 {
    margin-left: 4px;
    margin-right: 4px;
  }
  .my-1 {
    margin-top: 4px;
    margin-bottom: 4px;
  }
  .ml-1 {
    margin-left: 4px;
  }
  .ml-1-i {
    margin-left: 4px !important;
  }
  .mr-1 {
    margin-right: 4px;
  }
  .mr-1-i {
    margin-right: 4px !important;
  }
  .mb-1 {
    margin-bottom: 4px;
  }
  .mt-1 {
    margin-top: 4px;
  }
  .p-1 {
    padding: 4px;
  }
  .p-1-i {
    padding: 4px !important;
  }
  .px-1 {
    padding-left: 4px;
    padding-right: 4px;
  }
  .px-1-i {
    padding-left: 4px !important;
    padding-right: 4px !important;
  }
  .py-1 {
    padding-top: 4px;
    padding-bottom: 4px;
  }
  .pl-1 {
    padding-left: 4px;
  }
  .pr-1 {
    padding-right: 4px;
  }
  .pl-1-i {
    padding-left: 4px !important;
  }
  .pr-1-i {
    padding-right: 4px !important;
  }
  .pb-1 {
    padding-bottom: 4px;
  }
  .pb-1-i {
    padding-bottom: 4px !important;
  }
  .pt-1 {
    padding-top: 4px;
  }
  .m-2 {
    margin: 8px;
  }
  .mx-2 {
    margin-left: 8px;
    margin-right: 8px;
  }
  .my-2 {
    margin-top: 8px;
    margin-bottom: 8px;
  }
  .ml-2 {
    margin-left: 8px;
  }
  .ml-2-i {
    margin-left: 8px !important;
  }
  .mr-2 {
    margin-right: 8px;
  }
  .mr-2-i {
    margin-right: 8px !important;
  }
  .mb-2 {
    margin-bottom: 8px;
  }
  .mt-2 {
    margin-top: 8px;
  }
  .p-2 {
    padding: 8px;
  }
  .p-2-i {
    padding: 8px !important;
  }
  .px-2 {
    padding-left: 8px;
    padding-right: 8px;
  }
  .px-2-i {
    padding-left: 8px !important;
    padding-right: 8px !important;
  }
  .py-2 {
    padding-top: 8px;
    padding-bottom: 8px;
  }
  .pl-2 {
    padding-left: 8px;
  }
  .pr-2 {
    padding-right: 8px;
  }
  .pl-2-i {
    padding-left: 8px !important;
  }
  .pr-2-i {
    padding-right: 8px !important;
  }
  .pb-2 {
    padding-bottom: 8px;
  }
  .pb-2-i {
    padding-bottom: 8px !important;
  }
  .pt-2 {
    padding-top: 8px;
  }
  .m-3 {
    margin: 12px;
  }
  .mx-3 {
    margin-left: 12px;
    margin-right: 12px;
  }
  .my-3 {
    margin-top: 12px;
    margin-bottom: 12px;
  }
  .ml-3 {
    margin-left: 12px;
  }
  .ml-3-i {
    margin-left: 12px !important;
  }
  .mr-3 {
    margin-right: 12px;
  }
  .mr-3-i {
    margin-right: 12px !important;
  }
  .mb-3 {
    margin-bottom: 12px;
  }
  .mt-3 {
    margin-top: 12px;
  }
  .p-3 {
    padding: 12px;
  }
  .p-3-i {
    padding: 12px !important;
  }
  .px-3 {
    padding-left: 12px;
    padding-right: 12px;
  }
  .px-3-i {
    padding-left: 12px !important;
    padding-right: 12px !important;
  }
  .py-3 {
    padding-top: 12px;
    padding-bottom: 12px;
  }
  .pl-3 {
    padding-left: 12px;
  }
  .pr-3 {
    padding-right: 12px;
  }
  .pl-3-i {
    padding-left: 12px !important;
  }
  .pr-3-i {
    padding-right: 12px !important;
  }
  .pb-3 {
    padding-bottom: 12px;
  }
  .pb-3-i {
    padding-bottom: 12px !important;
  }
  .pt-3 {
    padding-top: 12px;
  }
  .m-4 {
    margin: 16px;
  }
  .mx-4 {
    margin-left: 16px;
    margin-right: 16px;
  }
  .my-4 {
    margin-top: 16px;
    margin-bottom: 16px;
  }
  .ml-4 {
    margin-left: 16px;
  }
  .ml-4-i {
    margin-left: 16px !important;
  }
  .mr-4 {
    margin-right: 16px;
  }
  .mr-4-i {
    margin-right: 16px !important;
  }
  .mb-4 {
    margin-bottom: 16px;
  }
  .mt-4 {
    margin-top: 16px;
  }
  .p-4 {
    padding: 16px;
  }
  .p-4-i {
    padding: 16px !important;
  }
  .px-4 {
    padding-left: 16px;
    padding-right: 16px;
  }
  .px-4-i {
    padding-left: 16px !important;
    padding-right: 16px !important;
  }
  .py-4 {
    padding-top: 16px;
    padding-bottom: 16px;
  }
  .pl-4 {
    padding-left: 16px;
  }
  .pr-4 {
    padding-right: 16px;
  }
  .pl-4-i {
    padding-left: 16px !important;
  }
  .pr-4-i {
    padding-right: 16px !important;
  }
  .pb-4 {
    padding-bottom: 16px;
  }
  .pb-4-i {
    padding-bottom: 16px !important;
  }
  .pt-4 {
    padding-top: 16px;
  }
  .m-5 {
    margin: 20px;
  }
  .mx-5 {
    margin-left: 20px;
    margin-right: 20px;
  }
  .my-5 {
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .ml-5 {
    margin-left: 20px;
  }
  .ml-5-i {
    margin-left: 20px !important;
  }
  .mr-5 {
    margin-right: 20px;
  }
  .mr-5-i {
    margin-right: 20px !important;
  }
  .mb-5 {
    margin-bottom: 20px;
  }
  .mt-5 {
    margin-top: 20px;
  }
  .p-5 {
    padding: 20px;
  }
  .p-5-i {
    padding: 20px !important;
  }
  .px-5 {
    padding-left: 20px;
    padding-right: 20px;
  }
  .px-5-i {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }
  .py-5 {
    padding-top: 20px;
    padding-bottom: 20px;
  }
  .pl-5 {
    padding-left: 20px;
  }
  .pr-5 {
    padding-right: 20px;
  }
  .pl-5-i {
    padding-left: 20px !important;
  }
  .pr-5-i {
    padding-right: 20px !important;
  }
  .pb-5 {
    padding-bottom: 20px;
  }
  .pb-5-i {
    padding-bottom: 20px !important;
  }
  .pt-5 {
    padding-top: 20px;
  }
  .m-6 {
    margin: 24px;
  }
  .mx-6 {
    margin-left: 24px;
    margin-right: 24px;
  }
  .my-6 {
    margin-top: 24px;
    margin-bottom: 24px;
  }
  .ml-6 {
    margin-left: 24px;
  }
  .ml-6-i {
    margin-left: 24px !important;
  }
  .mr-6 {
    margin-right: 24px;
  }
  .mr-6-i {
    margin-right: 24px !important;
  }
  .mb-6 {
    margin-bottom: 24px;
  }
  .mt-6 {
    margin-top: 24px;
  }
  .p-6 {
    padding: 24px;
  }
  .p-6-i {
    padding: 24px !important;
  }
  .px-6 {
    padding-left: 24px;
    padding-right: 24px;
  }
  .px-6-i {
    padding-left: 24px !important;
    padding-right: 24px !important;
  }
  .py-6 {
    padding-top: 24px;
    padding-bottom: 24px;
  }
  .pl-6 {
    padding-left: 24px;
  }
  .pr-6 {
    padding-right: 24px;
  }
  .pl-6-i {
    padding-left: 24px !important;
  }
  .pr-6-i {
    padding-right: 24px !important;
  }
  .pb-6 {
    padding-bottom: 24px;
  }
  .pb-6-i {
    padding-bottom: 24px !important;
  }
  .pt-6 {
    padding-top: 24px;
  }
  .m-7 {
    margin: 28px;
  }
  .mx-7 {
    margin-left: 28px;
    margin-right: 28px;
  }
  .my-7 {
    margin-top: 28px;
    margin-bottom: 28px;
  }
  .ml-7 {
    margin-left: 28px;
  }
  .ml-7-i {
    margin-left: 28px !important;
  }
  .mr-7 {
    margin-right: 28px;
  }
  .mr-7-i {
    margin-right: 28px !important;
  }
  .mb-7 {
    margin-bottom: 28px;
  }
  .mt-7 {
    margin-top: 28px;
  }
  .p-7 {
    padding: 28px;
  }
  .p-7-i {
    padding: 28px !important;
  }
  .px-7 {
    padding-left: 28px;
    padding-right: 28px;
  }
  .px-7-i {
    padding-left: 28px !important;
    padding-right: 28px !important;
  }
  .py-7 {
    padding-top: 28px;
    padding-bottom: 28px;
  }
  .pl-7 {
    padding-left: 28px;
  }
  .pr-7 {
    padding-right: 28px;
  }
  .pl-7-i {
    padding-left: 28px !important;
  }
  .pr-7-i {
    padding-right: 28px !important;
  }
  .pb-7 {
    padding-bottom: 28px;
  }
  .pb-7-i {
    padding-bottom: 28px !important;
  }
  .pt-7 {
    padding-top: 28px;
  }
  .m-8 {
    margin: 32px;
  }
  .mx-8 {
    margin-left: 32px;
    margin-right: 32px;
  }
  .my-8 {
    margin-top: 32px;
    margin-bottom: 32px;
  }
  .ml-8 {
    margin-left: 32px;
  }
  .ml-8-i {
    margin-left: 32px !important;
  }
  .mr-8 {
    margin-right: 32px;
  }
  .mr-8-i {
    margin-right: 32px !important;
  }
  .mb-8 {
    margin-bottom: 32px;
  }
  .mt-8 {
    margin-top: 32px;
  }
  .p-8 {
    padding: 32px;
  }
  .p-8-i {
    padding: 32px !important;
  }
  .px-8 {
    padding-left: 32px;
    padding-right: 32px;
  }
  .px-8-i {
    padding-left: 32px !important;
    padding-right: 32px !important;
  }
  .py-8 {
    padding-top: 32px;
    padding-bottom: 32px;
  }
  .pl-8 {
    padding-left: 32px;
  }
  .pr-8 {
    padding-right: 32px;
  }
  .pl-8-i {
    padding-left: 32px !important;
  }
  .pr-8-i {
    padding-right: 32px !important;
  }
  .pb-8 {
    padding-bottom: 32px;
  }
  .pb-8-i {
    padding-bottom: 32px !important;
  }
  .pt-8 {
    padding-top: 32px;
  }
  .m-9 {
    margin: 36px;
  }
  .mx-9 {
    margin-left: 36px;
    margin-right: 36px;
  }
  .my-9 {
    margin-top: 36px;
    margin-bottom: 36px;
  }
  .ml-9 {
    margin-left: 36px;
  }
  .ml-9-i {
    margin-left: 36px !important;
  }
  .mr-9 {
    margin-right: 36px;
  }
  .mr-9-i {
    margin-right: 36px !important;
  }
  .mb-9 {
    margin-bottom: 36px;
  }
  .mt-9 {
    margin-top: 36px;
  }
  .p-9 {
    padding: 36px;
  }
  .p-9-i {
    padding: 36px !important;
  }
  .px-9 {
    padding-left: 36px;
    padding-right: 36px;
  }
  .px-9-i {
    padding-left: 36px !important;
    padding-right: 36px !important;
  }
  .py-9 {
    padding-top: 36px;
    padding-bottom: 36px;
  }
  .pl-9 {
    padding-left: 36px;
  }
  .pr-9 {
    padding-right: 36px;
  }
  .pl-9-i {
    padding-left: 36px !important;
  }
  .pr-9-i {
    padding-right: 36px !important;
  }
  .pb-9 {
    padding-bottom: 36px;
  }
  .pb-9-i {
    padding-bottom: 36px !important;
  }
  .pt-9 {
    padding-top: 36px;
  }
  .m-10 {
    margin: 40px;
  }
  .mx-10 {
    margin-left: 40px;
    margin-right: 40px;
  }
  .my-10 {
    margin-top: 40px;
    margin-bottom: 40px;
  }
  .ml-10 {
    margin-left: 40px;
  }
  .ml-10-i {
    margin-left: 40px !important;
  }
  .mr-10 {
    margin-right: 40px;
  }
  .mr-10-i {
    margin-right: 40px !important;
  }
  .mb-10 {
    margin-bottom: 40px;
  }
  .mt-10 {
    margin-top: 40px;
  }
  .p-10 {
    padding: 40px;
  }
  .p-10-i {
    padding: 40px !important;
  }
  .px-10 {
    padding-left: 40px;
    padding-right: 40px;
  }
  .px-10-i {
    padding-left: 40px !important;
    padding-right: 40px !important;
  }
  .py-10 {
    padding-top: 40px;
    padding-bottom: 40px;
  }
  .pl-10 {
    padding-left: 40px;
  }
  .pr-10 {
    padding-right: 40px;
  }
  .pl-10-i {
    padding-left: 40px !important;
  }
  .pr-10-i {
    padding-right: 40px !important;
  }
  .pb-10 {
    padding-bottom: 40px;
  }
  .pb-10-i {
    padding-bottom: 40px !important;
  }
  .pt-10 {
    padding-top: 40px;
  }
  .m-11 {
    margin: 44px;
  }
  .mx-11 {
    margin-left: 44px;
    margin-right: 44px;
  }
  .my-11 {
    margin-top: 44px;
    margin-bottom: 44px;
  }
  .ml-11 {
    margin-left: 44px;
  }
  .ml-11-i {
    margin-left: 44px !important;
  }
  .mr-11 {
    margin-right: 44px;
  }
  .mr-11-i {
    margin-right: 44px !important;
  }
  .mb-11 {
    margin-bottom: 44px;
  }
  .mt-11 {
    margin-top: 44px;
  }
  .p-11 {
    padding: 44px;
  }
  .p-11-i {
    padding: 44px !important;
  }
  .px-11 {
    padding-left: 44px;
    padding-right: 44px;
  }
  .px-11-i {
    padding-left: 44px !important;
    padding-right: 44px !important;
  }
  .py-11 {
    padding-top: 44px;
    padding-bottom: 44px;
  }
  .pl-11 {
    padding-left: 44px;
  }
  .pr-11 {
    padding-right: 44px;
  }
  .pl-11-i {
    padding-left: 44px !important;
  }
  .pr-11-i {
    padding-right: 44px !important;
  }
  .pb-11 {
    padding-bottom: 44px;
  }
  .pb-11-i {
    padding-bottom: 44px !important;
  }
  .pt-11 {
    padding-top: 44px;
  }
  .m-12 {
    margin: 48px;
  }
  .mx-12 {
    margin-left: 48px;
    margin-right: 48px;
  }
  .my-12 {
    margin-top: 48px;
    margin-bottom: 48px;
  }
  .ml-12 {
    margin-left: 48px;
  }
  .ml-12-i {
    margin-left: 48px !important;
  }
  .mr-12 {
    margin-right: 48px;
  }
  .mr-12-i {
    margin-right: 48px !important;
  }
  .mb-12 {
    margin-bottom: 48px;
  }
  .mt-12 {
    margin-top: 48px;
  }
  .p-12 {
    padding: 48px;
  }
  .p-12-i {
    padding: 48px !important;
  }
  .px-12 {
    padding-left: 48px;
    padding-right: 48px;
  }
  .px-12-i {
    padding-left: 48px !important;
    padding-right: 48px !important;
  }
  .py-12 {
    padding-top: 48px;
    padding-bottom: 48px;
  }
  .pl-12 {
    padding-left: 48px;
  }
  .pr-12 {
    padding-right: 48px;
  }
  .pl-12-i {
    padding-left: 48px !important;
  }
  .pr-12-i {
    padding-right: 48px !important;
  }
  .pb-12 {
    padding-bottom: 48px;
  }
  .pb-12-i {
    padding-bottom: 48px !important;
  }
  .pt-12 {
    padding-top: 48px;
  }
  .m-13 {
    margin: 52px;
  }
  .mx-13 {
    margin-left: 52px;
    margin-right: 52px;
  }
  .my-13 {
    margin-top: 52px;
    margin-bottom: 52px;
  }
  .ml-13 {
    margin-left: 52px;
  }
  .ml-13-i {
    margin-left: 52px !important;
  }
  .mr-13 {
    margin-right: 52px;
  }
  .mr-13-i {
    margin-right: 52px !important;
  }
  .mb-13 {
    margin-bottom: 52px;
  }
  .mt-13 {
    margin-top: 52px;
  }
  .p-13 {
    padding: 52px;
  }
  .p-13-i {
    padding: 52px !important;
  }
  .px-13 {
    padding-left: 52px;
    padding-right: 52px;
  }
  .px-13-i {
    padding-left: 52px !important;
    padding-right: 52px !important;
  }
  .py-13 {
    padding-top: 52px;
    padding-bottom: 52px;
  }
  .pl-13 {
    padding-left: 52px;
  }
  .pr-13 {
    padding-right: 52px;
  }
  .pl-13-i {
    padding-left: 52px !important;
  }
  .pr-13-i {
    padding-right: 52px !important;
  }
  .pb-13 {
    padding-bottom: 52px;
  }
  .pb-13-i {
    padding-bottom: 52px !important;
  }
  .pt-13 {
    padding-top: 52px;
  }
  .m-14 {
    margin: 56px;
  }
  .mx-14 {
    margin-left: 56px;
    margin-right: 56px;
  }
  .my-14 {
    margin-top: 56px;
    margin-bottom: 56px;
  }
  .ml-14 {
    margin-left: 56px;
  }
  .ml-14-i {
    margin-left: 56px !important;
  }
  .mr-14 {
    margin-right: 56px;
  }
  .mr-14-i {
    margin-right: 56px !important;
  }
  .mb-14 {
    margin-bottom: 56px;
  }
  .mt-14 {
    margin-top: 56px;
  }
  .p-14 {
    padding: 56px;
  }
  .p-14-i {
    padding: 56px !important;
  }
  .px-14 {
    padding-left: 56px;
    padding-right: 56px;
  }
  .px-14-i {
    padding-left: 56px !important;
    padding-right: 56px !important;
  }
  .py-14 {
    padding-top: 56px;
    padding-bottom: 56px;
  }
  .pl-14 {
    padding-left: 56px;
  }
  .pr-14 {
    padding-right: 56px;
  }
  .pl-14-i {
    padding-left: 56px !important;
  }
  .pr-14-i {
    padding-right: 56px !important;
  }
  .pb-14 {
    padding-bottom: 56px;
  }
  .pb-14-i {
    padding-bottom: 56px !important;
  }
  .pt-14 {
    padding-top: 56px;
  }
  .m-15 {
    margin: 60px;
  }
  .mx-15 {
    margin-left: 60px;
    margin-right: 60px;
  }
  .my-15 {
    margin-top: 60px;
    margin-bottom: 60px;
  }
  .ml-15 {
    margin-left: 60px;
  }
  .ml-15-i {
    margin-left: 60px !important;
  }
  .mr-15 {
    margin-right: 60px;
  }
  .mr-15-i {
    margin-right: 60px !important;
  }
  .mb-15 {
    margin-bottom: 60px;
  }
  .mt-15 {
    margin-top: 60px;
  }
  .p-15 {
    padding: 60px;
  }
  .p-15-i {
    padding: 60px !important;
  }
  .px-15 {
    padding-left: 60px;
    padding-right: 60px;
  }
  .px-15-i {
    padding-left: 60px !important;
    padding-right: 60px !important;
  }
  .py-15 {
    padding-top: 60px;
    padding-bottom: 60px;
  }
  .pl-15 {
    padding-left: 60px;
  }
  .pr-15 {
    padding-right: 60px;
  }
  .pl-15-i {
    padding-left: 60px !important;
  }
  .pr-15-i {
    padding-right: 60px !important;
  }
  .pb-15 {
    padding-bottom: 60px;
  }
  .pb-15-i {
    padding-bottom: 60px !important;
  }
  .pt-15 {
    padding-top: 60px;
  }
  .m-16 {
    margin: 64px;
  }
  .mx-16 {
    margin-left: 64px;
    margin-right: 64px;
  }
  .my-16 {
    margin-top: 64px;
    margin-bottom: 64px;
  }
  .ml-16 {
    margin-left: 64px;
  }
  .ml-16-i {
    margin-left: 64px !important;
  }
  .mr-16 {
    margin-right: 64px;
  }
  .mr-16-i {
    margin-right: 64px !important;
  }
  .mb-16 {
    margin-bottom: 64px;
  }
  .mt-16 {
    margin-top: 64px;
  }
  .p-16 {
    padding: 64px;
  }
  .p-16-i {
    padding: 64px !important;
  }
  .px-16 {
    padding-left: 64px;
    padding-right: 64px;
  }
  .px-16-i {
    padding-left: 64px !important;
    padding-right: 64px !important;
  }
  .py-16 {
    padding-top: 64px;
    padding-bottom: 64px;
  }
  .pl-16 {
    padding-left: 64px;
  }
  .pr-16 {
    padding-right: 64px;
  }
  .pl-16-i {
    padding-left: 64px !important;
  }
  .pr-16-i {
    padding-right: 64px !important;
  }
  .pb-16 {
    padding-bottom: 64px;
  }
  .pb-16-i {
    padding-bottom: 64px !important;
  }
  .pt-16 {
    padding-top: 64px;
  }
  .m-17 {
    margin: 68px;
  }
  .mx-17 {
    margin-left: 68px;
    margin-right: 68px;
  }
  .my-17 {
    margin-top: 68px;
    margin-bottom: 68px;
  }
  .ml-17 {
    margin-left: 68px;
  }
  .ml-17-i {
    margin-left: 68px !important;
  }
  .mr-17 {
    margin-right: 68px;
  }
  .mr-17-i {
    margin-right: 68px !important;
  }
  .mb-17 {
    margin-bottom: 68px;
  }
  .mt-17 {
    margin-top: 68px;
  }
  .p-17 {
    padding: 68px;
  }
  .p-17-i {
    padding: 68px !important;
  }
  .px-17 {
    padding-left: 68px;
    padding-right: 68px;
  }
  .px-17-i {
    padding-left: 68px !important;
    padding-right: 68px !important;
  }
  .py-17 {
    padding-top: 68px;
    padding-bottom: 68px;
  }
  .pl-17 {
    padding-left: 68px;
  }
  .pr-17 {
    padding-right: 68px;
  }
  .pl-17-i {
    padding-left: 68px !important;
  }
  .pr-17-i {
    padding-right: 68px !important;
  }
  .pb-17 {
    padding-bottom: 68px;
  }
  .pb-17-i {
    padding-bottom: 68px !important;
  }
  .pt-17 {
    padding-top: 68px;
  }
  .m-18 {
    margin: 72px;
  }
  .mx-18 {
    margin-left: 72px;
    margin-right: 72px;
  }
  .my-18 {
    margin-top: 72px;
    margin-bottom: 72px;
  }
  .ml-18 {
    margin-left: 72px;
  }
  .ml-18-i {
    margin-left: 72px !important;
  }
  .mr-18 {
    margin-right: 72px;
  }
  .mr-18-i {
    margin-right: 72px !important;
  }
  .mb-18 {
    margin-bottom: 72px;
  }
  .mt-18 {
    margin-top: 72px;
  }
  .p-18 {
    padding: 72px;
  }
  .p-18-i {
    padding: 72px !important;
  }
  .px-18 {
    padding-left: 72px;
    padding-right: 72px;
  }
  .px-18-i {
    padding-left: 72px !important;
    padding-right: 72px !important;
  }
  .py-18 {
    padding-top: 72px;
    padding-bottom: 72px;
  }
  .pl-18 {
    padding-left: 72px;
  }
  .pr-18 {
    padding-right: 72px;
  }
  .pl-18-i {
    padding-left: 72px !important;
  }
  .pr-18-i {
    padding-right: 72px !important;
  }
  .pb-18 {
    padding-bottom: 72px;
  }
  .pb-18-i {
    padding-bottom: 72px !important;
  }
  .pt-18 {
    padding-top: 72px;
  }
  .m-19 {
    margin: 76px;
  }
  .mx-19 {
    margin-left: 76px;
    margin-right: 76px;
  }
  .my-19 {
    margin-top: 76px;
    margin-bottom: 76px;
  }
  .ml-19 {
    margin-left: 76px;
  }
  .ml-19-i {
    margin-left: 76px !important;
  }
  .mr-19 {
    margin-right: 76px;
  }
  .mr-19-i {
    margin-right: 76px !important;
  }
  .mb-19 {
    margin-bottom: 76px;
  }
  .mt-19 {
    margin-top: 76px;
  }
  .p-19 {
    padding: 76px;
  }
  .p-19-i {
    padding: 76px !important;
  }
  .px-19 {
    padding-left: 76px;
    padding-right: 76px;
  }
  .px-19-i {
    padding-left: 76px !important;
    padding-right: 76px !important;
  }
  .py-19 {
    padding-top: 76px;
    padding-bottom: 76px;
  }
  .pl-19 {
    padding-left: 76px;
  }
  .pr-19 {
    padding-right: 76px;
  }
  .pl-19-i {
    padding-left: 76px !important;
  }
  .pr-19-i {
    padding-right: 76px !important;
  }
  .pb-19 {
    padding-bottom: 76px;
  }
  .pb-19-i {
    padding-bottom: 76px !important;
  }
  .pt-19 {
    padding-top: 76px;
  }
  .m-20 {
    margin: 80px;
  }
  .mx-20 {
    margin-left: 80px;
    margin-right: 80px;
  }
  .my-20 {
    margin-top: 80px;
    margin-bottom: 80px;
  }
  .ml-20 {
    margin-left: 80px;
  }
  .ml-20-i {
    margin-left: 80px !important;
  }
  .mr-20 {
    margin-right: 80px;
  }
  .mr-20-i {
    margin-right: 80px !important;
  }
  .mb-20 {
    margin-bottom: 80px;
  }
  .mt-20 {
    margin-top: 80px;
  }
  .p-20 {
    padding: 80px;
  }
  .p-20-i {
    padding: 80px !important;
  }
  .px-20 {
    padding-left: 80px;
    padding-right: 80px;
  }
  .px-20-i {
    padding-left: 80px !important;
    padding-right: 80px !important;
  }
  .py-20 {
    padding-top: 80px;
    padding-bottom: 80px;
  }
  .pl-20 {
    padding-left: 80px;
  }
  .pr-20 {
    padding-right: 80px;
  }
  .pl-20-i {
    padding-left: 80px !important;
  }
  .pr-20-i {
    padding-right: 80px !important;
  }
  .pb-20 {
    padding-bottom: 80px;
  }
  .pb-20-i {
    padding-bottom: 80px !important;
  }
  .pt-20 {
    padding-top: 80px;
  }
  .m-25 {
    margin: 100px;
  }
  .mx-25 {
    margin-left: 100px;
    margin-right: 100px;
  }
  .my-25 {
    margin-top: 100px;
    margin-bottom: 100px;
  }
  .ml-25 {
    margin-left: 100px;
  }
  .ml-25-i {
    margin-left: 100px !important;
  }
  .mr-25 {
    margin-right: 100px;
  }
  .mr-25-i {
    margin-right: 100px !important;
  }
  .mb-25 {
    margin-bottom: 100px;
  }
  .mt-25 {
    margin-top: 100px;
  }
  .p-25 {
    padding: 100px;
  }
  .p-25-i {
    padding: 100px !important;
  }
  .px-25 {
    padding-left: 100px;
    padding-right: 100px;
  }
  .px-25-i {
    padding-left: 100px !important;
    padding-right: 100px !important;
  }
  .py-25 {
    padding-top: 100px;
    padding-bottom: 100px;
  }
  .pl-25 {
    padding-left: 100px;
  }
  .pr-25 {
    padding-right: 100px;
  }
  .pl-25-i {
    padding-left: 100px !important;
  }
  .pr-25-i {
    padding-right: 100px !important;
  }
  .pb-25 {
    padding-bottom: 100px;
  }
  .pb-25-i {
    padding-bottom: 100px !important;
  }
  .pt-25 {
    padding-top: 100px;
  }
  .m-30 {
    margin: 120px;
  }
  .mx-30 {
    margin-left: 120px;
    margin-right: 120px;
  }
  .my-30 {
    margin-top: 120px;
    margin-bottom: 120px;
  }
  .ml-30 {
    margin-left: 120px;
  }
  .ml-30-i {
    margin-left: 120px !important;
  }
  .mr-30 {
    margin-right: 120px;
  }
  .mr-30-i {
    margin-right: 120px !important;
  }
  .mb-30 {
    margin-bottom: 120px;
  }
  .mt-30 {
    margin-top: 120px;
  }
  .p-30 {
    padding: 120px;
  }
  .p-30-i {
    padding: 120px !important;
  }
  .px-30 {
    padding-left: 120px;
    padding-right: 120px;
  }
  .px-30-i {
    padding-left: 120px !important;
    padding-right: 120px !important;
  }
  .py-30 {
    padding-top: 120px;
    padding-bottom: 120px;
  }
  .pl-30 {
    padding-left: 120px;
  }
  .pr-30 {
    padding-right: 120px;
  }
  .pl-30-i {
    padding-left: 120px !important;
  }
  .pr-30-i {
    padding-right: 120px !important;
  }
  .pb-30 {
    padding-bottom: 120px;
  }
  .pb-30-i {
    padding-bottom: 120px !important;
  }
  .pt-30 {
    padding-top: 120px;
  }
  .m-35 {
    margin: 140px;
  }
  .mx-35 {
    margin-left: 140px;
    margin-right: 140px;
  }
  .my-35 {
    margin-top: 140px;
    margin-bottom: 140px;
  }
  .ml-35 {
    margin-left: 140px;
  }
  .ml-35-i {
    margin-left: 140px !important;
  }
  .mr-35 {
    margin-right: 140px;
  }
  .mr-35-i {
    margin-right: 140px !important;
  }
  .mb-35 {
    margin-bottom: 140px;
  }
  .mt-35 {
    margin-top: 140px;
  }
  .p-35 {
    padding: 140px;
  }
  .p-35-i {
    padding: 140px !important;
  }
  .px-35 {
    padding-left: 140px;
    padding-right: 140px;
  }
  .px-35-i {
    padding-left: 140px !important;
    padding-right: 140px !important;
  }
  .py-35 {
    padding-top: 140px;
    padding-bottom: 140px;
  }
  .pl-35 {
    padding-left: 140px;
  }
  .pr-35 {
    padding-right: 140px;
  }
  .pl-35-i {
    padding-left: 140px !important;
  }
  .pr-35-i {
    padding-right: 140px !important;
  }
  .pb-35 {
    padding-bottom: 140px;
  }
  .pb-35-i {
    padding-bottom: 140px !important;
  }
  .pt-35 {
    padding-top: 140px;
  }
  .m-50 {
    margin: 200px;
  }
  .mx-50 {
    margin-left: 200px;
    margin-right: 200px;
  }
  .my-50 {
    margin-top: 200px;
    margin-bottom: 200px;
  }
  .ml-50 {
    margin-left: 200px;
  }
  .ml-50-i {
    margin-left: 200px !important;
  }
  .mr-50 {
    margin-right: 200px;
  }
  .mr-50-i {
    margin-right: 200px !important;
  }
  .mb-50 {
    margin-bottom: 200px;
  }
  .mt-50 {
    margin-top: 200px;
  }
  .p-50 {
    padding: 200px;
  }
  .p-50-i {
    padding: 200px !important;
  }
  .px-50 {
    padding-left: 200px;
    padding-right: 200px;
  }
  .px-50-i {
    padding-left: 200px !important;
    padding-right: 200px !important;
  }
  .py-50 {
    padding-top: 200px;
    padding-bottom: 200px;
  }
  .pl-50 {
    padding-left: 200px;
  }
  .pr-50 {
    padding-right: 200px;
  }
  .pl-50-i {
    padding-left: 200px !important;
  }
  .pr-50-i {
    padding-right: 200px !important;
  }
  .pb-50 {
    padding-bottom: 200px;
  }
  .pb-50-i {
    padding-bottom: 200px !important;
  }
  .pt-50 {
    padding-top: 200px;
  }
  .m-65 {
    margin: 260px;
  }
  .mx-65 {
    margin-left: 260px;
    margin-right: 260px;
  }
  .my-65 {
    margin-top: 260px;
    margin-bottom: 260px;
  }
  .ml-65 {
    margin-left: 260px;
  }
  .ml-65-i {
    margin-left: 260px !important;
  }
  .mr-65 {
    margin-right: 260px;
  }
  .mr-65-i {
    margin-right: 260px !important;
  }
  .mb-65 {
    margin-bottom: 260px;
  }
  .mt-65 {
    margin-top: 260px;
  }
  .p-65 {
    padding: 260px;
  }
  .p-65-i {
    padding: 260px !important;
  }
  .px-65 {
    padding-left: 260px;
    padding-right: 260px;
  }
  .px-65-i {
    padding-left: 260px !important;
    padding-right: 260px !important;
  }
  .py-65 {
    padding-top: 260px;
    padding-bottom: 260px;
  }
  .pl-65 {
    padding-left: 260px;
  }
  .pr-65 {
    padding-right: 260px;
  }
  .pl-65-i {
    padding-left: 260px !important;
  }
  .pr-65-i {
    padding-right: 260px !important;
  }
  .pb-65 {
    padding-bottom: 260px;
  }
  .pb-65-i {
    padding-bottom: 260px !important;
  }
  .pt-65 {
    padding-top: 260px;
  }
  .m-85 {
    margin: 340px;
  }
  .mx-85 {
    margin-left: 340px;
    margin-right: 340px;
  }
  .my-85 {
    margin-top: 340px;
    margin-bottom: 340px;
  }
  .ml-85 {
    margin-left: 340px;
  }
  .ml-85-i {
    margin-left: 340px !important;
  }
  .mr-85 {
    margin-right: 340px;
  }
  .mr-85-i {
    margin-right: 340px !important;
  }
  .mb-85 {
    margin-bottom: 340px;
  }
  .mt-85 {
    margin-top: 340px;
  }
  .p-85 {
    padding: 340px;
  }
  .p-85-i {
    padding: 340px !important;
  }
  .px-85 {
    padding-left: 340px;
    padding-right: 340px;
  }
  .px-85-i {
    padding-left: 340px !important;
    padding-right: 340px !important;
  }
  .py-85 {
    padding-top: 340px;
    padding-bottom: 340px;
  }
  .pl-85 {
    padding-left: 340px;
  }
  .pr-85 {
    padding-right: 340px;
  }
  .pl-85-i {
    padding-left: 340px !important;
  }
  .pr-85-i {
    padding-right: 340px !important;
  }
  .pb-85 {
    padding-bottom: 340px;
  }
  .pb-85-i {
    padding-bottom: 340px !important;
  }
  .pt-85 {
    padding-top: 340px;
  }
  .m-100 {
    margin: 400px;
  }
  .mx-100 {
    margin-left: 400px;
    margin-right: 400px;
  }
  .my-100 {
    margin-top: 400px;
    margin-bottom: 400px;
  }
  .ml-100 {
    margin-left: 400px;
  }
  .ml-100-i {
    margin-left: 400px !important;
  }
  .mr-100 {
    margin-right: 400px;
  }
  .mr-100-i {
    margin-right: 400px !important;
  }
  .mb-100 {
    margin-bottom: 400px;
  }
  .mt-100 {
    margin-top: 400px;
  }
  .p-100 {
    padding: 400px;
  }
  .p-100-i {
    padding: 400px !important;
  }
  .px-100 {
    padding-left: 400px;
    padding-right: 400px;
  }
  .px-100-i {
    padding-left: 400px !important;
    padding-right: 400px !important;
  }
  .py-100 {
    padding-top: 400px;
    padding-bottom: 400px;
  }
  .pl-100 {
    padding-left: 400px;
  }
  .pr-100 {
    padding-right: 400px;
  }
  .pl-100-i {
    padding-left: 400px !important;
  }
  .pr-100-i {
    padding-right: 400px !important;
  }
  .pb-100 {
    padding-bottom: 400px;
  }
  .pb-100-i {
    padding-bottom: 400px !important;
  }
  .pt-100 {
    padding-top: 400px;
  }
  .d-flex {
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flexbox;
    display: -ms-flex;
    display: flex;
  }
  .d-flex-i {
    display: -webkit-flex !important;
    display: -moz-flex !important;
    display: -ms-flex !importantbox;
    display: -ms-flex !important;
    display: flex !important;
  }
  .d-block {
    display: -webkit-block;
    display: -moz-block;
    display: -ms-blockbox;
    display: -ms-block;
    display: block;
  }
  .d-grid {
    display: -webkit-grid;
    display: -moz-grid;
    display: -ms-gridbox;
    display: -ms-grid;
    display: grid;
  }
  .d-inline {
    display: -webkit-inline-flex;
    display: -moz-inline-flex;
    display: -ms-inline-flexbox;
    display: -ms-inline-flex;
    display: inline-flex;
  }
  .d-none {
    display: -webkit-none;
    display: -moz-none;
    display: -ms-nonebox;
    display: -ms-none;
    display: none;
  }
  .direction-row {
    -webkit-flex-direction: row;
    -moz-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
  }
  .directionrow-reverse {
    -webkit-flex-direction: row-reverse;
    -moz-flex-direction: row-reverse;
    -ms-flex-direction: row-reverse;
    flex-direction: row-reverse;
  }
  .direction-column {
    -webkit-flex-direction: column;
    -moz-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .direction-column-reverse {
    -webkit-flex-direction: column-reverse;
    -moz-flex-direction: column-reverse;
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse;
  }
  .wrap-wrap {
    -webkit-flex-wrap: wrap;
    -moz-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
  .wrap-nowrap {
    -webkit-flex-wrap: nowrap;
    -moz-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
  }
  .wrap-reverse {
    -webkit-flex-wrap: wrap-reverse;
    -moz-flex-wrap: wrap-reverse;
    -ms-flex-wrap: wrap-reverse;
    flex-wrap: wrap-reverse;
  }
  .justify-flex-start {
    -webkit-justify-content: flex-start;
    -moz-justify-content: flex-start;
    -ms-justify-content: flex-start;
    justify-content: flex-start;
  }
  .justify-flex-end {
    -webkit-justify-content: flex-end;
    -moz-justify-content: flex-end;
    -ms-justify-content: flex-end;
    justify-content: flex-end;
  }
  .justify-center {
    -webkit-justify-content: center;
    -moz-justify-content: center;
    -ms-justify-content: center;
    justify-content: center;
  }
  .justify-space-between {
    -webkit-justify-content: space-between;
    -moz-justify-content: space-between;
    -ms-justify-content: space-between;
    justify-content: space-between;
  }
  .justify-space-around {
    -webkit-justify-content: space-around;
    -moz-justify-content: space-around;
    -ms-justify-content: space-around;
    justify-content: space-around;
  }
  .justify-self-flex-start {
    -webkit-justify-self: flex-start;
    -moz-justify-self: flex-start;
    -ms-justify-self: flex-start;
    justify-self: flex-start;
  }
  .justify-self-flex-end {
    -webkit-justify-self: flex-end;
    -moz-justify-self: flex-end;
    -ms-justify-self: flex-end;
    justify-self: flex-end;
  }
  .justify-self-center {
    -webkit-justify-self: center;
    -moz-justify-self: center;
    -ms-justify-self: center;
    justify-self: center;
  }
  .justify-self-space-between {
    -webkit-justify-self: space-between;
    -moz-justify-self: space-between;
    -ms-justify-self: space-between;
    justify-self: space-between;
  }
  .justify-self-space-around {
    -webkit-justify-self: space-around;
    -moz-justify-self: space-around;
    -ms-justify-self: space-around;
    justify-self: space-around;
  }
  .align-content-flex-start {
    -webkit-align-content: flex-start;
    -moz-align-content: flex-start;
    -ms-align-content: flex-start;
    align-content: flex-start;
  }
  .align-content-flex-end {
    -webkit-align-content: flex-end;
    -moz-align-content: flex-end;
    -ms-align-content: flex-end;
    align-content: flex-end;
  }
  .align-content-center {
    -webkit-align-content: center;
    -moz-align-content: center;
    -ms-align-content: center;
    align-content: center;
  }
  .align-content-space-between {
    -webkit-align-content: space-between;
    -moz-align-content: space-between;
    -ms-align-content: space-between;
    align-content: space-between;
  }
  .align-content-space-around {
    -webkit-align-content: space-around;
    -moz-align-content: space-around;
    -ms-align-content: space-around;
    align-content: space-around;
  }
  .align-content-stretch {
    -webkit-align-content: stretch;
    -moz-align-content: stretch;
    -ms-align-content: stretch;
    align-content: stretch;
  }
  .align-items-flex-start {
    -webkit-align-items: flex-start;
    -moz-align-items: flex-start;
    -ms-align-items: flex-start;
    align-items: flex-start;
  }
  .align-items-flex-end {
    -webkit-align-items: flex-end;
    -moz-align-items: flex-end;
    -ms-align-items: flex-end;
    align-items: flex-end;
  }
  .align-items-center {
    -webkit-align-items: center;
    -moz-align-items: center;
    -ms-align-items: center;
    align-items: center;
  }
  .align-items-baseline {
    -webkit-align-items: baseline;
    -moz-align-items: baseline;
    -ms-align-items: baseline;
    align-items: baseline;
  }
  .align-items-stretch {
    -webkit-align-items: stretch;
    -moz-align-items: stretch;
    -ms-align-items: stretch;
    align-items: stretch;
  }
  .order-0 {
    -webkit-order: 0;
    -moz-order: 0;
    -ms-order: 0;
    order: 0;
  }
  .order-1 {
    -webkit-order: 1;
    -moz-order: 1;
    -ms-order: 1;
    order: 1;
  }
  .order-2 {
    -webkit-order: 2;
    -moz-order: 2;
    -ms-order: 2;
    order: 2;
  }
  .order-3 {
    -webkit-order: 3;
    -moz-order: 3;
    -ms-order: 3;
    order: 3;
  }
  .order-4 {
    -webkit-order: 4;
    -moz-order: 4;
    -ms-order: 4;
    order: 4;
  }
  .order-5 {
    -webkit-order: 5;
    -moz-order: 5;
    -ms-order: 5;
    order: 5;
  }
  .order-6 {
    -webkit-order: 6;
    -moz-order: 6;
    -ms-order: 6;
    order: 6;
  }
  .order-7 {
    -webkit-order: 7;
    -moz-order: 7;
    -ms-order: 7;
    order: 7;
  }
  .order-8 {
    -webkit-order: 8;
    -moz-order: 8;
    -ms-order: 8;
    order: 8;
  }
  .order-9 {
    -webkit-order: 9;
    -moz-order: 9;
    -ms-order: 9;
    order: 9;
  }
  .order-10 {
    -webkit-order: 10;
    -moz-order: 10;
    -ms-order: 10;
    order: 10;
  }
  .grow-0 {
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-grow: 0;
    flex-grow: 0;
  }
  .grow-1 {
    -webkit-flex-grow: 1;
    -moz-flex-grow: 1;
    -ms-flex-grow: 1;
    flex-grow: 1;
  }
  .grow-2 {
    -webkit-flex-grow: 2;
    -moz-flex-grow: 2;
    -ms-flex-grow: 2;
    flex-grow: 2;
  }
  .grow-3 {
    -webkit-flex-grow: 3;
    -moz-flex-grow: 3;
    -ms-flex-grow: 3;
    flex-grow: 3;
  }
  .grow-4 {
    -webkit-flex-grow: 4;
    -moz-flex-grow: 4;
    -ms-flex-grow: 4;
    flex-grow: 4;
  }
  .grow-5 {
    -webkit-flex-grow: 5;
    -moz-flex-grow: 5;
    -ms-flex-grow: 5;
    flex-grow: 5;
  }
  .grow-6 {
    -webkit-flex-grow: 6;
    -moz-flex-grow: 6;
    -ms-flex-grow: 6;
    flex-grow: 6;
  }
  .grow-7 {
    -webkit-flex-grow: 7;
    -moz-flex-grow: 7;
    -ms-flex-grow: 7;
    flex-grow: 7;
  }
  .grow-8 {
    -webkit-flex-grow: 8;
    -moz-flex-grow: 8;
    -ms-flex-grow: 8;
    flex-grow: 8;
  }
  .grow-9 {
    -webkit-flex-grow: 9;
    -moz-flex-grow: 9;
    -ms-flex-grow: 9;
    flex-grow: 9;
  }
  .grow-10 {
    -webkit-flex-grow: 10;
    -moz-flex-grow: 10;
    -ms-flex-grow: 10;
    flex-grow: 10;
  }
  .shrink-0 {
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-shrink: 0;
    flex-shrink: 0;
  }
  .shrink-1 {
    -webkit-flex-shrink: 1;
    -moz-flex-shrink: 1;
    -ms-flex-shrink: 1;
    flex-shrink: 1;
  }
  .shrink-2 {
    -webkit-flex-shrink: 2;
    -moz-flex-shrink: 2;
    -ms-flex-shrink: 2;
    flex-shrink: 2;
  }
  .shrink-3 {
    -webkit-flex-shrink: 3;
    -moz-flex-shrink: 3;
    -ms-flex-shrink: 3;
    flex-shrink: 3;
  }
  .shrink-4 {
    -webkit-flex-shrink: 4;
    -moz-flex-shrink: 4;
    -ms-flex-shrink: 4;
    flex-shrink: 4;
  }
  .shrink-5 {
    -webkit-flex-shrink: 5;
    -moz-flex-shrink: 5;
    -ms-flex-shrink: 5;
    flex-shrink: 5;
  }
  .shrink-6 {
    -webkit-flex-shrink: 6;
    -moz-flex-shrink: 6;
    -ms-flex-shrink: 6;
    flex-shrink: 6;
  }
  .shrink-7 {
    -webkit-flex-shrink: 7;
    -moz-flex-shrink: 7;
    -ms-flex-shrink: 7;
    flex-shrink: 7;
  }
  .shrink-8 {
    -webkit-flex-shrink: 8;
    -moz-flex-shrink: 8;
    -ms-flex-shrink: 8;
    flex-shrink: 8;
  }
  .shrink-9 {
    -webkit-flex-shrink: 9;
    -moz-flex-shrink: 9;
    -ms-flex-shrink: 9;
    flex-shrink: 9;
  }
  .shrink-10 {
    -webkit-flex-shrink: 10;
    -moz-flex-shrink: 10;
    -ms-flex-shrink: 10;
    flex-shrink: 10;
  }
  .align-self-flex-start {
    -webkit-align-self: flex-start;
    -moz-align-self: flex-start;
    -ms-align-self: flex-start;
    align-self: flex-start;
  }
  .align-self-flex-end {
    -webkit-align-self: flex-end;
    -moz-align-self: flex-end;
    -ms-align-self: flex-end;
    align-self: flex-end;
  }
  .align-self-center {
    -webkit-align-self: center;
    -moz-align-self: center;
    -ms-align-self: center;
    align-self: center;
  }
  .align-self-baseline {
    -webkit-align-self: baseline;
    -moz-align-self: baseline;
    -ms-align-self: baseline;
    align-self: baseline;
  }
  .align-self-stretch {
    -webkit-align-self: stretch;
    -moz-align-self: stretch;
    -ms-align-self: stretch;
    align-self: stretch;
  }
}
@media (max-width: 767px) {
  .mobile-icon-size-0 {
    font-size: 0px;
    line-height: 0px;
    height: 0px !important;
    width: 0px !important;
  }
  .mobile-icon-size-1 {
    font-size: 2px;
    line-height: 2px;
    height: 2px !important;
    width: 2px !important;
  }
  .mobile-icon-size-2 {
    font-size: 4px;
    line-height: 4px;
    height: 4px !important;
    width: 4px !important;
  }
  .mobile-icon-size-3 {
    font-size: 6px;
    line-height: 6px;
    height: 6px !important;
    width: 6px !important;
  }
  .mobile-icon-size-4 {
    font-size: 8px;
    line-height: 8px;
    height: 8px !important;
    width: 8px !important;
  }
  .mobile-icon-size-5 {
    font-size: 10px;
    line-height: 10px;
    height: 10px !important;
    width: 10px !important;
  }
  .mobile-icon-size-6 {
    font-size: 12px;
    line-height: 12px;
    height: 12px !important;
    width: 12px !important;
  }
  .mobile-icon-size-7 {
    font-size: 14px;
    line-height: 14px;
    height: 14px !important;
    width: 14px !important;
  }
  .mobile-icon-size-8 {
    font-size: 16px;
    line-height: 16px;
    height: 16px !important;
    width: 16px !important;
  }
  .mobile-icon-size-9 {
    font-size: 18px;
    line-height: 18px;
    height: 18px !important;
    width: 18px !important;
  }
  .mobile-icon-size-10 {
    font-size: 20px;
    line-height: 20px;
    height: 20px !important;
    width: 20px !important;
  }
  .mobile-icon-size-11 {
    font-size: 22px;
    line-height: 22px;
    height: 22px !important;
    width: 22px !important;
  }
  .mobile-icon-size-12 {
    font-size: 24px;
    line-height: 24px;
    height: 24px !important;
    width: 24px !important;
  }
  .mobile-icon-size-13 {
    font-size: 26px;
    line-height: 26px;
    height: 26px !important;
    width: 26px !important;
  }
  .mobile-icon-size-14 {
    font-size: 28px;
    line-height: 28px;
    height: 28px !important;
    width: 28px !important;
  }
  .mobile-icon-size-15 {
    font-size: 30px;
    line-height: 30px;
    height: 30px !important;
    width: 30px !important;
  }
  .mobile-icon-size-16 {
    font-size: 32px;
    line-height: 32px;
    height: 32px !important;
    width: 32px !important;
  }
  .mobile-icon-size-17 {
    font-size: 34px;
    line-height: 34px;
    height: 34px !important;
    width: 34px !important;
  }
  .mobile-icon-size-18 {
    font-size: 36px;
    line-height: 36px;
    height: 36px !important;
    width: 36px !important;
  }
  .mobile-icon-size-19 {
    font-size: 38px;
    line-height: 38px;
    height: 38px !important;
    width: 38px !important;
  }
  .mobile-icon-size-20 {
    font-size: 40px;
    line-height: 40px;
    height: 40px !important;
    width: 40px !important;
  }
  .mobile-icon-size-25 {
    font-size: 50px;
    line-height: 50px;
    height: 50px !important;
    width: 50px !important;
  }
  .mobile-icon-size-30 {
    font-size: 60px;
    line-height: 60px;
    height: 60px !important;
    width: 60px !important;
  }
  .mobile-icon-size-35 {
    font-size: 70px;
    line-height: 70px;
    height: 70px !important;
    width: 70px !important;
  }
  .mobile-icon-size-50 {
    font-size: 100px;
    line-height: 100px;
    height: 100px !important;
    width: 100px !important;
  }
  .mobile-icon-size-65 {
    font-size: 130px;
    line-height: 130px;
    height: 130px !important;
    width: 130px !important;
  }
  .mobile-icon-size-85 {
    font-size: 170px;
    line-height: 170px;
    height: 170px !important;
    width: 170px !important;
  }
  .mobile-icon-size-100 {
    font-size: 200px;
    line-height: 200px;
    height: 200px !important;
    width: 200px !important;
  }
  .mobile-text-size-0 {
    font-size: 0px;
  }
  .mobile-text-size-1 {
    font-size: 2px;
  }
  .mobile-text-size-2 {
    font-size: 4px;
  }
  .mobile-text-size-3 {
    font-size: 6px;
  }
  .mobile-text-size-4 {
    font-size: 8px;
  }
  .mobile-text-size-5 {
    font-size: 10px;
  }
  .mobile-text-size-6 {
    font-size: 12px;
  }
  .mobile-text-size-7 {
    font-size: 14px;
  }
  .mobile-text-size-8 {
    font-size: 16px;
  }
  .mobile-text-size-9 {
    font-size: 18px;
  }
  .mobile-text-size-10 {
    font-size: 20px;
  }
  .mobile-text-size-11 {
    font-size: 22px;
  }
  .mobile-text-size-12 {
    font-size: 24px;
  }
  .mobile-text-size-13 {
    font-size: 26px;
  }
  .mobile-text-size-14 {
    font-size: 28px;
  }
  .mobile-text-size-15 {
    font-size: 30px;
  }
  .mobile-text-size-16 {
    font-size: 32px;
  }
  .mobile-text-size-17 {
    font-size: 34px;
  }
  .mobile-text-size-18 {
    font-size: 36px;
  }
  .mobile-text-size-19 {
    font-size: 38px;
  }
  .mobile-text-size-20 {
    font-size: 40px;
  }
  .mobile-text-size-25 {
    font-size: 50px;
  }
  .mobile-text-size-30 {
    font-size: 60px;
  }
  .mobile-text-size-35 {
    font-size: 70px;
  }
  .mobile-text-size-50 {
    font-size: 100px;
  }
  .mobile-text-size-65 {
    font-size: 130px;
  }
  .mobile-text-size-85 {
    font-size: 170px;
  }
  .mobile-text-size-100 {
    font-size: 200px;
  }
  .mobile-w-0 {
    width: 0px;
  }
  .mobile-h-0 {
    height: 0px;
  }
  .mobile-h-0-i {
    height: 0px !important;
  }
  .mobile-min-h-0 {
    min-height: 0px;
  }
  .mobile-min-w-0 {
    min-width: 0px !important;
  }
  .mobile-w-1 {
    width: 4px;
  }
  .mobile-h-1 {
    height: 4px;
  }
  .mobile-h-1-i {
    height: 4px !important;
  }
  .mobile-min-h-1 {
    min-height: 4px;
  }
  .mobile-min-w-1 {
    min-width: 4px !important;
  }
  .mobile-w-2 {
    width: 8px;
  }
  .mobile-h-2 {
    height: 8px;
  }
  .mobile-h-2-i {
    height: 8px !important;
  }
  .mobile-min-h-2 {
    min-height: 8px;
  }
  .mobile-min-w-2 {
    min-width: 8px !important;
  }
  .mobile-w-3 {
    width: 12px;
  }
  .mobile-h-3 {
    height: 12px;
  }
  .mobile-h-3-i {
    height: 12px !important;
  }
  .mobile-min-h-3 {
    min-height: 12px;
  }
  .mobile-min-w-3 {
    min-width: 12px !important;
  }
  .mobile-w-4 {
    width: 16px;
  }
  .mobile-h-4 {
    height: 16px;
  }
  .mobile-h-4-i {
    height: 16px !important;
  }
  .mobile-min-h-4 {
    min-height: 16px;
  }
  .mobile-min-w-4 {
    min-width: 16px !important;
  }
  .mobile-w-5 {
    width: 20px;
  }
  .mobile-h-5 {
    height: 20px;
  }
  .mobile-h-5-i {
    height: 20px !important;
  }
  .mobile-min-h-5 {
    min-height: 20px;
  }
  .mobile-min-w-5 {
    min-width: 20px !important;
  }
  .mobile-w-6 {
    width: 24px;
  }
  .mobile-h-6 {
    height: 24px;
  }
  .mobile-h-6-i {
    height: 24px !important;
  }
  .mobile-min-h-6 {
    min-height: 24px;
  }
  .mobile-min-w-6 {
    min-width: 24px !important;
  }
  .mobile-w-7 {
    width: 28px;
  }
  .mobile-h-7 {
    height: 28px;
  }
  .mobile-h-7-i {
    height: 28px !important;
  }
  .mobile-min-h-7 {
    min-height: 28px;
  }
  .mobile-min-w-7 {
    min-width: 28px !important;
  }
  .mobile-w-8 {
    width: 32px;
  }
  .mobile-h-8 {
    height: 32px;
  }
  .mobile-h-8-i {
    height: 32px !important;
  }
  .mobile-min-h-8 {
    min-height: 32px;
  }
  .mobile-min-w-8 {
    min-width: 32px !important;
  }
  .mobile-w-9 {
    width: 36px;
  }
  .mobile-h-9 {
    height: 36px;
  }
  .mobile-h-9-i {
    height: 36px !important;
  }
  .mobile-min-h-9 {
    min-height: 36px;
  }
  .mobile-min-w-9 {
    min-width: 36px !important;
  }
  .mobile-w-10 {
    width: 40px;
  }
  .mobile-h-10 {
    height: 40px;
  }
  .mobile-h-10-i {
    height: 40px !important;
  }
  .mobile-min-h-10 {
    min-height: 40px;
  }
  .mobile-min-w-10 {
    min-width: 40px !important;
  }
  .mobile-w-11 {
    width: 44px;
  }
  .mobile-h-11 {
    height: 44px;
  }
  .mobile-h-11-i {
    height: 44px !important;
  }
  .mobile-min-h-11 {
    min-height: 44px;
  }
  .mobile-min-w-11 {
    min-width: 44px !important;
  }
  .mobile-w-12 {
    width: 48px;
  }
  .mobile-h-12 {
    height: 48px;
  }
  .mobile-h-12-i {
    height: 48px !important;
  }
  .mobile-min-h-12 {
    min-height: 48px;
  }
  .mobile-min-w-12 {
    min-width: 48px !important;
  }
  .mobile-w-13 {
    width: 52px;
  }
  .mobile-h-13 {
    height: 52px;
  }
  .mobile-h-13-i {
    height: 52px !important;
  }
  .mobile-min-h-13 {
    min-height: 52px;
  }
  .mobile-min-w-13 {
    min-width: 52px !important;
  }
  .mobile-w-14 {
    width: 56px;
  }
  .mobile-h-14 {
    height: 56px;
  }
  .mobile-h-14-i {
    height: 56px !important;
  }
  .mobile-min-h-14 {
    min-height: 56px;
  }
  .mobile-min-w-14 {
    min-width: 56px !important;
  }
  .mobile-w-15 {
    width: 60px;
  }
  .mobile-h-15 {
    height: 60px;
  }
  .mobile-h-15-i {
    height: 60px !important;
  }
  .mobile-min-h-15 {
    min-height: 60px;
  }
  .mobile-min-w-15 {
    min-width: 60px !important;
  }
  .mobile-w-16 {
    width: 64px;
  }
  .mobile-h-16 {
    height: 64px;
  }
  .mobile-h-16-i {
    height: 64px !important;
  }
  .mobile-min-h-16 {
    min-height: 64px;
  }
  .mobile-min-w-16 {
    min-width: 64px !important;
  }
  .mobile-w-17 {
    width: 68px;
  }
  .mobile-h-17 {
    height: 68px;
  }
  .mobile-h-17-i {
    height: 68px !important;
  }
  .mobile-min-h-17 {
    min-height: 68px;
  }
  .mobile-min-w-17 {
    min-width: 68px !important;
  }
  .mobile-w-18 {
    width: 72px;
  }
  .mobile-h-18 {
    height: 72px;
  }
  .mobile-h-18-i {
    height: 72px !important;
  }
  .mobile-min-h-18 {
    min-height: 72px;
  }
  .mobile-min-w-18 {
    min-width: 72px !important;
  }
  .mobile-w-19 {
    width: 76px;
  }
  .mobile-h-19 {
    height: 76px;
  }
  .mobile-h-19-i {
    height: 76px !important;
  }
  .mobile-min-h-19 {
    min-height: 76px;
  }
  .mobile-min-w-19 {
    min-width: 76px !important;
  }
  .mobile-w-20 {
    width: 80px;
  }
  .mobile-h-20 {
    height: 80px;
  }
  .mobile-h-20-i {
    height: 80px !important;
  }
  .mobile-min-h-20 {
    min-height: 80px;
  }
  .mobile-min-w-20 {
    min-width: 80px !important;
  }
  .mobile-w-25 {
    width: 100px;
  }
  .mobile-h-25 {
    height: 100px;
  }
  .mobile-h-25-i {
    height: 100px !important;
  }
  .mobile-min-h-25 {
    min-height: 100px;
  }
  .mobile-min-w-25 {
    min-width: 100px !important;
  }
  .mobile-w-30 {
    width: 120px;
  }
  .mobile-h-30 {
    height: 120px;
  }
  .mobile-h-30-i {
    height: 120px !important;
  }
  .mobile-min-h-30 {
    min-height: 120px;
  }
  .mobile-min-w-30 {
    min-width: 120px !important;
  }
  .mobile-w-35 {
    width: 140px;
  }
  .mobile-h-35 {
    height: 140px;
  }
  .mobile-h-35-i {
    height: 140px !important;
  }
  .mobile-min-h-35 {
    min-height: 140px;
  }
  .mobile-min-w-35 {
    min-width: 140px !important;
  }
  .mobile-w-50 {
    width: 200px;
  }
  .mobile-h-50 {
    height: 200px;
  }
  .mobile-h-50-i {
    height: 200px !important;
  }
  .mobile-min-h-50 {
    min-height: 200px;
  }
  .mobile-min-w-50 {
    min-width: 200px !important;
  }
  .mobile-w-65 {
    width: 260px;
  }
  .mobile-h-65 {
    height: 260px;
  }
  .mobile-h-65-i {
    height: 260px !important;
  }
  .mobile-min-h-65 {
    min-height: 260px;
  }
  .mobile-min-w-65 {
    min-width: 260px !important;
  }
  .mobile-w-85 {
    width: 340px;
  }
  .mobile-h-85 {
    height: 340px;
  }
  .mobile-h-85-i {
    height: 340px !important;
  }
  .mobile-min-h-85 {
    min-height: 340px;
  }
  .mobile-min-w-85 {
    min-width: 340px !important;
  }
  .mobile-w-100 {
    width: 400px;
  }
  .mobile-h-100 {
    height: 400px;
  }
  .mobile-h-100-i {
    height: 400px !important;
  }
  .mobile-min-h-100 {
    min-height: 400px;
  }
  .mobile-min-w-100 {
    min-width: 400px !important;
  }
  .mobile-m-0 {
    margin: 0px;
  }
  .mobile-mx-0 {
    margin-left: 0px;
    margin-right: 0px;
  }
  .mobile-my-0 {
    margin-top: 0px;
    margin-bottom: 0px;
  }
  .mobile-ml-0 {
    margin-left: 0px;
  }
  .mobile-ml-0-i {
    margin-left: 0px !important;
  }
  .mobile-mr-0 {
    margin-right: 0px;
  }
  .mobile-mr-0-i {
    margin-right: 0px !important;
  }
  .mobile-mb-0 {
    margin-bottom: 0px;
  }
  .mobile-mt-0 {
    margin-top: 0px;
  }
  .mobile-p-0 {
    padding: 0px;
  }
  .mobile-p-0-i {
    padding: 0px !important;
  }
  .mobile-px-0 {
    padding-left: 0px;
    padding-right: 0px;
  }
  .mobile-px-0-i {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
  .mobile-py-0 {
    padding-top: 0px;
    padding-bottom: 0px;
  }
  .mobile-pl-0 {
    padding-left: 0px;
  }
  .mobile-pr-0 {
    padding-right: 0px;
  }
  .mobile-pl-0-i {
    padding-left: 0px !important;
  }
  .mobile-pr-0-i {
    padding-right: 0px !important;
  }
  .mobile-pb-0 {
    padding-bottom: 0px;
  }
  .mobile-pb-0-i {
    padding-bottom: 0px !important;
  }
  .mobile-pt-0 {
    padding-top: 0px;
  }
  .mobile-m-1 {
    margin: 4px;
  }
  .mobile-mx-1 {
    margin-left: 4px;
    margin-right: 4px;
  }
  .mobile-my-1 {
    margin-top: 4px;
    margin-bottom: 4px;
  }
  .mobile-ml-1 {
    margin-left: 4px;
  }
  .mobile-ml-1-i {
    margin-left: 4px !important;
  }
  .mobile-mr-1 {
    margin-right: 4px;
  }
  .mobile-mr-1-i {
    margin-right: 4px !important;
  }
  .mobile-mb-1 {
    margin-bottom: 4px;
  }
  .mobile-mt-1 {
    margin-top: 4px;
  }
  .mobile-p-1 {
    padding: 4px;
  }
  .mobile-p-1-i {
    padding: 4px !important;
  }
  .mobile-px-1 {
    padding-left: 4px;
    padding-right: 4px;
  }
  .mobile-px-1-i {
    padding-left: 4px !important;
    padding-right: 4px !important;
  }
  .mobile-py-1 {
    padding-top: 4px;
    padding-bottom: 4px;
  }
  .mobile-pl-1 {
    padding-left: 4px;
  }
  .mobile-pr-1 {
    padding-right: 4px;
  }
  .mobile-pl-1-i {
    padding-left: 4px !important;
  }
  .mobile-pr-1-i {
    padding-right: 4px !important;
  }
  .mobile-pb-1 {
    padding-bottom: 4px;
  }
  .mobile-pb-1-i {
    padding-bottom: 4px !important;
  }
  .mobile-pt-1 {
    padding-top: 4px;
  }
  .mobile-m-2 {
    margin: 8px;
  }
  .mobile-mx-2 {
    margin-left: 8px;
    margin-right: 8px;
  }
  .mobile-my-2 {
    margin-top: 8px;
    margin-bottom: 8px;
  }
  .mobile-ml-2 {
    margin-left: 8px;
  }
  .mobile-ml-2-i {
    margin-left: 8px !important;
  }
  .mobile-mr-2 {
    margin-right: 8px;
  }
  .mobile-mr-2-i {
    margin-right: 8px !important;
  }
  .mobile-mb-2 {
    margin-bottom: 8px;
  }
  .mobile-mt-2 {
    margin-top: 8px;
  }
  .mobile-p-2 {
    padding: 8px;
  }
  .mobile-p-2-i {
    padding: 8px !important;
  }
  .mobile-px-2 {
    padding-left: 8px;
    padding-right: 8px;
  }
  .mobile-px-2-i {
    padding-left: 8px !important;
    padding-right: 8px !important;
  }
  .mobile-py-2 {
    padding-top: 8px;
    padding-bottom: 8px;
  }
  .mobile-pl-2 {
    padding-left: 8px;
  }
  .mobile-pr-2 {
    padding-right: 8px;
  }
  .mobile-pl-2-i {
    padding-left: 8px !important;
  }
  .mobile-pr-2-i {
    padding-right: 8px !important;
  }
  .mobile-pb-2 {
    padding-bottom: 8px;
  }
  .mobile-pb-2-i {
    padding-bottom: 8px !important;
  }
  .mobile-pt-2 {
    padding-top: 8px;
  }
  .mobile-m-3 {
    margin: 12px;
  }
  .mobile-mx-3 {
    margin-left: 12px;
    margin-right: 12px;
  }
  .mobile-my-3 {
    margin-top: 12px;
    margin-bottom: 12px;
  }
  .mobile-ml-3 {
    margin-left: 12px;
  }
  .mobile-ml-3-i {
    margin-left: 12px !important;
  }
  .mobile-mr-3 {
    margin-right: 12px;
  }
  .mobile-mr-3-i {
    margin-right: 12px !important;
  }
  .mobile-mb-3 {
    margin-bottom: 12px;
  }
  .mobile-mt-3 {
    margin-top: 12px;
  }
  .mobile-p-3 {
    padding: 12px;
  }
  .mobile-p-3-i {
    padding: 12px !important;
  }
  .mobile-px-3 {
    padding-left: 12px;
    padding-right: 12px;
  }
  .mobile-px-3-i {
    padding-left: 12px !important;
    padding-right: 12px !important;
  }
  .mobile-py-3 {
    padding-top: 12px;
    padding-bottom: 12px;
  }
  .mobile-pl-3 {
    padding-left: 12px;
  }
  .mobile-pr-3 {
    padding-right: 12px;
  }
  .mobile-pl-3-i {
    padding-left: 12px !important;
  }
  .mobile-pr-3-i {
    padding-right: 12px !important;
  }
  .mobile-pb-3 {
    padding-bottom: 12px;
  }
  .mobile-pb-3-i {
    padding-bottom: 12px !important;
  }
  .mobile-pt-3 {
    padding-top: 12px;
  }
  .mobile-m-4 {
    margin: 16px;
  }
  .mobile-mx-4 {
    margin-left: 16px;
    margin-right: 16px;
  }
  .mobile-my-4 {
    margin-top: 16px;
    margin-bottom: 16px;
  }
  .mobile-ml-4 {
    margin-left: 16px;
  }
  .mobile-ml-4-i {
    margin-left: 16px !important;
  }
  .mobile-mr-4 {
    margin-right: 16px;
  }
  .mobile-mr-4-i {
    margin-right: 16px !important;
  }
  .mobile-mb-4 {
    margin-bottom: 16px;
  }
  .mobile-mt-4 {
    margin-top: 16px;
  }
  .mobile-p-4 {
    padding: 16px;
  }
  .mobile-p-4-i {
    padding: 16px !important;
  }
  .mobile-px-4 {
    padding-left: 16px;
    padding-right: 16px;
  }
  .mobile-px-4-i {
    padding-left: 16px !important;
    padding-right: 16px !important;
  }
  .mobile-py-4 {
    padding-top: 16px;
    padding-bottom: 16px;
  }
  .mobile-pl-4 {
    padding-left: 16px;
  }
  .mobile-pr-4 {
    padding-right: 16px;
  }
  .mobile-pl-4-i {
    padding-left: 16px !important;
  }
  .mobile-pr-4-i {
    padding-right: 16px !important;
  }
  .mobile-pb-4 {
    padding-bottom: 16px;
  }
  .mobile-pb-4-i {
    padding-bottom: 16px !important;
  }
  .mobile-pt-4 {
    padding-top: 16px;
  }
  .mobile-m-5 {
    margin: 20px;
  }
  .mobile-mx-5 {
    margin-left: 20px;
    margin-right: 20px;
  }
  .mobile-my-5 {
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .mobile-ml-5 {
    margin-left: 20px;
  }
  .mobile-ml-5-i {
    margin-left: 20px !important;
  }
  .mobile-mr-5 {
    margin-right: 20px;
  }
  .mobile-mr-5-i {
    margin-right: 20px !important;
  }
  .mobile-mb-5 {
    margin-bottom: 20px;
  }
  .mobile-mt-5 {
    margin-top: 20px;
  }
  .mobile-p-5 {
    padding: 20px;
  }
  .mobile-p-5-i {
    padding: 20px !important;
  }
  .mobile-px-5 {
    padding-left: 20px;
    padding-right: 20px;
  }
  .mobile-px-5-i {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }
  .mobile-py-5 {
    padding-top: 20px;
    padding-bottom: 20px;
  }
  .mobile-pl-5 {
    padding-left: 20px;
  }
  .mobile-pr-5 {
    padding-right: 20px;
  }
  .mobile-pl-5-i {
    padding-left: 20px !important;
  }
  .mobile-pr-5-i {
    padding-right: 20px !important;
  }
  .mobile-pb-5 {
    padding-bottom: 20px;
  }
  .mobile-pb-5-i {
    padding-bottom: 20px !important;
  }
  .mobile-pt-5 {
    padding-top: 20px;
  }
  .mobile-m-6 {
    margin: 24px;
  }
  .mobile-mx-6 {
    margin-left: 24px;
    margin-right: 24px;
  }
  .mobile-my-6 {
    margin-top: 24px;
    margin-bottom: 24px;
  }
  .mobile-ml-6 {
    margin-left: 24px;
  }
  .mobile-ml-6-i {
    margin-left: 24px !important;
  }
  .mobile-mr-6 {
    margin-right: 24px;
  }
  .mobile-mr-6-i {
    margin-right: 24px !important;
  }
  .mobile-mb-6 {
    margin-bottom: 24px;
  }
  .mobile-mt-6 {
    margin-top: 24px;
  }
  .mobile-p-6 {
    padding: 24px;
  }
  .mobile-p-6-i {
    padding: 24px !important;
  }
  .mobile-px-6 {
    padding-left: 24px;
    padding-right: 24px;
  }
  .mobile-px-6-i {
    padding-left: 24px !important;
    padding-right: 24px !important;
  }
  .mobile-py-6 {
    padding-top: 24px;
    padding-bottom: 24px;
  }
  .mobile-pl-6 {
    padding-left: 24px;
  }
  .mobile-pr-6 {
    padding-right: 24px;
  }
  .mobile-pl-6-i {
    padding-left: 24px !important;
  }
  .mobile-pr-6-i {
    padding-right: 24px !important;
  }
  .mobile-pb-6 {
    padding-bottom: 24px;
  }
  .mobile-pb-6-i {
    padding-bottom: 24px !important;
  }
  .mobile-pt-6 {
    padding-top: 24px;
  }
  .mobile-m-7 {
    margin: 28px;
  }
  .mobile-mx-7 {
    margin-left: 28px;
    margin-right: 28px;
  }
  .mobile-my-7 {
    margin-top: 28px;
    margin-bottom: 28px;
  }
  .mobile-ml-7 {
    margin-left: 28px;
  }
  .mobile-ml-7-i {
    margin-left: 28px !important;
  }
  .mobile-mr-7 {
    margin-right: 28px;
  }
  .mobile-mr-7-i {
    margin-right: 28px !important;
  }
  .mobile-mb-7 {
    margin-bottom: 28px;
  }
  .mobile-mt-7 {
    margin-top: 28px;
  }
  .mobile-p-7 {
    padding: 28px;
  }
  .mobile-p-7-i {
    padding: 28px !important;
  }
  .mobile-px-7 {
    padding-left: 28px;
    padding-right: 28px;
  }
  .mobile-px-7-i {
    padding-left: 28px !important;
    padding-right: 28px !important;
  }
  .mobile-py-7 {
    padding-top: 28px;
    padding-bottom: 28px;
  }
  .mobile-pl-7 {
    padding-left: 28px;
  }
  .mobile-pr-7 {
    padding-right: 28px;
  }
  .mobile-pl-7-i {
    padding-left: 28px !important;
  }
  .mobile-pr-7-i {
    padding-right: 28px !important;
  }
  .mobile-pb-7 {
    padding-bottom: 28px;
  }
  .mobile-pb-7-i {
    padding-bottom: 28px !important;
  }
  .mobile-pt-7 {
    padding-top: 28px;
  }
  .mobile-m-8 {
    margin: 32px;
  }
  .mobile-mx-8 {
    margin-left: 32px;
    margin-right: 32px;
  }
  .mobile-my-8 {
    margin-top: 32px;
    margin-bottom: 32px;
  }
  .mobile-ml-8 {
    margin-left: 32px;
  }
  .mobile-ml-8-i {
    margin-left: 32px !important;
  }
  .mobile-mr-8 {
    margin-right: 32px;
  }
  .mobile-mr-8-i {
    margin-right: 32px !important;
  }
  .mobile-mb-8 {
    margin-bottom: 32px;
  }
  .mobile-mt-8 {
    margin-top: 32px;
  }
  .mobile-p-8 {
    padding: 32px;
  }
  .mobile-p-8-i {
    padding: 32px !important;
  }
  .mobile-px-8 {
    padding-left: 32px;
    padding-right: 32px;
  }
  .mobile-px-8-i {
    padding-left: 32px !important;
    padding-right: 32px !important;
  }
  .mobile-py-8 {
    padding-top: 32px;
    padding-bottom: 32px;
  }
  .mobile-pl-8 {
    padding-left: 32px;
  }
  .mobile-pr-8 {
    padding-right: 32px;
  }
  .mobile-pl-8-i {
    padding-left: 32px !important;
  }
  .mobile-pr-8-i {
    padding-right: 32px !important;
  }
  .mobile-pb-8 {
    padding-bottom: 32px;
  }
  .mobile-pb-8-i {
    padding-bottom: 32px !important;
  }
  .mobile-pt-8 {
    padding-top: 32px;
  }
  .mobile-m-9 {
    margin: 36px;
  }
  .mobile-mx-9 {
    margin-left: 36px;
    margin-right: 36px;
  }
  .mobile-my-9 {
    margin-top: 36px;
    margin-bottom: 36px;
  }
  .mobile-ml-9 {
    margin-left: 36px;
  }
  .mobile-ml-9-i {
    margin-left: 36px !important;
  }
  .mobile-mr-9 {
    margin-right: 36px;
  }
  .mobile-mr-9-i {
    margin-right: 36px !important;
  }
  .mobile-mb-9 {
    margin-bottom: 36px;
  }
  .mobile-mt-9 {
    margin-top: 36px;
  }
  .mobile-p-9 {
    padding: 36px;
  }
  .mobile-p-9-i {
    padding: 36px !important;
  }
  .mobile-px-9 {
    padding-left: 36px;
    padding-right: 36px;
  }
  .mobile-px-9-i {
    padding-left: 36px !important;
    padding-right: 36px !important;
  }
  .mobile-py-9 {
    padding-top: 36px;
    padding-bottom: 36px;
  }
  .mobile-pl-9 {
    padding-left: 36px;
  }
  .mobile-pr-9 {
    padding-right: 36px;
  }
  .mobile-pl-9-i {
    padding-left: 36px !important;
  }
  .mobile-pr-9-i {
    padding-right: 36px !important;
  }
  .mobile-pb-9 {
    padding-bottom: 36px;
  }
  .mobile-pb-9-i {
    padding-bottom: 36px !important;
  }
  .mobile-pt-9 {
    padding-top: 36px;
  }
  .mobile-m-10 {
    margin: 40px;
  }
  .mobile-mx-10 {
    margin-left: 40px;
    margin-right: 40px;
  }
  .mobile-my-10 {
    margin-top: 40px;
    margin-bottom: 40px;
  }
  .mobile-ml-10 {
    margin-left: 40px;
  }
  .mobile-ml-10-i {
    margin-left: 40px !important;
  }
  .mobile-mr-10 {
    margin-right: 40px;
  }
  .mobile-mr-10-i {
    margin-right: 40px !important;
  }
  .mobile-mb-10 {
    margin-bottom: 40px;
  }
  .mobile-mt-10 {
    margin-top: 40px;
  }
  .mobile-p-10 {
    padding: 40px;
  }
  .mobile-p-10-i {
    padding: 40px !important;
  }
  .mobile-px-10 {
    padding-left: 40px;
    padding-right: 40px;
  }
  .mobile-px-10-i {
    padding-left: 40px !important;
    padding-right: 40px !important;
  }
  .mobile-py-10 {
    padding-top: 40px;
    padding-bottom: 40px;
  }
  .mobile-pl-10 {
    padding-left: 40px;
  }
  .mobile-pr-10 {
    padding-right: 40px;
  }
  .mobile-pl-10-i {
    padding-left: 40px !important;
  }
  .mobile-pr-10-i {
    padding-right: 40px !important;
  }
  .mobile-pb-10 {
    padding-bottom: 40px;
  }
  .mobile-pb-10-i {
    padding-bottom: 40px !important;
  }
  .mobile-pt-10 {
    padding-top: 40px;
  }
  .mobile-m-11 {
    margin: 44px;
  }
  .mobile-mx-11 {
    margin-left: 44px;
    margin-right: 44px;
  }
  .mobile-my-11 {
    margin-top: 44px;
    margin-bottom: 44px;
  }
  .mobile-ml-11 {
    margin-left: 44px;
  }
  .mobile-ml-11-i {
    margin-left: 44px !important;
  }
  .mobile-mr-11 {
    margin-right: 44px;
  }
  .mobile-mr-11-i {
    margin-right: 44px !important;
  }
  .mobile-mb-11 {
    margin-bottom: 44px;
  }
  .mobile-mt-11 {
    margin-top: 44px;
  }
  .mobile-p-11 {
    padding: 44px;
  }
  .mobile-p-11-i {
    padding: 44px !important;
  }
  .mobile-px-11 {
    padding-left: 44px;
    padding-right: 44px;
  }
  .mobile-px-11-i {
    padding-left: 44px !important;
    padding-right: 44px !important;
  }
  .mobile-py-11 {
    padding-top: 44px;
    padding-bottom: 44px;
  }
  .mobile-pl-11 {
    padding-left: 44px;
  }
  .mobile-pr-11 {
    padding-right: 44px;
  }
  .mobile-pl-11-i {
    padding-left: 44px !important;
  }
  .mobile-pr-11-i {
    padding-right: 44px !important;
  }
  .mobile-pb-11 {
    padding-bottom: 44px;
  }
  .mobile-pb-11-i {
    padding-bottom: 44px !important;
  }
  .mobile-pt-11 {
    padding-top: 44px;
  }
  .mobile-m-12 {
    margin: 48px;
  }
  .mobile-mx-12 {
    margin-left: 48px;
    margin-right: 48px;
  }
  .mobile-my-12 {
    margin-top: 48px;
    margin-bottom: 48px;
  }
  .mobile-ml-12 {
    margin-left: 48px;
  }
  .mobile-ml-12-i {
    margin-left: 48px !important;
  }
  .mobile-mr-12 {
    margin-right: 48px;
  }
  .mobile-mr-12-i {
    margin-right: 48px !important;
  }
  .mobile-mb-12 {
    margin-bottom: 48px;
  }
  .mobile-mt-12 {
    margin-top: 48px;
  }
  .mobile-p-12 {
    padding: 48px;
  }
  .mobile-p-12-i {
    padding: 48px !important;
  }
  .mobile-px-12 {
    padding-left: 48px;
    padding-right: 48px;
  }
  .mobile-px-12-i {
    padding-left: 48px !important;
    padding-right: 48px !important;
  }
  .mobile-py-12 {
    padding-top: 48px;
    padding-bottom: 48px;
  }
  .mobile-pl-12 {
    padding-left: 48px;
  }
  .mobile-pr-12 {
    padding-right: 48px;
  }
  .mobile-pl-12-i {
    padding-left: 48px !important;
  }
  .mobile-pr-12-i {
    padding-right: 48px !important;
  }
  .mobile-pb-12 {
    padding-bottom: 48px;
  }
  .mobile-pb-12-i {
    padding-bottom: 48px !important;
  }
  .mobile-pt-12 {
    padding-top: 48px;
  }
  .mobile-m-13 {
    margin: 52px;
  }
  .mobile-mx-13 {
    margin-left: 52px;
    margin-right: 52px;
  }
  .mobile-my-13 {
    margin-top: 52px;
    margin-bottom: 52px;
  }
  .mobile-ml-13 {
    margin-left: 52px;
  }
  .mobile-ml-13-i {
    margin-left: 52px !important;
  }
  .mobile-mr-13 {
    margin-right: 52px;
  }
  .mobile-mr-13-i {
    margin-right: 52px !important;
  }
  .mobile-mb-13 {
    margin-bottom: 52px;
  }
  .mobile-mt-13 {
    margin-top: 52px;
  }
  .mobile-p-13 {
    padding: 52px;
  }
  .mobile-p-13-i {
    padding: 52px !important;
  }
  .mobile-px-13 {
    padding-left: 52px;
    padding-right: 52px;
  }
  .mobile-px-13-i {
    padding-left: 52px !important;
    padding-right: 52px !important;
  }
  .mobile-py-13 {
    padding-top: 52px;
    padding-bottom: 52px;
  }
  .mobile-pl-13 {
    padding-left: 52px;
  }
  .mobile-pr-13 {
    padding-right: 52px;
  }
  .mobile-pl-13-i {
    padding-left: 52px !important;
  }
  .mobile-pr-13-i {
    padding-right: 52px !important;
  }
  .mobile-pb-13 {
    padding-bottom: 52px;
  }
  .mobile-pb-13-i {
    padding-bottom: 52px !important;
  }
  .mobile-pt-13 {
    padding-top: 52px;
  }
  .mobile-m-14 {
    margin: 56px;
  }
  .mobile-mx-14 {
    margin-left: 56px;
    margin-right: 56px;
  }
  .mobile-my-14 {
    margin-top: 56px;
    margin-bottom: 56px;
  }
  .mobile-ml-14 {
    margin-left: 56px;
  }
  .mobile-ml-14-i {
    margin-left: 56px !important;
  }
  .mobile-mr-14 {
    margin-right: 56px;
  }
  .mobile-mr-14-i {
    margin-right: 56px !important;
  }
  .mobile-mb-14 {
    margin-bottom: 56px;
  }
  .mobile-mt-14 {
    margin-top: 56px;
  }
  .mobile-p-14 {
    padding: 56px;
  }
  .mobile-p-14-i {
    padding: 56px !important;
  }
  .mobile-px-14 {
    padding-left: 56px;
    padding-right: 56px;
  }
  .mobile-px-14-i {
    padding-left: 56px !important;
    padding-right: 56px !important;
  }
  .mobile-py-14 {
    padding-top: 56px;
    padding-bottom: 56px;
  }
  .mobile-pl-14 {
    padding-left: 56px;
  }
  .mobile-pr-14 {
    padding-right: 56px;
  }
  .mobile-pl-14-i {
    padding-left: 56px !important;
  }
  .mobile-pr-14-i {
    padding-right: 56px !important;
  }
  .mobile-pb-14 {
    padding-bottom: 56px;
  }
  .mobile-pb-14-i {
    padding-bottom: 56px !important;
  }
  .mobile-pt-14 {
    padding-top: 56px;
  }
  .mobile-m-15 {
    margin: 60px;
  }
  .mobile-mx-15 {
    margin-left: 60px;
    margin-right: 60px;
  }
  .mobile-my-15 {
    margin-top: 60px;
    margin-bottom: 60px;
  }
  .mobile-ml-15 {
    margin-left: 60px;
  }
  .mobile-ml-15-i {
    margin-left: 60px !important;
  }
  .mobile-mr-15 {
    margin-right: 60px;
  }
  .mobile-mr-15-i {
    margin-right: 60px !important;
  }
  .mobile-mb-15 {
    margin-bottom: 60px;
  }
  .mobile-mt-15 {
    margin-top: 60px;
  }
  .mobile-p-15 {
    padding: 60px;
  }
  .mobile-p-15-i {
    padding: 60px !important;
  }
  .mobile-px-15 {
    padding-left: 60px;
    padding-right: 60px;
  }
  .mobile-px-15-i {
    padding-left: 60px !important;
    padding-right: 60px !important;
  }
  .mobile-py-15 {
    padding-top: 60px;
    padding-bottom: 60px;
  }
  .mobile-pl-15 {
    padding-left: 60px;
  }
  .mobile-pr-15 {
    padding-right: 60px;
  }
  .mobile-pl-15-i {
    padding-left: 60px !important;
  }
  .mobile-pr-15-i {
    padding-right: 60px !important;
  }
  .mobile-pb-15 {
    padding-bottom: 60px;
  }
  .mobile-pb-15-i {
    padding-bottom: 60px !important;
  }
  .mobile-pt-15 {
    padding-top: 60px;
  }
  .mobile-m-16 {
    margin: 64px;
  }
  .mobile-mx-16 {
    margin-left: 64px;
    margin-right: 64px;
  }
  .mobile-my-16 {
    margin-top: 64px;
    margin-bottom: 64px;
  }
  .mobile-ml-16 {
    margin-left: 64px;
  }
  .mobile-ml-16-i {
    margin-left: 64px !important;
  }
  .mobile-mr-16 {
    margin-right: 64px;
  }
  .mobile-mr-16-i {
    margin-right: 64px !important;
  }
  .mobile-mb-16 {
    margin-bottom: 64px;
  }
  .mobile-mt-16 {
    margin-top: 64px;
  }
  .mobile-p-16 {
    padding: 64px;
  }
  .mobile-p-16-i {
    padding: 64px !important;
  }
  .mobile-px-16 {
    padding-left: 64px;
    padding-right: 64px;
  }
  .mobile-px-16-i {
    padding-left: 64px !important;
    padding-right: 64px !important;
  }
  .mobile-py-16 {
    padding-top: 64px;
    padding-bottom: 64px;
  }
  .mobile-pl-16 {
    padding-left: 64px;
  }
  .mobile-pr-16 {
    padding-right: 64px;
  }
  .mobile-pl-16-i {
    padding-left: 64px !important;
  }
  .mobile-pr-16-i {
    padding-right: 64px !important;
  }
  .mobile-pb-16 {
    padding-bottom: 64px;
  }
  .mobile-pb-16-i {
    padding-bottom: 64px !important;
  }
  .mobile-pt-16 {
    padding-top: 64px;
  }
  .mobile-m-17 {
    margin: 68px;
  }
  .mobile-mx-17 {
    margin-left: 68px;
    margin-right: 68px;
  }
  .mobile-my-17 {
    margin-top: 68px;
    margin-bottom: 68px;
  }
  .mobile-ml-17 {
    margin-left: 68px;
  }
  .mobile-ml-17-i {
    margin-left: 68px !important;
  }
  .mobile-mr-17 {
    margin-right: 68px;
  }
  .mobile-mr-17-i {
    margin-right: 68px !important;
  }
  .mobile-mb-17 {
    margin-bottom: 68px;
  }
  .mobile-mt-17 {
    margin-top: 68px;
  }
  .mobile-p-17 {
    padding: 68px;
  }
  .mobile-p-17-i {
    padding: 68px !important;
  }
  .mobile-px-17 {
    padding-left: 68px;
    padding-right: 68px;
  }
  .mobile-px-17-i {
    padding-left: 68px !important;
    padding-right: 68px !important;
  }
  .mobile-py-17 {
    padding-top: 68px;
    padding-bottom: 68px;
  }
  .mobile-pl-17 {
    padding-left: 68px;
  }
  .mobile-pr-17 {
    padding-right: 68px;
  }
  .mobile-pl-17-i {
    padding-left: 68px !important;
  }
  .mobile-pr-17-i {
    padding-right: 68px !important;
  }
  .mobile-pb-17 {
    padding-bottom: 68px;
  }
  .mobile-pb-17-i {
    padding-bottom: 68px !important;
  }
  .mobile-pt-17 {
    padding-top: 68px;
  }
  .mobile-m-18 {
    margin: 72px;
  }
  .mobile-mx-18 {
    margin-left: 72px;
    margin-right: 72px;
  }
  .mobile-my-18 {
    margin-top: 72px;
    margin-bottom: 72px;
  }
  .mobile-ml-18 {
    margin-left: 72px;
  }
  .mobile-ml-18-i {
    margin-left: 72px !important;
  }
  .mobile-mr-18 {
    margin-right: 72px;
  }
  .mobile-mr-18-i {
    margin-right: 72px !important;
  }
  .mobile-mb-18 {
    margin-bottom: 72px;
  }
  .mobile-mt-18 {
    margin-top: 72px;
  }
  .mobile-p-18 {
    padding: 72px;
  }
  .mobile-p-18-i {
    padding: 72px !important;
  }
  .mobile-px-18 {
    padding-left: 72px;
    padding-right: 72px;
  }
  .mobile-px-18-i {
    padding-left: 72px !important;
    padding-right: 72px !important;
  }
  .mobile-py-18 {
    padding-top: 72px;
    padding-bottom: 72px;
  }
  .mobile-pl-18 {
    padding-left: 72px;
  }
  .mobile-pr-18 {
    padding-right: 72px;
  }
  .mobile-pl-18-i {
    padding-left: 72px !important;
  }
  .mobile-pr-18-i {
    padding-right: 72px !important;
  }
  .mobile-pb-18 {
    padding-bottom: 72px;
  }
  .mobile-pb-18-i {
    padding-bottom: 72px !important;
  }
  .mobile-pt-18 {
    padding-top: 72px;
  }
  .mobile-m-19 {
    margin: 76px;
  }
  .mobile-mx-19 {
    margin-left: 76px;
    margin-right: 76px;
  }
  .mobile-my-19 {
    margin-top: 76px;
    margin-bottom: 76px;
  }
  .mobile-ml-19 {
    margin-left: 76px;
  }
  .mobile-ml-19-i {
    margin-left: 76px !important;
  }
  .mobile-mr-19 {
    margin-right: 76px;
  }
  .mobile-mr-19-i {
    margin-right: 76px !important;
  }
  .mobile-mb-19 {
    margin-bottom: 76px;
  }
  .mobile-mt-19 {
    margin-top: 76px;
  }
  .mobile-p-19 {
    padding: 76px;
  }
  .mobile-p-19-i {
    padding: 76px !important;
  }
  .mobile-px-19 {
    padding-left: 76px;
    padding-right: 76px;
  }
  .mobile-px-19-i {
    padding-left: 76px !important;
    padding-right: 76px !important;
  }
  .mobile-py-19 {
    padding-top: 76px;
    padding-bottom: 76px;
  }
  .mobile-pl-19 {
    padding-left: 76px;
  }
  .mobile-pr-19 {
    padding-right: 76px;
  }
  .mobile-pl-19-i {
    padding-left: 76px !important;
  }
  .mobile-pr-19-i {
    padding-right: 76px !important;
  }
  .mobile-pb-19 {
    padding-bottom: 76px;
  }
  .mobile-pb-19-i {
    padding-bottom: 76px !important;
  }
  .mobile-pt-19 {
    padding-top: 76px;
  }
  .mobile-m-20 {
    margin: 80px;
  }
  .mobile-mx-20 {
    margin-left: 80px;
    margin-right: 80px;
  }
  .mobile-my-20 {
    margin-top: 80px;
    margin-bottom: 80px;
  }
  .mobile-ml-20 {
    margin-left: 80px;
  }
  .mobile-ml-20-i {
    margin-left: 80px !important;
  }
  .mobile-mr-20 {
    margin-right: 80px;
  }
  .mobile-mr-20-i {
    margin-right: 80px !important;
  }
  .mobile-mb-20 {
    margin-bottom: 80px;
  }
  .mobile-mt-20 {
    margin-top: 80px;
  }
  .mobile-p-20 {
    padding: 80px;
  }
  .mobile-p-20-i {
    padding: 80px !important;
  }
  .mobile-px-20 {
    padding-left: 80px;
    padding-right: 80px;
  }
  .mobile-px-20-i {
    padding-left: 80px !important;
    padding-right: 80px !important;
  }
  .mobile-py-20 {
    padding-top: 80px;
    padding-bottom: 80px;
  }
  .mobile-pl-20 {
    padding-left: 80px;
  }
  .mobile-pr-20 {
    padding-right: 80px;
  }
  .mobile-pl-20-i {
    padding-left: 80px !important;
  }
  .mobile-pr-20-i {
    padding-right: 80px !important;
  }
  .mobile-pb-20 {
    padding-bottom: 80px;
  }
  .mobile-pb-20-i {
    padding-bottom: 80px !important;
  }
  .mobile-pt-20 {
    padding-top: 80px;
  }
  .mobile-m-25 {
    margin: 100px;
  }
  .mobile-mx-25 {
    margin-left: 100px;
    margin-right: 100px;
  }
  .mobile-my-25 {
    margin-top: 100px;
    margin-bottom: 100px;
  }
  .mobile-ml-25 {
    margin-left: 100px;
  }
  .mobile-ml-25-i {
    margin-left: 100px !important;
  }
  .mobile-mr-25 {
    margin-right: 100px;
  }
  .mobile-mr-25-i {
    margin-right: 100px !important;
  }
  .mobile-mb-25 {
    margin-bottom: 100px;
  }
  .mobile-mt-25 {
    margin-top: 100px;
  }
  .mobile-p-25 {
    padding: 100px;
  }
  .mobile-p-25-i {
    padding: 100px !important;
  }
  .mobile-px-25 {
    padding-left: 100px;
    padding-right: 100px;
  }
  .mobile-px-25-i {
    padding-left: 100px !important;
    padding-right: 100px !important;
  }
  .mobile-py-25 {
    padding-top: 100px;
    padding-bottom: 100px;
  }
  .mobile-pl-25 {
    padding-left: 100px;
  }
  .mobile-pr-25 {
    padding-right: 100px;
  }
  .mobile-pl-25-i {
    padding-left: 100px !important;
  }
  .mobile-pr-25-i {
    padding-right: 100px !important;
  }
  .mobile-pb-25 {
    padding-bottom: 100px;
  }
  .mobile-pb-25-i {
    padding-bottom: 100px !important;
  }
  .mobile-pt-25 {
    padding-top: 100px;
  }
  .mobile-m-30 {
    margin: 120px;
  }
  .mobile-mx-30 {
    margin-left: 120px;
    margin-right: 120px;
  }
  .mobile-my-30 {
    margin-top: 120px;
    margin-bottom: 120px;
  }
  .mobile-ml-30 {
    margin-left: 120px;
  }
  .mobile-ml-30-i {
    margin-left: 120px !important;
  }
  .mobile-mr-30 {
    margin-right: 120px;
  }
  .mobile-mr-30-i {
    margin-right: 120px !important;
  }
  .mobile-mb-30 {
    margin-bottom: 120px;
  }
  .mobile-mt-30 {
    margin-top: 120px;
  }
  .mobile-p-30 {
    padding: 120px;
  }
  .mobile-p-30-i {
    padding: 120px !important;
  }
  .mobile-px-30 {
    padding-left: 120px;
    padding-right: 120px;
  }
  .mobile-px-30-i {
    padding-left: 120px !important;
    padding-right: 120px !important;
  }
  .mobile-py-30 {
    padding-top: 120px;
    padding-bottom: 120px;
  }
  .mobile-pl-30 {
    padding-left: 120px;
  }
  .mobile-pr-30 {
    padding-right: 120px;
  }
  .mobile-pl-30-i {
    padding-left: 120px !important;
  }
  .mobile-pr-30-i {
    padding-right: 120px !important;
  }
  .mobile-pb-30 {
    padding-bottom: 120px;
  }
  .mobile-pb-30-i {
    padding-bottom: 120px !important;
  }
  .mobile-pt-30 {
    padding-top: 120px;
  }
  .mobile-m-35 {
    margin: 140px;
  }
  .mobile-mx-35 {
    margin-left: 140px;
    margin-right: 140px;
  }
  .mobile-my-35 {
    margin-top: 140px;
    margin-bottom: 140px;
  }
  .mobile-ml-35 {
    margin-left: 140px;
  }
  .mobile-ml-35-i {
    margin-left: 140px !important;
  }
  .mobile-mr-35 {
    margin-right: 140px;
  }
  .mobile-mr-35-i {
    margin-right: 140px !important;
  }
  .mobile-mb-35 {
    margin-bottom: 140px;
  }
  .mobile-mt-35 {
    margin-top: 140px;
  }
  .mobile-p-35 {
    padding: 140px;
  }
  .mobile-p-35-i {
    padding: 140px !important;
  }
  .mobile-px-35 {
    padding-left: 140px;
    padding-right: 140px;
  }
  .mobile-px-35-i {
    padding-left: 140px !important;
    padding-right: 140px !important;
  }
  .mobile-py-35 {
    padding-top: 140px;
    padding-bottom: 140px;
  }
  .mobile-pl-35 {
    padding-left: 140px;
  }
  .mobile-pr-35 {
    padding-right: 140px;
  }
  .mobile-pl-35-i {
    padding-left: 140px !important;
  }
  .mobile-pr-35-i {
    padding-right: 140px !important;
  }
  .mobile-pb-35 {
    padding-bottom: 140px;
  }
  .mobile-pb-35-i {
    padding-bottom: 140px !important;
  }
  .mobile-pt-35 {
    padding-top: 140px;
  }
  .mobile-m-50 {
    margin: 200px;
  }
  .mobile-mx-50 {
    margin-left: 200px;
    margin-right: 200px;
  }
  .mobile-my-50 {
    margin-top: 200px;
    margin-bottom: 200px;
  }
  .mobile-ml-50 {
    margin-left: 200px;
  }
  .mobile-ml-50-i {
    margin-left: 200px !important;
  }
  .mobile-mr-50 {
    margin-right: 200px;
  }
  .mobile-mr-50-i {
    margin-right: 200px !important;
  }
  .mobile-mb-50 {
    margin-bottom: 200px;
  }
  .mobile-mt-50 {
    margin-top: 200px;
  }
  .mobile-p-50 {
    padding: 200px;
  }
  .mobile-p-50-i {
    padding: 200px !important;
  }
  .mobile-px-50 {
    padding-left: 200px;
    padding-right: 200px;
  }
  .mobile-px-50-i {
    padding-left: 200px !important;
    padding-right: 200px !important;
  }
  .mobile-py-50 {
    padding-top: 200px;
    padding-bottom: 200px;
  }
  .mobile-pl-50 {
    padding-left: 200px;
  }
  .mobile-pr-50 {
    padding-right: 200px;
  }
  .mobile-pl-50-i {
    padding-left: 200px !important;
  }
  .mobile-pr-50-i {
    padding-right: 200px !important;
  }
  .mobile-pb-50 {
    padding-bottom: 200px;
  }
  .mobile-pb-50-i {
    padding-bottom: 200px !important;
  }
  .mobile-pt-50 {
    padding-top: 200px;
  }
  .mobile-m-65 {
    margin: 260px;
  }
  .mobile-mx-65 {
    margin-left: 260px;
    margin-right: 260px;
  }
  .mobile-my-65 {
    margin-top: 260px;
    margin-bottom: 260px;
  }
  .mobile-ml-65 {
    margin-left: 260px;
  }
  .mobile-ml-65-i {
    margin-left: 260px !important;
  }
  .mobile-mr-65 {
    margin-right: 260px;
  }
  .mobile-mr-65-i {
    margin-right: 260px !important;
  }
  .mobile-mb-65 {
    margin-bottom: 260px;
  }
  .mobile-mt-65 {
    margin-top: 260px;
  }
  .mobile-p-65 {
    padding: 260px;
  }
  .mobile-p-65-i {
    padding: 260px !important;
  }
  .mobile-px-65 {
    padding-left: 260px;
    padding-right: 260px;
  }
  .mobile-px-65-i {
    padding-left: 260px !important;
    padding-right: 260px !important;
  }
  .mobile-py-65 {
    padding-top: 260px;
    padding-bottom: 260px;
  }
  .mobile-pl-65 {
    padding-left: 260px;
  }
  .mobile-pr-65 {
    padding-right: 260px;
  }
  .mobile-pl-65-i {
    padding-left: 260px !important;
  }
  .mobile-pr-65-i {
    padding-right: 260px !important;
  }
  .mobile-pb-65 {
    padding-bottom: 260px;
  }
  .mobile-pb-65-i {
    padding-bottom: 260px !important;
  }
  .mobile-pt-65 {
    padding-top: 260px;
  }
  .mobile-m-85 {
    margin: 340px;
  }
  .mobile-mx-85 {
    margin-left: 340px;
    margin-right: 340px;
  }
  .mobile-my-85 {
    margin-top: 340px;
    margin-bottom: 340px;
  }
  .mobile-ml-85 {
    margin-left: 340px;
  }
  .mobile-ml-85-i {
    margin-left: 340px !important;
  }
  .mobile-mr-85 {
    margin-right: 340px;
  }
  .mobile-mr-85-i {
    margin-right: 340px !important;
  }
  .mobile-mb-85 {
    margin-bottom: 340px;
  }
  .mobile-mt-85 {
    margin-top: 340px;
  }
  .mobile-p-85 {
    padding: 340px;
  }
  .mobile-p-85-i {
    padding: 340px !important;
  }
  .mobile-px-85 {
    padding-left: 340px;
    padding-right: 340px;
  }
  .mobile-px-85-i {
    padding-left: 340px !important;
    padding-right: 340px !important;
  }
  .mobile-py-85 {
    padding-top: 340px;
    padding-bottom: 340px;
  }
  .mobile-pl-85 {
    padding-left: 340px;
  }
  .mobile-pr-85 {
    padding-right: 340px;
  }
  .mobile-pl-85-i {
    padding-left: 340px !important;
  }
  .mobile-pr-85-i {
    padding-right: 340px !important;
  }
  .mobile-pb-85 {
    padding-bottom: 340px;
  }
  .mobile-pb-85-i {
    padding-bottom: 340px !important;
  }
  .mobile-pt-85 {
    padding-top: 340px;
  }
  .mobile-m-100 {
    margin: 400px;
  }
  .mobile-mx-100 {
    margin-left: 400px;
    margin-right: 400px;
  }
  .mobile-my-100 {
    margin-top: 400px;
    margin-bottom: 400px;
  }
  .mobile-ml-100 {
    margin-left: 400px;
  }
  .mobile-ml-100-i {
    margin-left: 400px !important;
  }
  .mobile-mr-100 {
    margin-right: 400px;
  }
  .mobile-mr-100-i {
    margin-right: 400px !important;
  }
  .mobile-mb-100 {
    margin-bottom: 400px;
  }
  .mobile-mt-100 {
    margin-top: 400px;
  }
  .mobile-p-100 {
    padding: 400px;
  }
  .mobile-p-100-i {
    padding: 400px !important;
  }
  .mobile-px-100 {
    padding-left: 400px;
    padding-right: 400px;
  }
  .mobile-px-100-i {
    padding-left: 400px !important;
    padding-right: 400px !important;
  }
  .mobile-py-100 {
    padding-top: 400px;
    padding-bottom: 400px;
  }
  .mobile-pl-100 {
    padding-left: 400px;
  }
  .mobile-pr-100 {
    padding-right: 400px;
  }
  .mobile-pl-100-i {
    padding-left: 400px !important;
  }
  .mobile-pr-100-i {
    padding-right: 400px !important;
  }
  .mobile-pb-100 {
    padding-bottom: 400px;
  }
  .mobile-pb-100-i {
    padding-bottom: 400px !important;
  }
  .mobile-pt-100 {
    padding-top: 400px;
  }
  .mobile-d-flex {
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flexbox;
    display: -ms-flex;
    display: flex;
  }
  .mobile-d-flex-i {
    display: -webkit-flex !important;
    display: -moz-flex !important;
    display: -ms-flex !importantbox;
    display: -ms-flex !important;
    display: flex !important;
  }
  .mobile-d-block {
    display: -webkit-block;
    display: -moz-block;
    display: -ms-blockbox;
    display: -ms-block;
    display: block;
  }
  .mobile-d-grid {
    display: -webkit-grid;
    display: -moz-grid;
    display: -ms-gridbox;
    display: -ms-grid;
    display: grid;
  }
  .mobile-d-inline {
    display: -webkit-inline-flex;
    display: -moz-inline-flex;
    display: -ms-inline-flexbox;
    display: -ms-inline-flex;
    display: inline-flex;
  }
  .mobile-d-none {
    display: -webkit-none;
    display: -moz-none;
    display: -ms-nonebox;
    display: -ms-none;
    display: none;
  }
  .mobile-direction-row {
    -webkit-flex-direction: row;
    -moz-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
  }
  .mobile-directionrow-reverse {
    -webkit-flex-direction: row-reverse;
    -moz-flex-direction: row-reverse;
    -ms-flex-direction: row-reverse;
    flex-direction: row-reverse;
  }
  .mobile-direction-column {
    -webkit-flex-direction: column;
    -moz-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .mobile-direction-column-reverse {
    -webkit-flex-direction: column-reverse;
    -moz-flex-direction: column-reverse;
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse;
  }
  .mobile-wrap-wrap {
    -webkit-flex-wrap: wrap;
    -moz-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
  .mobile-wrap-nowrap {
    -webkit-flex-wrap: nowrap;
    -moz-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
  }
  .mobile-wrap-reverse {
    -webkit-flex-wrap: wrap-reverse;
    -moz-flex-wrap: wrap-reverse;
    -ms-flex-wrap: wrap-reverse;
    flex-wrap: wrap-reverse;
  }
  .mobile-justify-flex-start {
    -webkit-justify-content: flex-start;
    -moz-justify-content: flex-start;
    -ms-justify-content: flex-start;
    justify-content: flex-start;
  }
  .mobile-justify-flex-end {
    -webkit-justify-content: flex-end;
    -moz-justify-content: flex-end;
    -ms-justify-content: flex-end;
    justify-content: flex-end;
  }
  .mobile-justify-center {
    -webkit-justify-content: center;
    -moz-justify-content: center;
    -ms-justify-content: center;
    justify-content: center;
  }
  .mobile-justify-space-between {
    -webkit-justify-content: space-between;
    -moz-justify-content: space-between;
    -ms-justify-content: space-between;
    justify-content: space-between;
  }
  .mobile-justify-space-around {
    -webkit-justify-content: space-around;
    -moz-justify-content: space-around;
    -ms-justify-content: space-around;
    justify-content: space-around;
  }
  .mobile-justify-self-flex-start {
    -webkit-justify-self: flex-start;
    -moz-justify-self: flex-start;
    -ms-justify-self: flex-start;
    justify-self: flex-start;
  }
  .mobile-justify-self-flex-end {
    -webkit-justify-self: flex-end;
    -moz-justify-self: flex-end;
    -ms-justify-self: flex-end;
    justify-self: flex-end;
  }
  .mobile-justify-self-center {
    -webkit-justify-self: center;
    -moz-justify-self: center;
    -ms-justify-self: center;
    justify-self: center;
  }
  .mobile-justify-self-space-between {
    -webkit-justify-self: space-between;
    -moz-justify-self: space-between;
    -ms-justify-self: space-between;
    justify-self: space-between;
  }
  .mobile-justify-self-space-around {
    -webkit-justify-self: space-around;
    -moz-justify-self: space-around;
    -ms-justify-self: space-around;
    justify-self: space-around;
  }
  .mobile-align-content-flex-start {
    -webkit-align-content: flex-start;
    -moz-align-content: flex-start;
    -ms-align-content: flex-start;
    align-content: flex-start;
  }
  .mobile-align-content-flex-end {
    -webkit-align-content: flex-end;
    -moz-align-content: flex-end;
    -ms-align-content: flex-end;
    align-content: flex-end;
  }
  .mobile-align-content-center {
    -webkit-align-content: center;
    -moz-align-content: center;
    -ms-align-content: center;
    align-content: center;
  }
  .mobile-align-content-space-between {
    -webkit-align-content: space-between;
    -moz-align-content: space-between;
    -ms-align-content: space-between;
    align-content: space-between;
  }
  .mobile-align-content-space-around {
    -webkit-align-content: space-around;
    -moz-align-content: space-around;
    -ms-align-content: space-around;
    align-content: space-around;
  }
  .mobile-align-content-stretch {
    -webkit-align-content: stretch;
    -moz-align-content: stretch;
    -ms-align-content: stretch;
    align-content: stretch;
  }
  .mobile-align-items-flex-start {
    -webkit-align-items: flex-start;
    -moz-align-items: flex-start;
    -ms-align-items: flex-start;
    align-items: flex-start;
  }
  .mobile-align-items-flex-end {
    -webkit-align-items: flex-end;
    -moz-align-items: flex-end;
    -ms-align-items: flex-end;
    align-items: flex-end;
  }
  .mobile-align-items-center {
    -webkit-align-items: center;
    -moz-align-items: center;
    -ms-align-items: center;
    align-items: center;
  }
  .mobile-align-items-baseline {
    -webkit-align-items: baseline;
    -moz-align-items: baseline;
    -ms-align-items: baseline;
    align-items: baseline;
  }
  .mobile-align-items-stretch {
    -webkit-align-items: stretch;
    -moz-align-items: stretch;
    -ms-align-items: stretch;
    align-items: stretch;
  }
  .mobile-order-0 {
    -webkit-order: 0;
    -moz-order: 0;
    -ms-order: 0;
    order: 0;
  }
  .mobile-order-1 {
    -webkit-order: 1;
    -moz-order: 1;
    -ms-order: 1;
    order: 1;
  }
  .mobile-order-2 {
    -webkit-order: 2;
    -moz-order: 2;
    -ms-order: 2;
    order: 2;
  }
  .mobile-order-3 {
    -webkit-order: 3;
    -moz-order: 3;
    -ms-order: 3;
    order: 3;
  }
  .mobile-order-4 {
    -webkit-order: 4;
    -moz-order: 4;
    -ms-order: 4;
    order: 4;
  }
  .mobile-order-5 {
    -webkit-order: 5;
    -moz-order: 5;
    -ms-order: 5;
    order: 5;
  }
  .mobile-order-6 {
    -webkit-order: 6;
    -moz-order: 6;
    -ms-order: 6;
    order: 6;
  }
  .mobile-order-7 {
    -webkit-order: 7;
    -moz-order: 7;
    -ms-order: 7;
    order: 7;
  }
  .mobile-order-8 {
    -webkit-order: 8;
    -moz-order: 8;
    -ms-order: 8;
    order: 8;
  }
  .mobile-order-9 {
    -webkit-order: 9;
    -moz-order: 9;
    -ms-order: 9;
    order: 9;
  }
  .mobile-order-10 {
    -webkit-order: 10;
    -moz-order: 10;
    -ms-order: 10;
    order: 10;
  }
  .mobile-grow-0 {
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-grow: 0;
    flex-grow: 0;
  }
  .mobile-grow-1 {
    -webkit-flex-grow: 1;
    -moz-flex-grow: 1;
    -ms-flex-grow: 1;
    flex-grow: 1;
  }
  .mobile-grow-2 {
    -webkit-flex-grow: 2;
    -moz-flex-grow: 2;
    -ms-flex-grow: 2;
    flex-grow: 2;
  }
  .mobile-grow-3 {
    -webkit-flex-grow: 3;
    -moz-flex-grow: 3;
    -ms-flex-grow: 3;
    flex-grow: 3;
  }
  .mobile-grow-4 {
    -webkit-flex-grow: 4;
    -moz-flex-grow: 4;
    -ms-flex-grow: 4;
    flex-grow: 4;
  }
  .mobile-grow-5 {
    -webkit-flex-grow: 5;
    -moz-flex-grow: 5;
    -ms-flex-grow: 5;
    flex-grow: 5;
  }
  .mobile-grow-6 {
    -webkit-flex-grow: 6;
    -moz-flex-grow: 6;
    -ms-flex-grow: 6;
    flex-grow: 6;
  }
  .mobile-grow-7 {
    -webkit-flex-grow: 7;
    -moz-flex-grow: 7;
    -ms-flex-grow: 7;
    flex-grow: 7;
  }
  .mobile-grow-8 {
    -webkit-flex-grow: 8;
    -moz-flex-grow: 8;
    -ms-flex-grow: 8;
    flex-grow: 8;
  }
  .mobile-grow-9 {
    -webkit-flex-grow: 9;
    -moz-flex-grow: 9;
    -ms-flex-grow: 9;
    flex-grow: 9;
  }
  .mobile-grow-10 {
    -webkit-flex-grow: 10;
    -moz-flex-grow: 10;
    -ms-flex-grow: 10;
    flex-grow: 10;
  }
  .mobile-shrink-0 {
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-shrink: 0;
    flex-shrink: 0;
  }
  .mobile-shrink-1 {
    -webkit-flex-shrink: 1;
    -moz-flex-shrink: 1;
    -ms-flex-shrink: 1;
    flex-shrink: 1;
  }
  .mobile-shrink-2 {
    -webkit-flex-shrink: 2;
    -moz-flex-shrink: 2;
    -ms-flex-shrink: 2;
    flex-shrink: 2;
  }
  .mobile-shrink-3 {
    -webkit-flex-shrink: 3;
    -moz-flex-shrink: 3;
    -ms-flex-shrink: 3;
    flex-shrink: 3;
  }
  .mobile-shrink-4 {
    -webkit-flex-shrink: 4;
    -moz-flex-shrink: 4;
    -ms-flex-shrink: 4;
    flex-shrink: 4;
  }
  .mobile-shrink-5 {
    -webkit-flex-shrink: 5;
    -moz-flex-shrink: 5;
    -ms-flex-shrink: 5;
    flex-shrink: 5;
  }
  .mobile-shrink-6 {
    -webkit-flex-shrink: 6;
    -moz-flex-shrink: 6;
    -ms-flex-shrink: 6;
    flex-shrink: 6;
  }
  .mobile-shrink-7 {
    -webkit-flex-shrink: 7;
    -moz-flex-shrink: 7;
    -ms-flex-shrink: 7;
    flex-shrink: 7;
  }
  .mobile-shrink-8 {
    -webkit-flex-shrink: 8;
    -moz-flex-shrink: 8;
    -ms-flex-shrink: 8;
    flex-shrink: 8;
  }
  .mobile-shrink-9 {
    -webkit-flex-shrink: 9;
    -moz-flex-shrink: 9;
    -ms-flex-shrink: 9;
    flex-shrink: 9;
  }
  .mobile-shrink-10 {
    -webkit-flex-shrink: 10;
    -moz-flex-shrink: 10;
    -ms-flex-shrink: 10;
    flex-shrink: 10;
  }
  .mobile-align-self-flex-start {
    -webkit-align-self: flex-start;
    -moz-align-self: flex-start;
    -ms-align-self: flex-start;
    align-self: flex-start;
  }
  .mobile-align-self-flex-end {
    -webkit-align-self: flex-end;
    -moz-align-self: flex-end;
    -ms-align-self: flex-end;
    align-self: flex-end;
  }
  .mobile-align-self-center {
    -webkit-align-self: center;
    -moz-align-self: center;
    -ms-align-self: center;
    align-self: center;
  }
  .mobile-align-self-baseline {
    -webkit-align-self: baseline;
    -moz-align-self: baseline;
    -ms-align-self: baseline;
    align-self: baseline;
  }
  .mobile-align-self-stretch {
    -webkit-align-self: stretch;
    -moz-align-self: stretch;
    -ms-align-self: stretch;
    align-self: stretch;
  }
}
@media (min-width: 768px) {
  .tablet-icon-size-0 {
    font-size: 0px;
    line-height: 0px;
    height: 0px !important;
    width: 0px !important;
  }
  .tablet-icon-size-1 {
    font-size: 2px;
    line-height: 2px;
    height: 2px !important;
    width: 2px !important;
  }
  .tablet-icon-size-2 {
    font-size: 4px;
    line-height: 4px;
    height: 4px !important;
    width: 4px !important;
  }
  .tablet-icon-size-3 {
    font-size: 6px;
    line-height: 6px;
    height: 6px !important;
    width: 6px !important;
  }
  .tablet-icon-size-4 {
    font-size: 8px;
    line-height: 8px;
    height: 8px !important;
    width: 8px !important;
  }
  .tablet-icon-size-5 {
    font-size: 10px;
    line-height: 10px;
    height: 10px !important;
    width: 10px !important;
  }
  .tablet-icon-size-6 {
    font-size: 12px;
    line-height: 12px;
    height: 12px !important;
    width: 12px !important;
  }
  .tablet-icon-size-7 {
    font-size: 14px;
    line-height: 14px;
    height: 14px !important;
    width: 14px !important;
  }
  .tablet-icon-size-8 {
    font-size: 16px;
    line-height: 16px;
    height: 16px !important;
    width: 16px !important;
  }
  .tablet-icon-size-9 {
    font-size: 18px;
    line-height: 18px;
    height: 18px !important;
    width: 18px !important;
  }
  .tablet-icon-size-10 {
    font-size: 20px;
    line-height: 20px;
    height: 20px !important;
    width: 20px !important;
  }
  .tablet-icon-size-11 {
    font-size: 22px;
    line-height: 22px;
    height: 22px !important;
    width: 22px !important;
  }
  .tablet-icon-size-12 {
    font-size: 24px;
    line-height: 24px;
    height: 24px !important;
    width: 24px !important;
  }
  .tablet-icon-size-13 {
    font-size: 26px;
    line-height: 26px;
    height: 26px !important;
    width: 26px !important;
  }
  .tablet-icon-size-14 {
    font-size: 28px;
    line-height: 28px;
    height: 28px !important;
    width: 28px !important;
  }
  .tablet-icon-size-15 {
    font-size: 30px;
    line-height: 30px;
    height: 30px !important;
    width: 30px !important;
  }
  .tablet-icon-size-16 {
    font-size: 32px;
    line-height: 32px;
    height: 32px !important;
    width: 32px !important;
  }
  .tablet-icon-size-17 {
    font-size: 34px;
    line-height: 34px;
    height: 34px !important;
    width: 34px !important;
  }
  .tablet-icon-size-18 {
    font-size: 36px;
    line-height: 36px;
    height: 36px !important;
    width: 36px !important;
  }
  .tablet-icon-size-19 {
    font-size: 38px;
    line-height: 38px;
    height: 38px !important;
    width: 38px !important;
  }
  .tablet-icon-size-20 {
    font-size: 40px;
    line-height: 40px;
    height: 40px !important;
    width: 40px !important;
  }
  .tablet-icon-size-25 {
    font-size: 50px;
    line-height: 50px;
    height: 50px !important;
    width: 50px !important;
  }
  .tablet-icon-size-30 {
    font-size: 60px;
    line-height: 60px;
    height: 60px !important;
    width: 60px !important;
  }
  .tablet-icon-size-35 {
    font-size: 70px;
    line-height: 70px;
    height: 70px !important;
    width: 70px !important;
  }
  .tablet-icon-size-50 {
    font-size: 100px;
    line-height: 100px;
    height: 100px !important;
    width: 100px !important;
  }
  .tablet-icon-size-65 {
    font-size: 130px;
    line-height: 130px;
    height: 130px !important;
    width: 130px !important;
  }
  .tablet-icon-size-85 {
    font-size: 170px;
    line-height: 170px;
    height: 170px !important;
    width: 170px !important;
  }
  .tablet-icon-size-100 {
    font-size: 200px;
    line-height: 200px;
    height: 200px !important;
    width: 200px !important;
  }
  .tablet-text-size-0 {
    font-size: 0px;
  }
  .tablet-text-size-1 {
    font-size: 2px;
  }
  .tablet-text-size-2 {
    font-size: 4px;
  }
  .tablet-text-size-3 {
    font-size: 6px;
  }
  .tablet-text-size-4 {
    font-size: 8px;
  }
  .tablet-text-size-5 {
    font-size: 10px;
  }
  .tablet-text-size-6 {
    font-size: 12px;
  }
  .tablet-text-size-7 {
    font-size: 14px;
  }
  .tablet-text-size-8 {
    font-size: 16px;
  }
  .tablet-text-size-9 {
    font-size: 18px;
  }
  .tablet-text-size-10 {
    font-size: 20px;
  }
  .tablet-text-size-11 {
    font-size: 22px;
  }
  .tablet-text-size-12 {
    font-size: 24px;
  }
  .tablet-text-size-13 {
    font-size: 26px;
  }
  .tablet-text-size-14 {
    font-size: 28px;
  }
  .tablet-text-size-15 {
    font-size: 30px;
  }
  .tablet-text-size-16 {
    font-size: 32px;
  }
  .tablet-text-size-17 {
    font-size: 34px;
  }
  .tablet-text-size-18 {
    font-size: 36px;
  }
  .tablet-text-size-19 {
    font-size: 38px;
  }
  .tablet-text-size-20 {
    font-size: 40px;
  }
  .tablet-text-size-25 {
    font-size: 50px;
  }
  .tablet-text-size-30 {
    font-size: 60px;
  }
  .tablet-text-size-35 {
    font-size: 70px;
  }
  .tablet-text-size-50 {
    font-size: 100px;
  }
  .tablet-text-size-65 {
    font-size: 130px;
  }
  .tablet-text-size-85 {
    font-size: 170px;
  }
  .tablet-text-size-100 {
    font-size: 200px;
  }
  .tablet-w-0 {
    width: 0px;
  }
  .tablet-h-0 {
    height: 0px;
  }
  .tablet-h-0-i {
    height: 0px !important;
  }
  .tablet-min-h-0 {
    min-height: 0px;
  }
  .tablet-min-w-0 {
    min-width: 0px !important;
  }
  .tablet-w-1 {
    width: 4px;
  }
  .tablet-h-1 {
    height: 4px;
  }
  .tablet-h-1-i {
    height: 4px !important;
  }
  .tablet-min-h-1 {
    min-height: 4px;
  }
  .tablet-min-w-1 {
    min-width: 4px !important;
  }
  .tablet-w-2 {
    width: 8px;
  }
  .tablet-h-2 {
    height: 8px;
  }
  .tablet-h-2-i {
    height: 8px !important;
  }
  .tablet-min-h-2 {
    min-height: 8px;
  }
  .tablet-min-w-2 {
    min-width: 8px !important;
  }
  .tablet-w-3 {
    width: 12px;
  }
  .tablet-h-3 {
    height: 12px;
  }
  .tablet-h-3-i {
    height: 12px !important;
  }
  .tablet-min-h-3 {
    min-height: 12px;
  }
  .tablet-min-w-3 {
    min-width: 12px !important;
  }
  .tablet-w-4 {
    width: 16px;
  }
  .tablet-h-4 {
    height: 16px;
  }
  .tablet-h-4-i {
    height: 16px !important;
  }
  .tablet-min-h-4 {
    min-height: 16px;
  }
  .tablet-min-w-4 {
    min-width: 16px !important;
  }
  .tablet-w-5 {
    width: 20px;
  }
  .tablet-h-5 {
    height: 20px;
  }
  .tablet-h-5-i {
    height: 20px !important;
  }
  .tablet-min-h-5 {
    min-height: 20px;
  }
  .tablet-min-w-5 {
    min-width: 20px !important;
  }
  .tablet-w-6 {
    width: 24px;
  }
  .tablet-h-6 {
    height: 24px;
  }
  .tablet-h-6-i {
    height: 24px !important;
  }
  .tablet-min-h-6 {
    min-height: 24px;
  }
  .tablet-min-w-6 {
    min-width: 24px !important;
  }
  .tablet-w-7 {
    width: 28px;
  }
  .tablet-h-7 {
    height: 28px;
  }
  .tablet-h-7-i {
    height: 28px !important;
  }
  .tablet-min-h-7 {
    min-height: 28px;
  }
  .tablet-min-w-7 {
    min-width: 28px !important;
  }
  .tablet-w-8 {
    width: 32px;
  }
  .tablet-h-8 {
    height: 32px;
  }
  .tablet-h-8-i {
    height: 32px !important;
  }
  .tablet-min-h-8 {
    min-height: 32px;
  }
  .tablet-min-w-8 {
    min-width: 32px !important;
  }
  .tablet-w-9 {
    width: 36px;
  }
  .tablet-h-9 {
    height: 36px;
  }
  .tablet-h-9-i {
    height: 36px !important;
  }
  .tablet-min-h-9 {
    min-height: 36px;
  }
  .tablet-min-w-9 {
    min-width: 36px !important;
  }
  .tablet-w-10 {
    width: 40px;
  }
  .tablet-h-10 {
    height: 40px;
  }
  .tablet-h-10-i {
    height: 40px !important;
  }
  .tablet-min-h-10 {
    min-height: 40px;
  }
  .tablet-min-w-10 {
    min-width: 40px !important;
  }
  .tablet-w-11 {
    width: 44px;
  }
  .tablet-h-11 {
    height: 44px;
  }
  .tablet-h-11-i {
    height: 44px !important;
  }
  .tablet-min-h-11 {
    min-height: 44px;
  }
  .tablet-min-w-11 {
    min-width: 44px !important;
  }
  .tablet-w-12 {
    width: 48px;
  }
  .tablet-h-12 {
    height: 48px;
  }
  .tablet-h-12-i {
    height: 48px !important;
  }
  .tablet-min-h-12 {
    min-height: 48px;
  }
  .tablet-min-w-12 {
    min-width: 48px !important;
  }
  .tablet-w-13 {
    width: 52px;
  }
  .tablet-h-13 {
    height: 52px;
  }
  .tablet-h-13-i {
    height: 52px !important;
  }
  .tablet-min-h-13 {
    min-height: 52px;
  }
  .tablet-min-w-13 {
    min-width: 52px !important;
  }
  .tablet-w-14 {
    width: 56px;
  }
  .tablet-h-14 {
    height: 56px;
  }
  .tablet-h-14-i {
    height: 56px !important;
  }
  .tablet-min-h-14 {
    min-height: 56px;
  }
  .tablet-min-w-14 {
    min-width: 56px !important;
  }
  .tablet-w-15 {
    width: 60px;
  }
  .tablet-h-15 {
    height: 60px;
  }
  .tablet-h-15-i {
    height: 60px !important;
  }
  .tablet-min-h-15 {
    min-height: 60px;
  }
  .tablet-min-w-15 {
    min-width: 60px !important;
  }
  .tablet-w-16 {
    width: 64px;
  }
  .tablet-h-16 {
    height: 64px;
  }
  .tablet-h-16-i {
    height: 64px !important;
  }
  .tablet-min-h-16 {
    min-height: 64px;
  }
  .tablet-min-w-16 {
    min-width: 64px !important;
  }
  .tablet-w-17 {
    width: 68px;
  }
  .tablet-h-17 {
    height: 68px;
  }
  .tablet-h-17-i {
    height: 68px !important;
  }
  .tablet-min-h-17 {
    min-height: 68px;
  }
  .tablet-min-w-17 {
    min-width: 68px !important;
  }
  .tablet-w-18 {
    width: 72px;
  }
  .tablet-h-18 {
    height: 72px;
  }
  .tablet-h-18-i {
    height: 72px !important;
  }
  .tablet-min-h-18 {
    min-height: 72px;
  }
  .tablet-min-w-18 {
    min-width: 72px !important;
  }
  .tablet-w-19 {
    width: 76px;
  }
  .tablet-h-19 {
    height: 76px;
  }
  .tablet-h-19-i {
    height: 76px !important;
  }
  .tablet-min-h-19 {
    min-height: 76px;
  }
  .tablet-min-w-19 {
    min-width: 76px !important;
  }
  .tablet-w-20 {
    width: 80px;
  }
  .tablet-h-20 {
    height: 80px;
  }
  .tablet-h-20-i {
    height: 80px !important;
  }
  .tablet-min-h-20 {
    min-height: 80px;
  }
  .tablet-min-w-20 {
    min-width: 80px !important;
  }
  .tablet-w-25 {
    width: 100px;
  }
  .tablet-h-25 {
    height: 100px;
  }
  .tablet-h-25-i {
    height: 100px !important;
  }
  .tablet-min-h-25 {
    min-height: 100px;
  }
  .tablet-min-w-25 {
    min-width: 100px !important;
  }
  .tablet-w-30 {
    width: 120px;
  }
  .tablet-h-30 {
    height: 120px;
  }
  .tablet-h-30-i {
    height: 120px !important;
  }
  .tablet-min-h-30 {
    min-height: 120px;
  }
  .tablet-min-w-30 {
    min-width: 120px !important;
  }
  .tablet-w-35 {
    width: 140px;
  }
  .tablet-h-35 {
    height: 140px;
  }
  .tablet-h-35-i {
    height: 140px !important;
  }
  .tablet-min-h-35 {
    min-height: 140px;
  }
  .tablet-min-w-35 {
    min-width: 140px !important;
  }
  .tablet-w-50 {
    width: 200px;
  }
  .tablet-h-50 {
    height: 200px;
  }
  .tablet-h-50-i {
    height: 200px !important;
  }
  .tablet-min-h-50 {
    min-height: 200px;
  }
  .tablet-min-w-50 {
    min-width: 200px !important;
  }
  .tablet-w-65 {
    width: 260px;
  }
  .tablet-h-65 {
    height: 260px;
  }
  .tablet-h-65-i {
    height: 260px !important;
  }
  .tablet-min-h-65 {
    min-height: 260px;
  }
  .tablet-min-w-65 {
    min-width: 260px !important;
  }
  .tablet-w-85 {
    width: 340px;
  }
  .tablet-h-85 {
    height: 340px;
  }
  .tablet-h-85-i {
    height: 340px !important;
  }
  .tablet-min-h-85 {
    min-height: 340px;
  }
  .tablet-min-w-85 {
    min-width: 340px !important;
  }
  .tablet-w-100 {
    width: 400px;
  }
  .tablet-h-100 {
    height: 400px;
  }
  .tablet-h-100-i {
    height: 400px !important;
  }
  .tablet-min-h-100 {
    min-height: 400px;
  }
  .tablet-min-w-100 {
    min-width: 400px !important;
  }
  .tablet-m-0 {
    margin: 0px;
  }
  .tablet-mx-0 {
    margin-left: 0px;
    margin-right: 0px;
  }
  .tablet-my-0 {
    margin-top: 0px;
    margin-bottom: 0px;
  }
  .tablet-ml-0 {
    margin-left: 0px;
  }
  .tablet-ml-0-i {
    margin-left: 0px !important;
  }
  .tablet-mr-0 {
    margin-right: 0px;
  }
  .tablet-mr-0-i {
    margin-right: 0px !important;
  }
  .tablet-mb-0 {
    margin-bottom: 0px;
  }
  .tablet-mt-0 {
    margin-top: 0px;
  }
  .tablet-p-0 {
    padding: 0px;
  }
  .tablet-p-0-i {
    padding: 0px !important;
  }
  .tablet-px-0 {
    padding-left: 0px;
    padding-right: 0px;
  }
  .tablet-px-0-i {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
  .tablet-py-0 {
    padding-top: 0px;
    padding-bottom: 0px;
  }
  .tablet-pl-0 {
    padding-left: 0px;
  }
  .tablet-pr-0 {
    padding-right: 0px;
  }
  .tablet-pl-0-i {
    padding-left: 0px !important;
  }
  .tablet-pr-0-i {
    padding-right: 0px !important;
  }
  .tablet-pb-0 {
    padding-bottom: 0px;
  }
  .tablet-pb-0-i {
    padding-bottom: 0px !important;
  }
  .tablet-pt-0 {
    padding-top: 0px;
  }
  .tablet-m-1 {
    margin: 4px;
  }
  .tablet-mx-1 {
    margin-left: 4px;
    margin-right: 4px;
  }
  .tablet-my-1 {
    margin-top: 4px;
    margin-bottom: 4px;
  }
  .tablet-ml-1 {
    margin-left: 4px;
  }
  .tablet-ml-1-i {
    margin-left: 4px !important;
  }
  .tablet-mr-1 {
    margin-right: 4px;
  }
  .tablet-mr-1-i {
    margin-right: 4px !important;
  }
  .tablet-mb-1 {
    margin-bottom: 4px;
  }
  .tablet-mt-1 {
    margin-top: 4px;
  }
  .tablet-p-1 {
    padding: 4px;
  }
  .tablet-p-1-i {
    padding: 4px !important;
  }
  .tablet-px-1 {
    padding-left: 4px;
    padding-right: 4px;
  }
  .tablet-px-1-i {
    padding-left: 4px !important;
    padding-right: 4px !important;
  }
  .tablet-py-1 {
    padding-top: 4px;
    padding-bottom: 4px;
  }
  .tablet-pl-1 {
    padding-left: 4px;
  }
  .tablet-pr-1 {
    padding-right: 4px;
  }
  .tablet-pl-1-i {
    padding-left: 4px !important;
  }
  .tablet-pr-1-i {
    padding-right: 4px !important;
  }
  .tablet-pb-1 {
    padding-bottom: 4px;
  }
  .tablet-pb-1-i {
    padding-bottom: 4px !important;
  }
  .tablet-pt-1 {
    padding-top: 4px;
  }
  .tablet-m-2 {
    margin: 8px;
  }
  .tablet-mx-2 {
    margin-left: 8px;
    margin-right: 8px;
  }
  .tablet-my-2 {
    margin-top: 8px;
    margin-bottom: 8px;
  }
  .tablet-ml-2 {
    margin-left: 8px;
  }
  .tablet-ml-2-i {
    margin-left: 8px !important;
  }
  .tablet-mr-2 {
    margin-right: 8px;
  }
  .tablet-mr-2-i {
    margin-right: 8px !important;
  }
  .tablet-mb-2 {
    margin-bottom: 8px;
  }
  .tablet-mt-2 {
    margin-top: 8px;
  }
  .tablet-p-2 {
    padding: 8px;
  }
  .tablet-p-2-i {
    padding: 8px !important;
  }
  .tablet-px-2 {
    padding-left: 8px;
    padding-right: 8px;
  }
  .tablet-px-2-i {
    padding-left: 8px !important;
    padding-right: 8px !important;
  }
  .tablet-py-2 {
    padding-top: 8px;
    padding-bottom: 8px;
  }
  .tablet-pl-2 {
    padding-left: 8px;
  }
  .tablet-pr-2 {
    padding-right: 8px;
  }
  .tablet-pl-2-i {
    padding-left: 8px !important;
  }
  .tablet-pr-2-i {
    padding-right: 8px !important;
  }
  .tablet-pb-2 {
    padding-bottom: 8px;
  }
  .tablet-pb-2-i {
    padding-bottom: 8px !important;
  }
  .tablet-pt-2 {
    padding-top: 8px;
  }
  .tablet-m-3 {
    margin: 12px;
  }
  .tablet-mx-3 {
    margin-left: 12px;
    margin-right: 12px;
  }
  .tablet-my-3 {
    margin-top: 12px;
    margin-bottom: 12px;
  }
  .tablet-ml-3 {
    margin-left: 12px;
  }
  .tablet-ml-3-i {
    margin-left: 12px !important;
  }
  .tablet-mr-3 {
    margin-right: 12px;
  }
  .tablet-mr-3-i {
    margin-right: 12px !important;
  }
  .tablet-mb-3 {
    margin-bottom: 12px;
  }
  .tablet-mt-3 {
    margin-top: 12px;
  }
  .tablet-p-3 {
    padding: 12px;
  }
  .tablet-p-3-i {
    padding: 12px !important;
  }
  .tablet-px-3 {
    padding-left: 12px;
    padding-right: 12px;
  }
  .tablet-px-3-i {
    padding-left: 12px !important;
    padding-right: 12px !important;
  }
  .tablet-py-3 {
    padding-top: 12px;
    padding-bottom: 12px;
  }
  .tablet-pl-3 {
    padding-left: 12px;
  }
  .tablet-pr-3 {
    padding-right: 12px;
  }
  .tablet-pl-3-i {
    padding-left: 12px !important;
  }
  .tablet-pr-3-i {
    padding-right: 12px !important;
  }
  .tablet-pb-3 {
    padding-bottom: 12px;
  }
  .tablet-pb-3-i {
    padding-bottom: 12px !important;
  }
  .tablet-pt-3 {
    padding-top: 12px;
  }
  .tablet-m-4 {
    margin: 16px;
  }
  .tablet-mx-4 {
    margin-left: 16px;
    margin-right: 16px;
  }
  .tablet-my-4 {
    margin-top: 16px;
    margin-bottom: 16px;
  }
  .tablet-ml-4 {
    margin-left: 16px;
  }
  .tablet-ml-4-i {
    margin-left: 16px !important;
  }
  .tablet-mr-4 {
    margin-right: 16px;
  }
  .tablet-mr-4-i {
    margin-right: 16px !important;
  }
  .tablet-mb-4 {
    margin-bottom: 16px;
  }
  .tablet-mt-4 {
    margin-top: 16px;
  }
  .tablet-p-4 {
    padding: 16px;
  }
  .tablet-p-4-i {
    padding: 16px !important;
  }
  .tablet-px-4 {
    padding-left: 16px;
    padding-right: 16px;
  }
  .tablet-px-4-i {
    padding-left: 16px !important;
    padding-right: 16px !important;
  }
  .tablet-py-4 {
    padding-top: 16px;
    padding-bottom: 16px;
  }
  .tablet-pl-4 {
    padding-left: 16px;
  }
  .tablet-pr-4 {
    padding-right: 16px;
  }
  .tablet-pl-4-i {
    padding-left: 16px !important;
  }
  .tablet-pr-4-i {
    padding-right: 16px !important;
  }
  .tablet-pb-4 {
    padding-bottom: 16px;
  }
  .tablet-pb-4-i {
    padding-bottom: 16px !important;
  }
  .tablet-pt-4 {
    padding-top: 16px;
  }
  .tablet-m-5 {
    margin: 20px;
  }
  .tablet-mx-5 {
    margin-left: 20px;
    margin-right: 20px;
  }
  .tablet-my-5 {
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .tablet-ml-5 {
    margin-left: 20px;
  }
  .tablet-ml-5-i {
    margin-left: 20px !important;
  }
  .tablet-mr-5 {
    margin-right: 20px;
  }
  .tablet-mr-5-i {
    margin-right: 20px !important;
  }
  .tablet-mb-5 {
    margin-bottom: 20px;
  }
  .tablet-mt-5 {
    margin-top: 20px;
  }
  .tablet-p-5 {
    padding: 20px;
  }
  .tablet-p-5-i {
    padding: 20px !important;
  }
  .tablet-px-5 {
    padding-left: 20px;
    padding-right: 20px;
  }
  .tablet-px-5-i {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }
  .tablet-py-5 {
    padding-top: 20px;
    padding-bottom: 20px;
  }
  .tablet-pl-5 {
    padding-left: 20px;
  }
  .tablet-pr-5 {
    padding-right: 20px;
  }
  .tablet-pl-5-i {
    padding-left: 20px !important;
  }
  .tablet-pr-5-i {
    padding-right: 20px !important;
  }
  .tablet-pb-5 {
    padding-bottom: 20px;
  }
  .tablet-pb-5-i {
    padding-bottom: 20px !important;
  }
  .tablet-pt-5 {
    padding-top: 20px;
  }
  .tablet-m-6 {
    margin: 24px;
  }
  .tablet-mx-6 {
    margin-left: 24px;
    margin-right: 24px;
  }
  .tablet-my-6 {
    margin-top: 24px;
    margin-bottom: 24px;
  }
  .tablet-ml-6 {
    margin-left: 24px;
  }
  .tablet-ml-6-i {
    margin-left: 24px !important;
  }
  .tablet-mr-6 {
    margin-right: 24px;
  }
  .tablet-mr-6-i {
    margin-right: 24px !important;
  }
  .tablet-mb-6 {
    margin-bottom: 24px;
  }
  .tablet-mt-6 {
    margin-top: 24px;
  }
  .tablet-p-6 {
    padding: 24px;
  }
  .tablet-p-6-i {
    padding: 24px !important;
  }
  .tablet-px-6 {
    padding-left: 24px;
    padding-right: 24px;
  }
  .tablet-px-6-i {
    padding-left: 24px !important;
    padding-right: 24px !important;
  }
  .tablet-py-6 {
    padding-top: 24px;
    padding-bottom: 24px;
  }
  .tablet-pl-6 {
    padding-left: 24px;
  }
  .tablet-pr-6 {
    padding-right: 24px;
  }
  .tablet-pl-6-i {
    padding-left: 24px !important;
  }
  .tablet-pr-6-i {
    padding-right: 24px !important;
  }
  .tablet-pb-6 {
    padding-bottom: 24px;
  }
  .tablet-pb-6-i {
    padding-bottom: 24px !important;
  }
  .tablet-pt-6 {
    padding-top: 24px;
  }
  .tablet-m-7 {
    margin: 28px;
  }
  .tablet-mx-7 {
    margin-left: 28px;
    margin-right: 28px;
  }
  .tablet-my-7 {
    margin-top: 28px;
    margin-bottom: 28px;
  }
  .tablet-ml-7 {
    margin-left: 28px;
  }
  .tablet-ml-7-i {
    margin-left: 28px !important;
  }
  .tablet-mr-7 {
    margin-right: 28px;
  }
  .tablet-mr-7-i {
    margin-right: 28px !important;
  }
  .tablet-mb-7 {
    margin-bottom: 28px;
  }
  .tablet-mt-7 {
    margin-top: 28px;
  }
  .tablet-p-7 {
    padding: 28px;
  }
  .tablet-p-7-i {
    padding: 28px !important;
  }
  .tablet-px-7 {
    padding-left: 28px;
    padding-right: 28px;
  }
  .tablet-px-7-i {
    padding-left: 28px !important;
    padding-right: 28px !important;
  }
  .tablet-py-7 {
    padding-top: 28px;
    padding-bottom: 28px;
  }
  .tablet-pl-7 {
    padding-left: 28px;
  }
  .tablet-pr-7 {
    padding-right: 28px;
  }
  .tablet-pl-7-i {
    padding-left: 28px !important;
  }
  .tablet-pr-7-i {
    padding-right: 28px !important;
  }
  .tablet-pb-7 {
    padding-bottom: 28px;
  }
  .tablet-pb-7-i {
    padding-bottom: 28px !important;
  }
  .tablet-pt-7 {
    padding-top: 28px;
  }
  .tablet-m-8 {
    margin: 32px;
  }
  .tablet-mx-8 {
    margin-left: 32px;
    margin-right: 32px;
  }
  .tablet-my-8 {
    margin-top: 32px;
    margin-bottom: 32px;
  }
  .tablet-ml-8 {
    margin-left: 32px;
  }
  .tablet-ml-8-i {
    margin-left: 32px !important;
  }
  .tablet-mr-8 {
    margin-right: 32px;
  }
  .tablet-mr-8-i {
    margin-right: 32px !important;
  }
  .tablet-mb-8 {
    margin-bottom: 32px;
  }
  .tablet-mt-8 {
    margin-top: 32px;
  }
  .tablet-p-8 {
    padding: 32px;
  }
  .tablet-p-8-i {
    padding: 32px !important;
  }
  .tablet-px-8 {
    padding-left: 32px;
    padding-right: 32px;
  }
  .tablet-px-8-i {
    padding-left: 32px !important;
    padding-right: 32px !important;
  }
  .tablet-py-8 {
    padding-top: 32px;
    padding-bottom: 32px;
  }
  .tablet-pl-8 {
    padding-left: 32px;
  }
  .tablet-pr-8 {
    padding-right: 32px;
  }
  .tablet-pl-8-i {
    padding-left: 32px !important;
  }
  .tablet-pr-8-i {
    padding-right: 32px !important;
  }
  .tablet-pb-8 {
    padding-bottom: 32px;
  }
  .tablet-pb-8-i {
    padding-bottom: 32px !important;
  }
  .tablet-pt-8 {
    padding-top: 32px;
  }
  .tablet-m-9 {
    margin: 36px;
  }
  .tablet-mx-9 {
    margin-left: 36px;
    margin-right: 36px;
  }
  .tablet-my-9 {
    margin-top: 36px;
    margin-bottom: 36px;
  }
  .tablet-ml-9 {
    margin-left: 36px;
  }
  .tablet-ml-9-i {
    margin-left: 36px !important;
  }
  .tablet-mr-9 {
    margin-right: 36px;
  }
  .tablet-mr-9-i {
    margin-right: 36px !important;
  }
  .tablet-mb-9 {
    margin-bottom: 36px;
  }
  .tablet-mt-9 {
    margin-top: 36px;
  }
  .tablet-p-9 {
    padding: 36px;
  }
  .tablet-p-9-i {
    padding: 36px !important;
  }
  .tablet-px-9 {
    padding-left: 36px;
    padding-right: 36px;
  }
  .tablet-px-9-i {
    padding-left: 36px !important;
    padding-right: 36px !important;
  }
  .tablet-py-9 {
    padding-top: 36px;
    padding-bottom: 36px;
  }
  .tablet-pl-9 {
    padding-left: 36px;
  }
  .tablet-pr-9 {
    padding-right: 36px;
  }
  .tablet-pl-9-i {
    padding-left: 36px !important;
  }
  .tablet-pr-9-i {
    padding-right: 36px !important;
  }
  .tablet-pb-9 {
    padding-bottom: 36px;
  }
  .tablet-pb-9-i {
    padding-bottom: 36px !important;
  }
  .tablet-pt-9 {
    padding-top: 36px;
  }
  .tablet-m-10 {
    margin: 40px;
  }
  .tablet-mx-10 {
    margin-left: 40px;
    margin-right: 40px;
  }
  .tablet-my-10 {
    margin-top: 40px;
    margin-bottom: 40px;
  }
  .tablet-ml-10 {
    margin-left: 40px;
  }
  .tablet-ml-10-i {
    margin-left: 40px !important;
  }
  .tablet-mr-10 {
    margin-right: 40px;
  }
  .tablet-mr-10-i {
    margin-right: 40px !important;
  }
  .tablet-mb-10 {
    margin-bottom: 40px;
  }
  .tablet-mt-10 {
    margin-top: 40px;
  }
  .tablet-p-10 {
    padding: 40px;
  }
  .tablet-p-10-i {
    padding: 40px !important;
  }
  .tablet-px-10 {
    padding-left: 40px;
    padding-right: 40px;
  }
  .tablet-px-10-i {
    padding-left: 40px !important;
    padding-right: 40px !important;
  }
  .tablet-py-10 {
    padding-top: 40px;
    padding-bottom: 40px;
  }
  .tablet-pl-10 {
    padding-left: 40px;
  }
  .tablet-pr-10 {
    padding-right: 40px;
  }
  .tablet-pl-10-i {
    padding-left: 40px !important;
  }
  .tablet-pr-10-i {
    padding-right: 40px !important;
  }
  .tablet-pb-10 {
    padding-bottom: 40px;
  }
  .tablet-pb-10-i {
    padding-bottom: 40px !important;
  }
  .tablet-pt-10 {
    padding-top: 40px;
  }
  .tablet-m-11 {
    margin: 44px;
  }
  .tablet-mx-11 {
    margin-left: 44px;
    margin-right: 44px;
  }
  .tablet-my-11 {
    margin-top: 44px;
    margin-bottom: 44px;
  }
  .tablet-ml-11 {
    margin-left: 44px;
  }
  .tablet-ml-11-i {
    margin-left: 44px !important;
  }
  .tablet-mr-11 {
    margin-right: 44px;
  }
  .tablet-mr-11-i {
    margin-right: 44px !important;
  }
  .tablet-mb-11 {
    margin-bottom: 44px;
  }
  .tablet-mt-11 {
    margin-top: 44px;
  }
  .tablet-p-11 {
    padding: 44px;
  }
  .tablet-p-11-i {
    padding: 44px !important;
  }
  .tablet-px-11 {
    padding-left: 44px;
    padding-right: 44px;
  }
  .tablet-px-11-i {
    padding-left: 44px !important;
    padding-right: 44px !important;
  }
  .tablet-py-11 {
    padding-top: 44px;
    padding-bottom: 44px;
  }
  .tablet-pl-11 {
    padding-left: 44px;
  }
  .tablet-pr-11 {
    padding-right: 44px;
  }
  .tablet-pl-11-i {
    padding-left: 44px !important;
  }
  .tablet-pr-11-i {
    padding-right: 44px !important;
  }
  .tablet-pb-11 {
    padding-bottom: 44px;
  }
  .tablet-pb-11-i {
    padding-bottom: 44px !important;
  }
  .tablet-pt-11 {
    padding-top: 44px;
  }
  .tablet-m-12 {
    margin: 48px;
  }
  .tablet-mx-12 {
    margin-left: 48px;
    margin-right: 48px;
  }
  .tablet-my-12 {
    margin-top: 48px;
    margin-bottom: 48px;
  }
  .tablet-ml-12 {
    margin-left: 48px;
  }
  .tablet-ml-12-i {
    margin-left: 48px !important;
  }
  .tablet-mr-12 {
    margin-right: 48px;
  }
  .tablet-mr-12-i {
    margin-right: 48px !important;
  }
  .tablet-mb-12 {
    margin-bottom: 48px;
  }
  .tablet-mt-12 {
    margin-top: 48px;
  }
  .tablet-p-12 {
    padding: 48px;
  }
  .tablet-p-12-i {
    padding: 48px !important;
  }
  .tablet-px-12 {
    padding-left: 48px;
    padding-right: 48px;
  }
  .tablet-px-12-i {
    padding-left: 48px !important;
    padding-right: 48px !important;
  }
  .tablet-py-12 {
    padding-top: 48px;
    padding-bottom: 48px;
  }
  .tablet-pl-12 {
    padding-left: 48px;
  }
  .tablet-pr-12 {
    padding-right: 48px;
  }
  .tablet-pl-12-i {
    padding-left: 48px !important;
  }
  .tablet-pr-12-i {
    padding-right: 48px !important;
  }
  .tablet-pb-12 {
    padding-bottom: 48px;
  }
  .tablet-pb-12-i {
    padding-bottom: 48px !important;
  }
  .tablet-pt-12 {
    padding-top: 48px;
  }
  .tablet-m-13 {
    margin: 52px;
  }
  .tablet-mx-13 {
    margin-left: 52px;
    margin-right: 52px;
  }
  .tablet-my-13 {
    margin-top: 52px;
    margin-bottom: 52px;
  }
  .tablet-ml-13 {
    margin-left: 52px;
  }
  .tablet-ml-13-i {
    margin-left: 52px !important;
  }
  .tablet-mr-13 {
    margin-right: 52px;
  }
  .tablet-mr-13-i {
    margin-right: 52px !important;
  }
  .tablet-mb-13 {
    margin-bottom: 52px;
  }
  .tablet-mt-13 {
    margin-top: 52px;
  }
  .tablet-p-13 {
    padding: 52px;
  }
  .tablet-p-13-i {
    padding: 52px !important;
  }
  .tablet-px-13 {
    padding-left: 52px;
    padding-right: 52px;
  }
  .tablet-px-13-i {
    padding-left: 52px !important;
    padding-right: 52px !important;
  }
  .tablet-py-13 {
    padding-top: 52px;
    padding-bottom: 52px;
  }
  .tablet-pl-13 {
    padding-left: 52px;
  }
  .tablet-pr-13 {
    padding-right: 52px;
  }
  .tablet-pl-13-i {
    padding-left: 52px !important;
  }
  .tablet-pr-13-i {
    padding-right: 52px !important;
  }
  .tablet-pb-13 {
    padding-bottom: 52px;
  }
  .tablet-pb-13-i {
    padding-bottom: 52px !important;
  }
  .tablet-pt-13 {
    padding-top: 52px;
  }
  .tablet-m-14 {
    margin: 56px;
  }
  .tablet-mx-14 {
    margin-left: 56px;
    margin-right: 56px;
  }
  .tablet-my-14 {
    margin-top: 56px;
    margin-bottom: 56px;
  }
  .tablet-ml-14 {
    margin-left: 56px;
  }
  .tablet-ml-14-i {
    margin-left: 56px !important;
  }
  .tablet-mr-14 {
    margin-right: 56px;
  }
  .tablet-mr-14-i {
    margin-right: 56px !important;
  }
  .tablet-mb-14 {
    margin-bottom: 56px;
  }
  .tablet-mt-14 {
    margin-top: 56px;
  }
  .tablet-p-14 {
    padding: 56px;
  }
  .tablet-p-14-i {
    padding: 56px !important;
  }
  .tablet-px-14 {
    padding-left: 56px;
    padding-right: 56px;
  }
  .tablet-px-14-i {
    padding-left: 56px !important;
    padding-right: 56px !important;
  }
  .tablet-py-14 {
    padding-top: 56px;
    padding-bottom: 56px;
  }
  .tablet-pl-14 {
    padding-left: 56px;
  }
  .tablet-pr-14 {
    padding-right: 56px;
  }
  .tablet-pl-14-i {
    padding-left: 56px !important;
  }
  .tablet-pr-14-i {
    padding-right: 56px !important;
  }
  .tablet-pb-14 {
    padding-bottom: 56px;
  }
  .tablet-pb-14-i {
    padding-bottom: 56px !important;
  }
  .tablet-pt-14 {
    padding-top: 56px;
  }
  .tablet-m-15 {
    margin: 60px;
  }
  .tablet-mx-15 {
    margin-left: 60px;
    margin-right: 60px;
  }
  .tablet-my-15 {
    margin-top: 60px;
    margin-bottom: 60px;
  }
  .tablet-ml-15 {
    margin-left: 60px;
  }
  .tablet-ml-15-i {
    margin-left: 60px !important;
  }
  .tablet-mr-15 {
    margin-right: 60px;
  }
  .tablet-mr-15-i {
    margin-right: 60px !important;
  }
  .tablet-mb-15 {
    margin-bottom: 60px;
  }
  .tablet-mt-15 {
    margin-top: 60px;
  }
  .tablet-p-15 {
    padding: 60px;
  }
  .tablet-p-15-i {
    padding: 60px !important;
  }
  .tablet-px-15 {
    padding-left: 60px;
    padding-right: 60px;
  }
  .tablet-px-15-i {
    padding-left: 60px !important;
    padding-right: 60px !important;
  }
  .tablet-py-15 {
    padding-top: 60px;
    padding-bottom: 60px;
  }
  .tablet-pl-15 {
    padding-left: 60px;
  }
  .tablet-pr-15 {
    padding-right: 60px;
  }
  .tablet-pl-15-i {
    padding-left: 60px !important;
  }
  .tablet-pr-15-i {
    padding-right: 60px !important;
  }
  .tablet-pb-15 {
    padding-bottom: 60px;
  }
  .tablet-pb-15-i {
    padding-bottom: 60px !important;
  }
  .tablet-pt-15 {
    padding-top: 60px;
  }
  .tablet-m-16 {
    margin: 64px;
  }
  .tablet-mx-16 {
    margin-left: 64px;
    margin-right: 64px;
  }
  .tablet-my-16 {
    margin-top: 64px;
    margin-bottom: 64px;
  }
  .tablet-ml-16 {
    margin-left: 64px;
  }
  .tablet-ml-16-i {
    margin-left: 64px !important;
  }
  .tablet-mr-16 {
    margin-right: 64px;
  }
  .tablet-mr-16-i {
    margin-right: 64px !important;
  }
  .tablet-mb-16 {
    margin-bottom: 64px;
  }
  .tablet-mt-16 {
    margin-top: 64px;
  }
  .tablet-p-16 {
    padding: 64px;
  }
  .tablet-p-16-i {
    padding: 64px !important;
  }
  .tablet-px-16 {
    padding-left: 64px;
    padding-right: 64px;
  }
  .tablet-px-16-i {
    padding-left: 64px !important;
    padding-right: 64px !important;
  }
  .tablet-py-16 {
    padding-top: 64px;
    padding-bottom: 64px;
  }
  .tablet-pl-16 {
    padding-left: 64px;
  }
  .tablet-pr-16 {
    padding-right: 64px;
  }
  .tablet-pl-16-i {
    padding-left: 64px !important;
  }
  .tablet-pr-16-i {
    padding-right: 64px !important;
  }
  .tablet-pb-16 {
    padding-bottom: 64px;
  }
  .tablet-pb-16-i {
    padding-bottom: 64px !important;
  }
  .tablet-pt-16 {
    padding-top: 64px;
  }
  .tablet-m-17 {
    margin: 68px;
  }
  .tablet-mx-17 {
    margin-left: 68px;
    margin-right: 68px;
  }
  .tablet-my-17 {
    margin-top: 68px;
    margin-bottom: 68px;
  }
  .tablet-ml-17 {
    margin-left: 68px;
  }
  .tablet-ml-17-i {
    margin-left: 68px !important;
  }
  .tablet-mr-17 {
    margin-right: 68px;
  }
  .tablet-mr-17-i {
    margin-right: 68px !important;
  }
  .tablet-mb-17 {
    margin-bottom: 68px;
  }
  .tablet-mt-17 {
    margin-top: 68px;
  }
  .tablet-p-17 {
    padding: 68px;
  }
  .tablet-p-17-i {
    padding: 68px !important;
  }
  .tablet-px-17 {
    padding-left: 68px;
    padding-right: 68px;
  }
  .tablet-px-17-i {
    padding-left: 68px !important;
    padding-right: 68px !important;
  }
  .tablet-py-17 {
    padding-top: 68px;
    padding-bottom: 68px;
  }
  .tablet-pl-17 {
    padding-left: 68px;
  }
  .tablet-pr-17 {
    padding-right: 68px;
  }
  .tablet-pl-17-i {
    padding-left: 68px !important;
  }
  .tablet-pr-17-i {
    padding-right: 68px !important;
  }
  .tablet-pb-17 {
    padding-bottom: 68px;
  }
  .tablet-pb-17-i {
    padding-bottom: 68px !important;
  }
  .tablet-pt-17 {
    padding-top: 68px;
  }
  .tablet-m-18 {
    margin: 72px;
  }
  .tablet-mx-18 {
    margin-left: 72px;
    margin-right: 72px;
  }
  .tablet-my-18 {
    margin-top: 72px;
    margin-bottom: 72px;
  }
  .tablet-ml-18 {
    margin-left: 72px;
  }
  .tablet-ml-18-i {
    margin-left: 72px !important;
  }
  .tablet-mr-18 {
    margin-right: 72px;
  }
  .tablet-mr-18-i {
    margin-right: 72px !important;
  }
  .tablet-mb-18 {
    margin-bottom: 72px;
  }
  .tablet-mt-18 {
    margin-top: 72px;
  }
  .tablet-p-18 {
    padding: 72px;
  }
  .tablet-p-18-i {
    padding: 72px !important;
  }
  .tablet-px-18 {
    padding-left: 72px;
    padding-right: 72px;
  }
  .tablet-px-18-i {
    padding-left: 72px !important;
    padding-right: 72px !important;
  }
  .tablet-py-18 {
    padding-top: 72px;
    padding-bottom: 72px;
  }
  .tablet-pl-18 {
    padding-left: 72px;
  }
  .tablet-pr-18 {
    padding-right: 72px;
  }
  .tablet-pl-18-i {
    padding-left: 72px !important;
  }
  .tablet-pr-18-i {
    padding-right: 72px !important;
  }
  .tablet-pb-18 {
    padding-bottom: 72px;
  }
  .tablet-pb-18-i {
    padding-bottom: 72px !important;
  }
  .tablet-pt-18 {
    padding-top: 72px;
  }
  .tablet-m-19 {
    margin: 76px;
  }
  .tablet-mx-19 {
    margin-left: 76px;
    margin-right: 76px;
  }
  .tablet-my-19 {
    margin-top: 76px;
    margin-bottom: 76px;
  }
  .tablet-ml-19 {
    margin-left: 76px;
  }
  .tablet-ml-19-i {
    margin-left: 76px !important;
  }
  .tablet-mr-19 {
    margin-right: 76px;
  }
  .tablet-mr-19-i {
    margin-right: 76px !important;
  }
  .tablet-mb-19 {
    margin-bottom: 76px;
  }
  .tablet-mt-19 {
    margin-top: 76px;
  }
  .tablet-p-19 {
    padding: 76px;
  }
  .tablet-p-19-i {
    padding: 76px !important;
  }
  .tablet-px-19 {
    padding-left: 76px;
    padding-right: 76px;
  }
  .tablet-px-19-i {
    padding-left: 76px !important;
    padding-right: 76px !important;
  }
  .tablet-py-19 {
    padding-top: 76px;
    padding-bottom: 76px;
  }
  .tablet-pl-19 {
    padding-left: 76px;
  }
  .tablet-pr-19 {
    padding-right: 76px;
  }
  .tablet-pl-19-i {
    padding-left: 76px !important;
  }
  .tablet-pr-19-i {
    padding-right: 76px !important;
  }
  .tablet-pb-19 {
    padding-bottom: 76px;
  }
  .tablet-pb-19-i {
    padding-bottom: 76px !important;
  }
  .tablet-pt-19 {
    padding-top: 76px;
  }
  .tablet-m-20 {
    margin: 80px;
  }
  .tablet-mx-20 {
    margin-left: 80px;
    margin-right: 80px;
  }
  .tablet-my-20 {
    margin-top: 80px;
    margin-bottom: 80px;
  }
  .tablet-ml-20 {
    margin-left: 80px;
  }
  .tablet-ml-20-i {
    margin-left: 80px !important;
  }
  .tablet-mr-20 {
    margin-right: 80px;
  }
  .tablet-mr-20-i {
    margin-right: 80px !important;
  }
  .tablet-mb-20 {
    margin-bottom: 80px;
  }
  .tablet-mt-20 {
    margin-top: 80px;
  }
  .tablet-p-20 {
    padding: 80px;
  }
  .tablet-p-20-i {
    padding: 80px !important;
  }
  .tablet-px-20 {
    padding-left: 80px;
    padding-right: 80px;
  }
  .tablet-px-20-i {
    padding-left: 80px !important;
    padding-right: 80px !important;
  }
  .tablet-py-20 {
    padding-top: 80px;
    padding-bottom: 80px;
  }
  .tablet-pl-20 {
    padding-left: 80px;
  }
  .tablet-pr-20 {
    padding-right: 80px;
  }
  .tablet-pl-20-i {
    padding-left: 80px !important;
  }
  .tablet-pr-20-i {
    padding-right: 80px !important;
  }
  .tablet-pb-20 {
    padding-bottom: 80px;
  }
  .tablet-pb-20-i {
    padding-bottom: 80px !important;
  }
  .tablet-pt-20 {
    padding-top: 80px;
  }
  .tablet-m-25 {
    margin: 100px;
  }
  .tablet-mx-25 {
    margin-left: 100px;
    margin-right: 100px;
  }
  .tablet-my-25 {
    margin-top: 100px;
    margin-bottom: 100px;
  }
  .tablet-ml-25 {
    margin-left: 100px;
  }
  .tablet-ml-25-i {
    margin-left: 100px !important;
  }
  .tablet-mr-25 {
    margin-right: 100px;
  }
  .tablet-mr-25-i {
    margin-right: 100px !important;
  }
  .tablet-mb-25 {
    margin-bottom: 100px;
  }
  .tablet-mt-25 {
    margin-top: 100px;
  }
  .tablet-p-25 {
    padding: 100px;
  }
  .tablet-p-25-i {
    padding: 100px !important;
  }
  .tablet-px-25 {
    padding-left: 100px;
    padding-right: 100px;
  }
  .tablet-px-25-i {
    padding-left: 100px !important;
    padding-right: 100px !important;
  }
  .tablet-py-25 {
    padding-top: 100px;
    padding-bottom: 100px;
  }
  .tablet-pl-25 {
    padding-left: 100px;
  }
  .tablet-pr-25 {
    padding-right: 100px;
  }
  .tablet-pl-25-i {
    padding-left: 100px !important;
  }
  .tablet-pr-25-i {
    padding-right: 100px !important;
  }
  .tablet-pb-25 {
    padding-bottom: 100px;
  }
  .tablet-pb-25-i {
    padding-bottom: 100px !important;
  }
  .tablet-pt-25 {
    padding-top: 100px;
  }
  .tablet-m-30 {
    margin: 120px;
  }
  .tablet-mx-30 {
    margin-left: 120px;
    margin-right: 120px;
  }
  .tablet-my-30 {
    margin-top: 120px;
    margin-bottom: 120px;
  }
  .tablet-ml-30 {
    margin-left: 120px;
  }
  .tablet-ml-30-i {
    margin-left: 120px !important;
  }
  .tablet-mr-30 {
    margin-right: 120px;
  }
  .tablet-mr-30-i {
    margin-right: 120px !important;
  }
  .tablet-mb-30 {
    margin-bottom: 120px;
  }
  .tablet-mt-30 {
    margin-top: 120px;
  }
  .tablet-p-30 {
    padding: 120px;
  }
  .tablet-p-30-i {
    padding: 120px !important;
  }
  .tablet-px-30 {
    padding-left: 120px;
    padding-right: 120px;
  }
  .tablet-px-30-i {
    padding-left: 120px !important;
    padding-right: 120px !important;
  }
  .tablet-py-30 {
    padding-top: 120px;
    padding-bottom: 120px;
  }
  .tablet-pl-30 {
    padding-left: 120px;
  }
  .tablet-pr-30 {
    padding-right: 120px;
  }
  .tablet-pl-30-i {
    padding-left: 120px !important;
  }
  .tablet-pr-30-i {
    padding-right: 120px !important;
  }
  .tablet-pb-30 {
    padding-bottom: 120px;
  }
  .tablet-pb-30-i {
    padding-bottom: 120px !important;
  }
  .tablet-pt-30 {
    padding-top: 120px;
  }
  .tablet-m-35 {
    margin: 140px;
  }
  .tablet-mx-35 {
    margin-left: 140px;
    margin-right: 140px;
  }
  .tablet-my-35 {
    margin-top: 140px;
    margin-bottom: 140px;
  }
  .tablet-ml-35 {
    margin-left: 140px;
  }
  .tablet-ml-35-i {
    margin-left: 140px !important;
  }
  .tablet-mr-35 {
    margin-right: 140px;
  }
  .tablet-mr-35-i {
    margin-right: 140px !important;
  }
  .tablet-mb-35 {
    margin-bottom: 140px;
  }
  .tablet-mt-35 {
    margin-top: 140px;
  }
  .tablet-p-35 {
    padding: 140px;
  }
  .tablet-p-35-i {
    padding: 140px !important;
  }
  .tablet-px-35 {
    padding-left: 140px;
    padding-right: 140px;
  }
  .tablet-px-35-i {
    padding-left: 140px !important;
    padding-right: 140px !important;
  }
  .tablet-py-35 {
    padding-top: 140px;
    padding-bottom: 140px;
  }
  .tablet-pl-35 {
    padding-left: 140px;
  }
  .tablet-pr-35 {
    padding-right: 140px;
  }
  .tablet-pl-35-i {
    padding-left: 140px !important;
  }
  .tablet-pr-35-i {
    padding-right: 140px !important;
  }
  .tablet-pb-35 {
    padding-bottom: 140px;
  }
  .tablet-pb-35-i {
    padding-bottom: 140px !important;
  }
  .tablet-pt-35 {
    padding-top: 140px;
  }
  .tablet-m-50 {
    margin: 200px;
  }
  .tablet-mx-50 {
    margin-left: 200px;
    margin-right: 200px;
  }
  .tablet-my-50 {
    margin-top: 200px;
    margin-bottom: 200px;
  }
  .tablet-ml-50 {
    margin-left: 200px;
  }
  .tablet-ml-50-i {
    margin-left: 200px !important;
  }
  .tablet-mr-50 {
    margin-right: 200px;
  }
  .tablet-mr-50-i {
    margin-right: 200px !important;
  }
  .tablet-mb-50 {
    margin-bottom: 200px;
  }
  .tablet-mt-50 {
    margin-top: 200px;
  }
  .tablet-p-50 {
    padding: 200px;
  }
  .tablet-p-50-i {
    padding: 200px !important;
  }
  .tablet-px-50 {
    padding-left: 200px;
    padding-right: 200px;
  }
  .tablet-px-50-i {
    padding-left: 200px !important;
    padding-right: 200px !important;
  }
  .tablet-py-50 {
    padding-top: 200px;
    padding-bottom: 200px;
  }
  .tablet-pl-50 {
    padding-left: 200px;
  }
  .tablet-pr-50 {
    padding-right: 200px;
  }
  .tablet-pl-50-i {
    padding-left: 200px !important;
  }
  .tablet-pr-50-i {
    padding-right: 200px !important;
  }
  .tablet-pb-50 {
    padding-bottom: 200px;
  }
  .tablet-pb-50-i {
    padding-bottom: 200px !important;
  }
  .tablet-pt-50 {
    padding-top: 200px;
  }
  .tablet-m-65 {
    margin: 260px;
  }
  .tablet-mx-65 {
    margin-left: 260px;
    margin-right: 260px;
  }
  .tablet-my-65 {
    margin-top: 260px;
    margin-bottom: 260px;
  }
  .tablet-ml-65 {
    margin-left: 260px;
  }
  .tablet-ml-65-i {
    margin-left: 260px !important;
  }
  .tablet-mr-65 {
    margin-right: 260px;
  }
  .tablet-mr-65-i {
    margin-right: 260px !important;
  }
  .tablet-mb-65 {
    margin-bottom: 260px;
  }
  .tablet-mt-65 {
    margin-top: 260px;
  }
  .tablet-p-65 {
    padding: 260px;
  }
  .tablet-p-65-i {
    padding: 260px !important;
  }
  .tablet-px-65 {
    padding-left: 260px;
    padding-right: 260px;
  }
  .tablet-px-65-i {
    padding-left: 260px !important;
    padding-right: 260px !important;
  }
  .tablet-py-65 {
    padding-top: 260px;
    padding-bottom: 260px;
  }
  .tablet-pl-65 {
    padding-left: 260px;
  }
  .tablet-pr-65 {
    padding-right: 260px;
  }
  .tablet-pl-65-i {
    padding-left: 260px !important;
  }
  .tablet-pr-65-i {
    padding-right: 260px !important;
  }
  .tablet-pb-65 {
    padding-bottom: 260px;
  }
  .tablet-pb-65-i {
    padding-bottom: 260px !important;
  }
  .tablet-pt-65 {
    padding-top: 260px;
  }
  .tablet-m-85 {
    margin: 340px;
  }
  .tablet-mx-85 {
    margin-left: 340px;
    margin-right: 340px;
  }
  .tablet-my-85 {
    margin-top: 340px;
    margin-bottom: 340px;
  }
  .tablet-ml-85 {
    margin-left: 340px;
  }
  .tablet-ml-85-i {
    margin-left: 340px !important;
  }
  .tablet-mr-85 {
    margin-right: 340px;
  }
  .tablet-mr-85-i {
    margin-right: 340px !important;
  }
  .tablet-mb-85 {
    margin-bottom: 340px;
  }
  .tablet-mt-85 {
    margin-top: 340px;
  }
  .tablet-p-85 {
    padding: 340px;
  }
  .tablet-p-85-i {
    padding: 340px !important;
  }
  .tablet-px-85 {
    padding-left: 340px;
    padding-right: 340px;
  }
  .tablet-px-85-i {
    padding-left: 340px !important;
    padding-right: 340px !important;
  }
  .tablet-py-85 {
    padding-top: 340px;
    padding-bottom: 340px;
  }
  .tablet-pl-85 {
    padding-left: 340px;
  }
  .tablet-pr-85 {
    padding-right: 340px;
  }
  .tablet-pl-85-i {
    padding-left: 340px !important;
  }
  .tablet-pr-85-i {
    padding-right: 340px !important;
  }
  .tablet-pb-85 {
    padding-bottom: 340px;
  }
  .tablet-pb-85-i {
    padding-bottom: 340px !important;
  }
  .tablet-pt-85 {
    padding-top: 340px;
  }
  .tablet-m-100 {
    margin: 400px;
  }
  .tablet-mx-100 {
    margin-left: 400px;
    margin-right: 400px;
  }
  .tablet-my-100 {
    margin-top: 400px;
    margin-bottom: 400px;
  }
  .tablet-ml-100 {
    margin-left: 400px;
  }
  .tablet-ml-100-i {
    margin-left: 400px !important;
  }
  .tablet-mr-100 {
    margin-right: 400px;
  }
  .tablet-mr-100-i {
    margin-right: 400px !important;
  }
  .tablet-mb-100 {
    margin-bottom: 400px;
  }
  .tablet-mt-100 {
    margin-top: 400px;
  }
  .tablet-p-100 {
    padding: 400px;
  }
  .tablet-p-100-i {
    padding: 400px !important;
  }
  .tablet-px-100 {
    padding-left: 400px;
    padding-right: 400px;
  }
  .tablet-px-100-i {
    padding-left: 400px !important;
    padding-right: 400px !important;
  }
  .tablet-py-100 {
    padding-top: 400px;
    padding-bottom: 400px;
  }
  .tablet-pl-100 {
    padding-left: 400px;
  }
  .tablet-pr-100 {
    padding-right: 400px;
  }
  .tablet-pl-100-i {
    padding-left: 400px !important;
  }
  .tablet-pr-100-i {
    padding-right: 400px !important;
  }
  .tablet-pb-100 {
    padding-bottom: 400px;
  }
  .tablet-pb-100-i {
    padding-bottom: 400px !important;
  }
  .tablet-pt-100 {
    padding-top: 400px;
  }
  .tablet-d-flex {
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flexbox;
    display: -ms-flex;
    display: flex;
  }
  .tablet-d-flex-i {
    display: -webkit-flex !important;
    display: -moz-flex !important;
    display: -ms-flex !importantbox;
    display: -ms-flex !important;
    display: flex !important;
  }
  .tablet-d-block {
    display: -webkit-block;
    display: -moz-block;
    display: -ms-blockbox;
    display: -ms-block;
    display: block;
  }
  .tablet-d-grid {
    display: -webkit-grid;
    display: -moz-grid;
    display: -ms-gridbox;
    display: -ms-grid;
    display: grid;
  }
  .tablet-d-inline {
    display: -webkit-inline-flex;
    display: -moz-inline-flex;
    display: -ms-inline-flexbox;
    display: -ms-inline-flex;
    display: inline-flex;
  }
  .tablet-d-none {
    display: -webkit-none;
    display: -moz-none;
    display: -ms-nonebox;
    display: -ms-none;
    display: none;
  }
  .tablet-direction-row {
    -webkit-flex-direction: row;
    -moz-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
  }
  .tablet-directionrow-reverse {
    -webkit-flex-direction: row-reverse;
    -moz-flex-direction: row-reverse;
    -ms-flex-direction: row-reverse;
    flex-direction: row-reverse;
  }
  .tablet-direction-column {
    -webkit-flex-direction: column;
    -moz-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .tablet-direction-column-reverse {
    -webkit-flex-direction: column-reverse;
    -moz-flex-direction: column-reverse;
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse;
  }
  .tablet-wrap-wrap {
    -webkit-flex-wrap: wrap;
    -moz-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
  .tablet-wrap-nowrap {
    -webkit-flex-wrap: nowrap;
    -moz-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
  }
  .tablet-wrap-reverse {
    -webkit-flex-wrap: wrap-reverse;
    -moz-flex-wrap: wrap-reverse;
    -ms-flex-wrap: wrap-reverse;
    flex-wrap: wrap-reverse;
  }
  .tablet-justify-flex-start {
    -webkit-justify-content: flex-start;
    -moz-justify-content: flex-start;
    -ms-justify-content: flex-start;
    justify-content: flex-start;
  }
  .tablet-justify-flex-end {
    -webkit-justify-content: flex-end;
    -moz-justify-content: flex-end;
    -ms-justify-content: flex-end;
    justify-content: flex-end;
  }
  .tablet-justify-center {
    -webkit-justify-content: center;
    -moz-justify-content: center;
    -ms-justify-content: center;
    justify-content: center;
  }
  .tablet-justify-space-between {
    -webkit-justify-content: space-between;
    -moz-justify-content: space-between;
    -ms-justify-content: space-between;
    justify-content: space-between;
  }
  .tablet-justify-space-around {
    -webkit-justify-content: space-around;
    -moz-justify-content: space-around;
    -ms-justify-content: space-around;
    justify-content: space-around;
  }
  .tablet-justify-self-flex-start {
    -webkit-justify-self: flex-start;
    -moz-justify-self: flex-start;
    -ms-justify-self: flex-start;
    justify-self: flex-start;
  }
  .tablet-justify-self-flex-end {
    -webkit-justify-self: flex-end;
    -moz-justify-self: flex-end;
    -ms-justify-self: flex-end;
    justify-self: flex-end;
  }
  .tablet-justify-self-center {
    -webkit-justify-self: center;
    -moz-justify-self: center;
    -ms-justify-self: center;
    justify-self: center;
  }
  .tablet-justify-self-space-between {
    -webkit-justify-self: space-between;
    -moz-justify-self: space-between;
    -ms-justify-self: space-between;
    justify-self: space-between;
  }
  .tablet-justify-self-space-around {
    -webkit-justify-self: space-around;
    -moz-justify-self: space-around;
    -ms-justify-self: space-around;
    justify-self: space-around;
  }
  .tablet-align-content-flex-start {
    -webkit-align-content: flex-start;
    -moz-align-content: flex-start;
    -ms-align-content: flex-start;
    align-content: flex-start;
  }
  .tablet-align-content-flex-end {
    -webkit-align-content: flex-end;
    -moz-align-content: flex-end;
    -ms-align-content: flex-end;
    align-content: flex-end;
  }
  .tablet-align-content-center {
    -webkit-align-content: center;
    -moz-align-content: center;
    -ms-align-content: center;
    align-content: center;
  }
  .tablet-align-content-space-between {
    -webkit-align-content: space-between;
    -moz-align-content: space-between;
    -ms-align-content: space-between;
    align-content: space-between;
  }
  .tablet-align-content-space-around {
    -webkit-align-content: space-around;
    -moz-align-content: space-around;
    -ms-align-content: space-around;
    align-content: space-around;
  }
  .tablet-align-content-stretch {
    -webkit-align-content: stretch;
    -moz-align-content: stretch;
    -ms-align-content: stretch;
    align-content: stretch;
  }
  .tablet-align-items-flex-start {
    -webkit-align-items: flex-start;
    -moz-align-items: flex-start;
    -ms-align-items: flex-start;
    align-items: flex-start;
  }
  .tablet-align-items-flex-end {
    -webkit-align-items: flex-end;
    -moz-align-items: flex-end;
    -ms-align-items: flex-end;
    align-items: flex-end;
  }
  .tablet-align-items-center {
    -webkit-align-items: center;
    -moz-align-items: center;
    -ms-align-items: center;
    align-items: center;
  }
  .tablet-align-items-baseline {
    -webkit-align-items: baseline;
    -moz-align-items: baseline;
    -ms-align-items: baseline;
    align-items: baseline;
  }
  .tablet-align-items-stretch {
    -webkit-align-items: stretch;
    -moz-align-items: stretch;
    -ms-align-items: stretch;
    align-items: stretch;
  }
  .tablet-order-0 {
    -webkit-order: 0;
    -moz-order: 0;
    -ms-order: 0;
    order: 0;
  }
  .tablet-order-1 {
    -webkit-order: 1;
    -moz-order: 1;
    -ms-order: 1;
    order: 1;
  }
  .tablet-order-2 {
    -webkit-order: 2;
    -moz-order: 2;
    -ms-order: 2;
    order: 2;
  }
  .tablet-order-3 {
    -webkit-order: 3;
    -moz-order: 3;
    -ms-order: 3;
    order: 3;
  }
  .tablet-order-4 {
    -webkit-order: 4;
    -moz-order: 4;
    -ms-order: 4;
    order: 4;
  }
  .tablet-order-5 {
    -webkit-order: 5;
    -moz-order: 5;
    -ms-order: 5;
    order: 5;
  }
  .tablet-order-6 {
    -webkit-order: 6;
    -moz-order: 6;
    -ms-order: 6;
    order: 6;
  }
  .tablet-order-7 {
    -webkit-order: 7;
    -moz-order: 7;
    -ms-order: 7;
    order: 7;
  }
  .tablet-order-8 {
    -webkit-order: 8;
    -moz-order: 8;
    -ms-order: 8;
    order: 8;
  }
  .tablet-order-9 {
    -webkit-order: 9;
    -moz-order: 9;
    -ms-order: 9;
    order: 9;
  }
  .tablet-order-10 {
    -webkit-order: 10;
    -moz-order: 10;
    -ms-order: 10;
    order: 10;
  }
  .tablet-grow-0 {
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-grow: 0;
    flex-grow: 0;
  }
  .tablet-grow-1 {
    -webkit-flex-grow: 1;
    -moz-flex-grow: 1;
    -ms-flex-grow: 1;
    flex-grow: 1;
  }
  .tablet-grow-2 {
    -webkit-flex-grow: 2;
    -moz-flex-grow: 2;
    -ms-flex-grow: 2;
    flex-grow: 2;
  }
  .tablet-grow-3 {
    -webkit-flex-grow: 3;
    -moz-flex-grow: 3;
    -ms-flex-grow: 3;
    flex-grow: 3;
  }
  .tablet-grow-4 {
    -webkit-flex-grow: 4;
    -moz-flex-grow: 4;
    -ms-flex-grow: 4;
    flex-grow: 4;
  }
  .tablet-grow-5 {
    -webkit-flex-grow: 5;
    -moz-flex-grow: 5;
    -ms-flex-grow: 5;
    flex-grow: 5;
  }
  .tablet-grow-6 {
    -webkit-flex-grow: 6;
    -moz-flex-grow: 6;
    -ms-flex-grow: 6;
    flex-grow: 6;
  }
  .tablet-grow-7 {
    -webkit-flex-grow: 7;
    -moz-flex-grow: 7;
    -ms-flex-grow: 7;
    flex-grow: 7;
  }
  .tablet-grow-8 {
    -webkit-flex-grow: 8;
    -moz-flex-grow: 8;
    -ms-flex-grow: 8;
    flex-grow: 8;
  }
  .tablet-grow-9 {
    -webkit-flex-grow: 9;
    -moz-flex-grow: 9;
    -ms-flex-grow: 9;
    flex-grow: 9;
  }
  .tablet-grow-10 {
    -webkit-flex-grow: 10;
    -moz-flex-grow: 10;
    -ms-flex-grow: 10;
    flex-grow: 10;
  }
  .tablet-shrink-0 {
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-shrink: 0;
    flex-shrink: 0;
  }
  .tablet-shrink-1 {
    -webkit-flex-shrink: 1;
    -moz-flex-shrink: 1;
    -ms-flex-shrink: 1;
    flex-shrink: 1;
  }
  .tablet-shrink-2 {
    -webkit-flex-shrink: 2;
    -moz-flex-shrink: 2;
    -ms-flex-shrink: 2;
    flex-shrink: 2;
  }
  .tablet-shrink-3 {
    -webkit-flex-shrink: 3;
    -moz-flex-shrink: 3;
    -ms-flex-shrink: 3;
    flex-shrink: 3;
  }
  .tablet-shrink-4 {
    -webkit-flex-shrink: 4;
    -moz-flex-shrink: 4;
    -ms-flex-shrink: 4;
    flex-shrink: 4;
  }
  .tablet-shrink-5 {
    -webkit-flex-shrink: 5;
    -moz-flex-shrink: 5;
    -ms-flex-shrink: 5;
    flex-shrink: 5;
  }
  .tablet-shrink-6 {
    -webkit-flex-shrink: 6;
    -moz-flex-shrink: 6;
    -ms-flex-shrink: 6;
    flex-shrink: 6;
  }
  .tablet-shrink-7 {
    -webkit-flex-shrink: 7;
    -moz-flex-shrink: 7;
    -ms-flex-shrink: 7;
    flex-shrink: 7;
  }
  .tablet-shrink-8 {
    -webkit-flex-shrink: 8;
    -moz-flex-shrink: 8;
    -ms-flex-shrink: 8;
    flex-shrink: 8;
  }
  .tablet-shrink-9 {
    -webkit-flex-shrink: 9;
    -moz-flex-shrink: 9;
    -ms-flex-shrink: 9;
    flex-shrink: 9;
  }
  .tablet-shrink-10 {
    -webkit-flex-shrink: 10;
    -moz-flex-shrink: 10;
    -ms-flex-shrink: 10;
    flex-shrink: 10;
  }
  .tablet-align-self-flex-start {
    -webkit-align-self: flex-start;
    -moz-align-self: flex-start;
    -ms-align-self: flex-start;
    align-self: flex-start;
  }
  .tablet-align-self-flex-end {
    -webkit-align-self: flex-end;
    -moz-align-self: flex-end;
    -ms-align-self: flex-end;
    align-self: flex-end;
  }
  .tablet-align-self-center {
    -webkit-align-self: center;
    -moz-align-self: center;
    -ms-align-self: center;
    align-self: center;
  }
  .tablet-align-self-baseline {
    -webkit-align-self: baseline;
    -moz-align-self: baseline;
    -ms-align-self: baseline;
    align-self: baseline;
  }
  .tablet-align-self-stretch {
    -webkit-align-self: stretch;
    -moz-align-self: stretch;
    -ms-align-self: stretch;
    align-self: stretch;
  }
}
@media (min-width: 1201px) {
  .desktop-icon-size-0 {
    font-size: 0px;
    line-height: 0px;
    height: 0px !important;
    width: 0px !important;
  }
  .desktop-icon-size-1 {
    font-size: 2px;
    line-height: 2px;
    height: 2px !important;
    width: 2px !important;
  }
  .desktop-icon-size-2 {
    font-size: 4px;
    line-height: 4px;
    height: 4px !important;
    width: 4px !important;
  }
  .desktop-icon-size-3 {
    font-size: 6px;
    line-height: 6px;
    height: 6px !important;
    width: 6px !important;
  }
  .desktop-icon-size-4 {
    font-size: 8px;
    line-height: 8px;
    height: 8px !important;
    width: 8px !important;
  }
  .desktop-icon-size-5 {
    font-size: 10px;
    line-height: 10px;
    height: 10px !important;
    width: 10px !important;
  }
  .desktop-icon-size-6 {
    font-size: 12px;
    line-height: 12px;
    height: 12px !important;
    width: 12px !important;
  }
  .desktop-icon-size-7 {
    font-size: 14px;
    line-height: 14px;
    height: 14px !important;
    width: 14px !important;
  }
  .desktop-icon-size-8 {
    font-size: 16px;
    line-height: 16px;
    height: 16px !important;
    width: 16px !important;
  }
  .desktop-icon-size-9 {
    font-size: 18px;
    line-height: 18px;
    height: 18px !important;
    width: 18px !important;
  }
  .desktop-icon-size-10 {
    font-size: 20px;
    line-height: 20px;
    height: 20px !important;
    width: 20px !important;
  }
  .desktop-icon-size-11 {
    font-size: 22px;
    line-height: 22px;
    height: 22px !important;
    width: 22px !important;
  }
  .desktop-icon-size-12 {
    font-size: 24px;
    line-height: 24px;
    height: 24px !important;
    width: 24px !important;
  }
  .desktop-icon-size-13 {
    font-size: 26px;
    line-height: 26px;
    height: 26px !important;
    width: 26px !important;
  }
  .desktop-icon-size-14 {
    font-size: 28px;
    line-height: 28px;
    height: 28px !important;
    width: 28px !important;
  }
  .desktop-icon-size-15 {
    font-size: 30px;
    line-height: 30px;
    height: 30px !important;
    width: 30px !important;
  }
  .desktop-icon-size-16 {
    font-size: 32px;
    line-height: 32px;
    height: 32px !important;
    width: 32px !important;
  }
  .desktop-icon-size-17 {
    font-size: 34px;
    line-height: 34px;
    height: 34px !important;
    width: 34px !important;
  }
  .desktop-icon-size-18 {
    font-size: 36px;
    line-height: 36px;
    height: 36px !important;
    width: 36px !important;
  }
  .desktop-icon-size-19 {
    font-size: 38px;
    line-height: 38px;
    height: 38px !important;
    width: 38px !important;
  }
  .desktop-icon-size-20 {
    font-size: 40px;
    line-height: 40px;
    height: 40px !important;
    width: 40px !important;
  }
  .desktop-icon-size-25 {
    font-size: 50px;
    line-height: 50px;
    height: 50px !important;
    width: 50px !important;
  }
  .desktop-icon-size-30 {
    font-size: 60px;
    line-height: 60px;
    height: 60px !important;
    width: 60px !important;
  }
  .desktop-icon-size-35 {
    font-size: 70px;
    line-height: 70px;
    height: 70px !important;
    width: 70px !important;
  }
  .desktop-icon-size-50 {
    font-size: 100px;
    line-height: 100px;
    height: 100px !important;
    width: 100px !important;
  }
  .desktop-icon-size-65 {
    font-size: 130px;
    line-height: 130px;
    height: 130px !important;
    width: 130px !important;
  }
  .desktop-icon-size-85 {
    font-size: 170px;
    line-height: 170px;
    height: 170px !important;
    width: 170px !important;
  }
  .desktop-icon-size-100 {
    font-size: 200px;
    line-height: 200px;
    height: 200px !important;
    width: 200px !important;
  }
  .desktop-text-size-0 {
    font-size: 0px;
  }
  .desktop-text-size-1 {
    font-size: 2px;
  }
  .desktop-text-size-2 {
    font-size: 4px;
  }
  .desktop-text-size-3 {
    font-size: 6px;
  }
  .desktop-text-size-4 {
    font-size: 8px;
  }
  .desktop-text-size-5 {
    font-size: 10px;
  }
  .desktop-text-size-6 {
    font-size: 12px;
  }
  .desktop-text-size-7 {
    font-size: 14px;
  }
  .desktop-text-size-8 {
    font-size: 16px;
  }
  .desktop-text-size-9 {
    font-size: 18px;
  }
  .desktop-text-size-10 {
    font-size: 20px;
  }
  .desktop-text-size-11 {
    font-size: 22px;
  }
  .desktop-text-size-12 {
    font-size: 24px;
  }
  .desktop-text-size-13 {
    font-size: 26px;
  }
  .desktop-text-size-14 {
    font-size: 28px;
  }
  .desktop-text-size-15 {
    font-size: 30px;
  }
  .desktop-text-size-16 {
    font-size: 32px;
  }
  .desktop-text-size-17 {
    font-size: 34px;
  }
  .desktop-text-size-18 {
    font-size: 36px;
  }
  .desktop-text-size-19 {
    font-size: 38px;
  }
  .desktop-text-size-20 {
    font-size: 40px;
  }
  .desktop-text-size-25 {
    font-size: 50px;
  }
  .desktop-text-size-30 {
    font-size: 60px;
  }
  .desktop-text-size-35 {
    font-size: 70px;
  }
  .desktop-text-size-50 {
    font-size: 100px;
  }
  .desktop-text-size-65 {
    font-size: 130px;
  }
  .desktop-text-size-85 {
    font-size: 170px;
  }
  .desktop-text-size-100 {
    font-size: 200px;
  }
  .desktop-w-0 {
    width: 0px;
  }
  .desktop-h-0 {
    height: 0px;
  }
  .desktop-h-0-i {
    height: 0px !important;
  }
  .desktop-min-h-0 {
    min-height: 0px;
  }
  .desktop-min-w-0 {
    min-width: 0px !important;
  }
  .desktop-w-1 {
    width: 4px;
  }
  .desktop-h-1 {
    height: 4px;
  }
  .desktop-h-1-i {
    height: 4px !important;
  }
  .desktop-min-h-1 {
    min-height: 4px;
  }
  .desktop-min-w-1 {
    min-width: 4px !important;
  }
  .desktop-w-2 {
    width: 8px;
  }
  .desktop-h-2 {
    height: 8px;
  }
  .desktop-h-2-i {
    height: 8px !important;
  }
  .desktop-min-h-2 {
    min-height: 8px;
  }
  .desktop-min-w-2 {
    min-width: 8px !important;
  }
  .desktop-w-3 {
    width: 12px;
  }
  .desktop-h-3 {
    height: 12px;
  }
  .desktop-h-3-i {
    height: 12px !important;
  }
  .desktop-min-h-3 {
    min-height: 12px;
  }
  .desktop-min-w-3 {
    min-width: 12px !important;
  }
  .desktop-w-4 {
    width: 16px;
  }
  .desktop-h-4 {
    height: 16px;
  }
  .desktop-h-4-i {
    height: 16px !important;
  }
  .desktop-min-h-4 {
    min-height: 16px;
  }
  .desktop-min-w-4 {
    min-width: 16px !important;
  }
  .desktop-w-5 {
    width: 20px;
  }
  .desktop-h-5 {
    height: 20px;
  }
  .desktop-h-5-i {
    height: 20px !important;
  }
  .desktop-min-h-5 {
    min-height: 20px;
  }
  .desktop-min-w-5 {
    min-width: 20px !important;
  }
  .desktop-w-6 {
    width: 24px;
  }
  .desktop-h-6 {
    height: 24px;
  }
  .desktop-h-6-i {
    height: 24px !important;
  }
  .desktop-min-h-6 {
    min-height: 24px;
  }
  .desktop-min-w-6 {
    min-width: 24px !important;
  }
  .desktop-w-7 {
    width: 28px;
  }
  .desktop-h-7 {
    height: 28px;
  }
  .desktop-h-7-i {
    height: 28px !important;
  }
  .desktop-min-h-7 {
    min-height: 28px;
  }
  .desktop-min-w-7 {
    min-width: 28px !important;
  }
  .desktop-w-8 {
    width: 32px;
  }
  .desktop-h-8 {
    height: 32px;
  }
  .desktop-h-8-i {
    height: 32px !important;
  }
  .desktop-min-h-8 {
    min-height: 32px;
  }
  .desktop-min-w-8 {
    min-width: 32px !important;
  }
  .desktop-w-9 {
    width: 36px;
  }
  .desktop-h-9 {
    height: 36px;
  }
  .desktop-h-9-i {
    height: 36px !important;
  }
  .desktop-min-h-9 {
    min-height: 36px;
  }
  .desktop-min-w-9 {
    min-width: 36px !important;
  }
  .desktop-w-10 {
    width: 40px;
  }
  .desktop-h-10 {
    height: 40px;
  }
  .desktop-h-10-i {
    height: 40px !important;
  }
  .desktop-min-h-10 {
    min-height: 40px;
  }
  .desktop-min-w-10 {
    min-width: 40px !important;
  }
  .desktop-w-11 {
    width: 44px;
  }
  .desktop-h-11 {
    height: 44px;
  }
  .desktop-h-11-i {
    height: 44px !important;
  }
  .desktop-min-h-11 {
    min-height: 44px;
  }
  .desktop-min-w-11 {
    min-width: 44px !important;
  }
  .desktop-w-12 {
    width: 48px;
  }
  .desktop-h-12 {
    height: 48px;
  }
  .desktop-h-12-i {
    height: 48px !important;
  }
  .desktop-min-h-12 {
    min-height: 48px;
  }
  .desktop-min-w-12 {
    min-width: 48px !important;
  }
  .desktop-w-13 {
    width: 52px;
  }
  .desktop-h-13 {
    height: 52px;
  }
  .desktop-h-13-i {
    height: 52px !important;
  }
  .desktop-min-h-13 {
    min-height: 52px;
  }
  .desktop-min-w-13 {
    min-width: 52px !important;
  }
  .desktop-w-14 {
    width: 56px;
  }
  .desktop-h-14 {
    height: 56px;
  }
  .desktop-h-14-i {
    height: 56px !important;
  }
  .desktop-min-h-14 {
    min-height: 56px;
  }
  .desktop-min-w-14 {
    min-width: 56px !important;
  }
  .desktop-w-15 {
    width: 60px;
  }
  .desktop-h-15 {
    height: 60px;
  }
  .desktop-h-15-i {
    height: 60px !important;
  }
  .desktop-min-h-15 {
    min-height: 60px;
  }
  .desktop-min-w-15 {
    min-width: 60px !important;
  }
  .desktop-w-16 {
    width: 64px;
  }
  .desktop-h-16 {
    height: 64px;
  }
  .desktop-h-16-i {
    height: 64px !important;
  }
  .desktop-min-h-16 {
    min-height: 64px;
  }
  .desktop-min-w-16 {
    min-width: 64px !important;
  }
  .desktop-w-17 {
    width: 68px;
  }
  .desktop-h-17 {
    height: 68px;
  }
  .desktop-h-17-i {
    height: 68px !important;
  }
  .desktop-min-h-17 {
    min-height: 68px;
  }
  .desktop-min-w-17 {
    min-width: 68px !important;
  }
  .desktop-w-18 {
    width: 72px;
  }
  .desktop-h-18 {
    height: 72px;
  }
  .desktop-h-18-i {
    height: 72px !important;
  }
  .desktop-min-h-18 {
    min-height: 72px;
  }
  .desktop-min-w-18 {
    min-width: 72px !important;
  }
  .desktop-w-19 {
    width: 76px;
  }
  .desktop-h-19 {
    height: 76px;
  }
  .desktop-h-19-i {
    height: 76px !important;
  }
  .desktop-min-h-19 {
    min-height: 76px;
  }
  .desktop-min-w-19 {
    min-width: 76px !important;
  }
  .desktop-w-20 {
    width: 80px;
  }
  .desktop-h-20 {
    height: 80px;
  }
  .desktop-h-20-i {
    height: 80px !important;
  }
  .desktop-min-h-20 {
    min-height: 80px;
  }
  .desktop-min-w-20 {
    min-width: 80px !important;
  }
  .desktop-w-25 {
    width: 100px;
  }
  .desktop-h-25 {
    height: 100px;
  }
  .desktop-h-25-i {
    height: 100px !important;
  }
  .desktop-min-h-25 {
    min-height: 100px;
  }
  .desktop-min-w-25 {
    min-width: 100px !important;
  }
  .desktop-w-30 {
    width: 120px;
  }
  .desktop-h-30 {
    height: 120px;
  }
  .desktop-h-30-i {
    height: 120px !important;
  }
  .desktop-min-h-30 {
    min-height: 120px;
  }
  .desktop-min-w-30 {
    min-width: 120px !important;
  }
  .desktop-w-35 {
    width: 140px;
  }
  .desktop-h-35 {
    height: 140px;
  }
  .desktop-h-35-i {
    height: 140px !important;
  }
  .desktop-min-h-35 {
    min-height: 140px;
  }
  .desktop-min-w-35 {
    min-width: 140px !important;
  }
  .desktop-w-50 {
    width: 200px;
  }
  .desktop-h-50 {
    height: 200px;
  }
  .desktop-h-50-i {
    height: 200px !important;
  }
  .desktop-min-h-50 {
    min-height: 200px;
  }
  .desktop-min-w-50 {
    min-width: 200px !important;
  }
  .desktop-w-65 {
    width: 260px;
  }
  .desktop-h-65 {
    height: 260px;
  }
  .desktop-h-65-i {
    height: 260px !important;
  }
  .desktop-min-h-65 {
    min-height: 260px;
  }
  .desktop-min-w-65 {
    min-width: 260px !important;
  }
  .desktop-w-85 {
    width: 340px;
  }
  .desktop-h-85 {
    height: 340px;
  }
  .desktop-h-85-i {
    height: 340px !important;
  }
  .desktop-min-h-85 {
    min-height: 340px;
  }
  .desktop-min-w-85 {
    min-width: 340px !important;
  }
  .desktop-w-100 {
    width: 400px;
  }
  .desktop-h-100 {
    height: 400px;
  }
  .desktop-h-100-i {
    height: 400px !important;
  }
  .desktop-min-h-100 {
    min-height: 400px;
  }
  .desktop-min-w-100 {
    min-width: 400px !important;
  }
  .desktop-m-0 {
    margin: 0px;
  }
  .desktop-mx-0 {
    margin-left: 0px;
    margin-right: 0px;
  }
  .desktop-my-0 {
    margin-top: 0px;
    margin-bottom: 0px;
  }
  .desktop-ml-0 {
    margin-left: 0px;
  }
  .desktop-ml-0-i {
    margin-left: 0px !important;
  }
  .desktop-mr-0 {
    margin-right: 0px;
  }
  .desktop-mr-0-i {
    margin-right: 0px !important;
  }
  .desktop-mb-0 {
    margin-bottom: 0px;
  }
  .desktop-mt-0 {
    margin-top: 0px;
  }
  .desktop-p-0 {
    padding: 0px;
  }
  .desktop-p-0-i {
    padding: 0px !important;
  }
  .desktop-px-0 {
    padding-left: 0px;
    padding-right: 0px;
  }
  .desktop-px-0-i {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
  .desktop-py-0 {
    padding-top: 0px;
    padding-bottom: 0px;
  }
  .desktop-pl-0 {
    padding-left: 0px;
  }
  .desktop-pr-0 {
    padding-right: 0px;
  }
  .desktop-pl-0-i {
    padding-left: 0px !important;
  }
  .desktop-pr-0-i {
    padding-right: 0px !important;
  }
  .desktop-pb-0 {
    padding-bottom: 0px;
  }
  .desktop-pb-0-i {
    padding-bottom: 0px !important;
  }
  .desktop-pt-0 {
    padding-top: 0px;
  }
  .desktop-m-1 {
    margin: 4px;
  }
  .desktop-mx-1 {
    margin-left: 4px;
    margin-right: 4px;
  }
  .desktop-my-1 {
    margin-top: 4px;
    margin-bottom: 4px;
  }
  .desktop-ml-1 {
    margin-left: 4px;
  }
  .desktop-ml-1-i {
    margin-left: 4px !important;
  }
  .desktop-mr-1 {
    margin-right: 4px;
  }
  .desktop-mr-1-i {
    margin-right: 4px !important;
  }
  .desktop-mb-1 {
    margin-bottom: 4px;
  }
  .desktop-mt-1 {
    margin-top: 4px;
  }
  .desktop-p-1 {
    padding: 4px;
  }
  .desktop-p-1-i {
    padding: 4px !important;
  }
  .desktop-px-1 {
    padding-left: 4px;
    padding-right: 4px;
  }
  .desktop-px-1-i {
    padding-left: 4px !important;
    padding-right: 4px !important;
  }
  .desktop-py-1 {
    padding-top: 4px;
    padding-bottom: 4px;
  }
  .desktop-pl-1 {
    padding-left: 4px;
  }
  .desktop-pr-1 {
    padding-right: 4px;
  }
  .desktop-pl-1-i {
    padding-left: 4px !important;
  }
  .desktop-pr-1-i {
    padding-right: 4px !important;
  }
  .desktop-pb-1 {
    padding-bottom: 4px;
  }
  .desktop-pb-1-i {
    padding-bottom: 4px !important;
  }
  .desktop-pt-1 {
    padding-top: 4px;
  }
  .desktop-m-2 {
    margin: 8px;
  }
  .desktop-mx-2 {
    margin-left: 8px;
    margin-right: 8px;
  }
  .desktop-my-2 {
    margin-top: 8px;
    margin-bottom: 8px;
  }
  .desktop-ml-2 {
    margin-left: 8px;
  }
  .desktop-ml-2-i {
    margin-left: 8px !important;
  }
  .desktop-mr-2 {
    margin-right: 8px;
  }
  .desktop-mr-2-i {
    margin-right: 8px !important;
  }
  .desktop-mb-2 {
    margin-bottom: 8px;
  }
  .desktop-mt-2 {
    margin-top: 8px;
  }
  .desktop-p-2 {
    padding: 8px;
  }
  .desktop-p-2-i {
    padding: 8px !important;
  }
  .desktop-px-2 {
    padding-left: 8px;
    padding-right: 8px;
  }
  .desktop-px-2-i {
    padding-left: 8px !important;
    padding-right: 8px !important;
  }
  .desktop-py-2 {
    padding-top: 8px;
    padding-bottom: 8px;
  }
  .desktop-pl-2 {
    padding-left: 8px;
  }
  .desktop-pr-2 {
    padding-right: 8px;
  }
  .desktop-pl-2-i {
    padding-left: 8px !important;
  }
  .desktop-pr-2-i {
    padding-right: 8px !important;
  }
  .desktop-pb-2 {
    padding-bottom: 8px;
  }
  .desktop-pb-2-i {
    padding-bottom: 8px !important;
  }
  .desktop-pt-2 {
    padding-top: 8px;
  }
  .desktop-m-3 {
    margin: 12px;
  }
  .desktop-mx-3 {
    margin-left: 12px;
    margin-right: 12px;
  }
  .desktop-my-3 {
    margin-top: 12px;
    margin-bottom: 12px;
  }
  .desktop-ml-3 {
    margin-left: 12px;
  }
  .desktop-ml-3-i {
    margin-left: 12px !important;
  }
  .desktop-mr-3 {
    margin-right: 12px;
  }
  .desktop-mr-3-i {
    margin-right: 12px !important;
  }
  .desktop-mb-3 {
    margin-bottom: 12px;
  }
  .desktop-mt-3 {
    margin-top: 12px;
  }
  .desktop-p-3 {
    padding: 12px;
  }
  .desktop-p-3-i {
    padding: 12px !important;
  }
  .desktop-px-3 {
    padding-left: 12px;
    padding-right: 12px;
  }
  .desktop-px-3-i {
    padding-left: 12px !important;
    padding-right: 12px !important;
  }
  .desktop-py-3 {
    padding-top: 12px;
    padding-bottom: 12px;
  }
  .desktop-pl-3 {
    padding-left: 12px;
  }
  .desktop-pr-3 {
    padding-right: 12px;
  }
  .desktop-pl-3-i {
    padding-left: 12px !important;
  }
  .desktop-pr-3-i {
    padding-right: 12px !important;
  }
  .desktop-pb-3 {
    padding-bottom: 12px;
  }
  .desktop-pb-3-i {
    padding-bottom: 12px !important;
  }
  .desktop-pt-3 {
    padding-top: 12px;
  }
  .desktop-m-4 {
    margin: 16px;
  }
  .desktop-mx-4 {
    margin-left: 16px;
    margin-right: 16px;
  }
  .desktop-my-4 {
    margin-top: 16px;
    margin-bottom: 16px;
  }
  .desktop-ml-4 {
    margin-left: 16px;
  }
  .desktop-ml-4-i {
    margin-left: 16px !important;
  }
  .desktop-mr-4 {
    margin-right: 16px;
  }
  .desktop-mr-4-i {
    margin-right: 16px !important;
  }
  .desktop-mb-4 {
    margin-bottom: 16px;
  }
  .desktop-mt-4 {
    margin-top: 16px;
  }
  .desktop-p-4 {
    padding: 16px;
  }
  .desktop-p-4-i {
    padding: 16px !important;
  }
  .desktop-px-4 {
    padding-left: 16px;
    padding-right: 16px;
  }
  .desktop-px-4-i {
    padding-left: 16px !important;
    padding-right: 16px !important;
  }
  .desktop-py-4 {
    padding-top: 16px;
    padding-bottom: 16px;
  }
  .desktop-pl-4 {
    padding-left: 16px;
  }
  .desktop-pr-4 {
    padding-right: 16px;
  }
  .desktop-pl-4-i {
    padding-left: 16px !important;
  }
  .desktop-pr-4-i {
    padding-right: 16px !important;
  }
  .desktop-pb-4 {
    padding-bottom: 16px;
  }
  .desktop-pb-4-i {
    padding-bottom: 16px !important;
  }
  .desktop-pt-4 {
    padding-top: 16px;
  }
  .desktop-m-5 {
    margin: 20px;
  }
  .desktop-mx-5 {
    margin-left: 20px;
    margin-right: 20px;
  }
  .desktop-my-5 {
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .desktop-ml-5 {
    margin-left: 20px;
  }
  .desktop-ml-5-i {
    margin-left: 20px !important;
  }
  .desktop-mr-5 {
    margin-right: 20px;
  }
  .desktop-mr-5-i {
    margin-right: 20px !important;
  }
  .desktop-mb-5 {
    margin-bottom: 20px;
  }
  .desktop-mt-5 {
    margin-top: 20px;
  }
  .desktop-p-5 {
    padding: 20px;
  }
  .desktop-p-5-i {
    padding: 20px !important;
  }
  .desktop-px-5 {
    padding-left: 20px;
    padding-right: 20px;
  }
  .desktop-px-5-i {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }
  .desktop-py-5 {
    padding-top: 20px;
    padding-bottom: 20px;
  }
  .desktop-pl-5 {
    padding-left: 20px;
  }
  .desktop-pr-5 {
    padding-right: 20px;
  }
  .desktop-pl-5-i {
    padding-left: 20px !important;
  }
  .desktop-pr-5-i {
    padding-right: 20px !important;
  }
  .desktop-pb-5 {
    padding-bottom: 20px;
  }
  .desktop-pb-5-i {
    padding-bottom: 20px !important;
  }
  .desktop-pt-5 {
    padding-top: 20px;
  }
  .desktop-m-6 {
    margin: 24px;
  }
  .desktop-mx-6 {
    margin-left: 24px;
    margin-right: 24px;
  }
  .desktop-my-6 {
    margin-top: 24px;
    margin-bottom: 24px;
  }
  .desktop-ml-6 {
    margin-left: 24px;
  }
  .desktop-ml-6-i {
    margin-left: 24px !important;
  }
  .desktop-mr-6 {
    margin-right: 24px;
  }
  .desktop-mr-6-i {
    margin-right: 24px !important;
  }
  .desktop-mb-6 {
    margin-bottom: 24px;
  }
  .desktop-mt-6 {
    margin-top: 24px;
  }
  .desktop-p-6 {
    padding: 24px;
  }
  .desktop-p-6-i {
    padding: 24px !important;
  }
  .desktop-px-6 {
    padding-left: 24px;
    padding-right: 24px;
  }
  .desktop-px-6-i {
    padding-left: 24px !important;
    padding-right: 24px !important;
  }
  .desktop-py-6 {
    padding-top: 24px;
    padding-bottom: 24px;
  }
  .desktop-pl-6 {
    padding-left: 24px;
  }
  .desktop-pr-6 {
    padding-right: 24px;
  }
  .desktop-pl-6-i {
    padding-left: 24px !important;
  }
  .desktop-pr-6-i {
    padding-right: 24px !important;
  }
  .desktop-pb-6 {
    padding-bottom: 24px;
  }
  .desktop-pb-6-i {
    padding-bottom: 24px !important;
  }
  .desktop-pt-6 {
    padding-top: 24px;
  }
  .desktop-m-7 {
    margin: 28px;
  }
  .desktop-mx-7 {
    margin-left: 28px;
    margin-right: 28px;
  }
  .desktop-my-7 {
    margin-top: 28px;
    margin-bottom: 28px;
  }
  .desktop-ml-7 {
    margin-left: 28px;
  }
  .desktop-ml-7-i {
    margin-left: 28px !important;
  }
  .desktop-mr-7 {
    margin-right: 28px;
  }
  .desktop-mr-7-i {
    margin-right: 28px !important;
  }
  .desktop-mb-7 {
    margin-bottom: 28px;
  }
  .desktop-mt-7 {
    margin-top: 28px;
  }
  .desktop-p-7 {
    padding: 28px;
  }
  .desktop-p-7-i {
    padding: 28px !important;
  }
  .desktop-px-7 {
    padding-left: 28px;
    padding-right: 28px;
  }
  .desktop-px-7-i {
    padding-left: 28px !important;
    padding-right: 28px !important;
  }
  .desktop-py-7 {
    padding-top: 28px;
    padding-bottom: 28px;
  }
  .desktop-pl-7 {
    padding-left: 28px;
  }
  .desktop-pr-7 {
    padding-right: 28px;
  }
  .desktop-pl-7-i {
    padding-left: 28px !important;
  }
  .desktop-pr-7-i {
    padding-right: 28px !important;
  }
  .desktop-pb-7 {
    padding-bottom: 28px;
  }
  .desktop-pb-7-i {
    padding-bottom: 28px !important;
  }
  .desktop-pt-7 {
    padding-top: 28px;
  }
  .desktop-m-8 {
    margin: 32px;
  }
  .desktop-mx-8 {
    margin-left: 32px;
    margin-right: 32px;
  }
  .desktop-my-8 {
    margin-top: 32px;
    margin-bottom: 32px;
  }
  .desktop-ml-8 {
    margin-left: 32px;
  }
  .desktop-ml-8-i {
    margin-left: 32px !important;
  }
  .desktop-mr-8 {
    margin-right: 32px;
  }
  .desktop-mr-8-i {
    margin-right: 32px !important;
  }
  .desktop-mb-8 {
    margin-bottom: 32px;
  }
  .desktop-mt-8 {
    margin-top: 32px;
  }
  .desktop-p-8 {
    padding: 32px;
  }
  .desktop-p-8-i {
    padding: 32px !important;
  }
  .desktop-px-8 {
    padding-left: 32px;
    padding-right: 32px;
  }
  .desktop-px-8-i {
    padding-left: 32px !important;
    padding-right: 32px !important;
  }
  .desktop-py-8 {
    padding-top: 32px;
    padding-bottom: 32px;
  }
  .desktop-pl-8 {
    padding-left: 32px;
  }
  .desktop-pr-8 {
    padding-right: 32px;
  }
  .desktop-pl-8-i {
    padding-left: 32px !important;
  }
  .desktop-pr-8-i {
    padding-right: 32px !important;
  }
  .desktop-pb-8 {
    padding-bottom: 32px;
  }
  .desktop-pb-8-i {
    padding-bottom: 32px !important;
  }
  .desktop-pt-8 {
    padding-top: 32px;
  }
  .desktop-m-9 {
    margin: 36px;
  }
  .desktop-mx-9 {
    margin-left: 36px;
    margin-right: 36px;
  }
  .desktop-my-9 {
    margin-top: 36px;
    margin-bottom: 36px;
  }
  .desktop-ml-9 {
    margin-left: 36px;
  }
  .desktop-ml-9-i {
    margin-left: 36px !important;
  }
  .desktop-mr-9 {
    margin-right: 36px;
  }
  .desktop-mr-9-i {
    margin-right: 36px !important;
  }
  .desktop-mb-9 {
    margin-bottom: 36px;
  }
  .desktop-mt-9 {
    margin-top: 36px;
  }
  .desktop-p-9 {
    padding: 36px;
  }
  .desktop-p-9-i {
    padding: 36px !important;
  }
  .desktop-px-9 {
    padding-left: 36px;
    padding-right: 36px;
  }
  .desktop-px-9-i {
    padding-left: 36px !important;
    padding-right: 36px !important;
  }
  .desktop-py-9 {
    padding-top: 36px;
    padding-bottom: 36px;
  }
  .desktop-pl-9 {
    padding-left: 36px;
  }
  .desktop-pr-9 {
    padding-right: 36px;
  }
  .desktop-pl-9-i {
    padding-left: 36px !important;
  }
  .desktop-pr-9-i {
    padding-right: 36px !important;
  }
  .desktop-pb-9 {
    padding-bottom: 36px;
  }
  .desktop-pb-9-i {
    padding-bottom: 36px !important;
  }
  .desktop-pt-9 {
    padding-top: 36px;
  }
  .desktop-m-10 {
    margin: 40px;
  }
  .desktop-mx-10 {
    margin-left: 40px;
    margin-right: 40px;
  }
  .desktop-my-10 {
    margin-top: 40px;
    margin-bottom: 40px;
  }
  .desktop-ml-10 {
    margin-left: 40px;
  }
  .desktop-ml-10-i {
    margin-left: 40px !important;
  }
  .desktop-mr-10 {
    margin-right: 40px;
  }
  .desktop-mr-10-i {
    margin-right: 40px !important;
  }
  .desktop-mb-10 {
    margin-bottom: 40px;
  }
  .desktop-mt-10 {
    margin-top: 40px;
  }
  .desktop-p-10 {
    padding: 40px;
  }
  .desktop-p-10-i {
    padding: 40px !important;
  }
  .desktop-px-10 {
    padding-left: 40px;
    padding-right: 40px;
  }
  .desktop-px-10-i {
    padding-left: 40px !important;
    padding-right: 40px !important;
  }
  .desktop-py-10 {
    padding-top: 40px;
    padding-bottom: 40px;
  }
  .desktop-pl-10 {
    padding-left: 40px;
  }
  .desktop-pr-10 {
    padding-right: 40px;
  }
  .desktop-pl-10-i {
    padding-left: 40px !important;
  }
  .desktop-pr-10-i {
    padding-right: 40px !important;
  }
  .desktop-pb-10 {
    padding-bottom: 40px;
  }
  .desktop-pb-10-i {
    padding-bottom: 40px !important;
  }
  .desktop-pt-10 {
    padding-top: 40px;
  }
  .desktop-m-11 {
    margin: 44px;
  }
  .desktop-mx-11 {
    margin-left: 44px;
    margin-right: 44px;
  }
  .desktop-my-11 {
    margin-top: 44px;
    margin-bottom: 44px;
  }
  .desktop-ml-11 {
    margin-left: 44px;
  }
  .desktop-ml-11-i {
    margin-left: 44px !important;
  }
  .desktop-mr-11 {
    margin-right: 44px;
  }
  .desktop-mr-11-i {
    margin-right: 44px !important;
  }
  .desktop-mb-11 {
    margin-bottom: 44px;
  }
  .desktop-mt-11 {
    margin-top: 44px;
  }
  .desktop-p-11 {
    padding: 44px;
  }
  .desktop-p-11-i {
    padding: 44px !important;
  }
  .desktop-px-11 {
    padding-left: 44px;
    padding-right: 44px;
  }
  .desktop-px-11-i {
    padding-left: 44px !important;
    padding-right: 44px !important;
  }
  .desktop-py-11 {
    padding-top: 44px;
    padding-bottom: 44px;
  }
  .desktop-pl-11 {
    padding-left: 44px;
  }
  .desktop-pr-11 {
    padding-right: 44px;
  }
  .desktop-pl-11-i {
    padding-left: 44px !important;
  }
  .desktop-pr-11-i {
    padding-right: 44px !important;
  }
  .desktop-pb-11 {
    padding-bottom: 44px;
  }
  .desktop-pb-11-i {
    padding-bottom: 44px !important;
  }
  .desktop-pt-11 {
    padding-top: 44px;
  }
  .desktop-m-12 {
    margin: 48px;
  }
  .desktop-mx-12 {
    margin-left: 48px;
    margin-right: 48px;
  }
  .desktop-my-12 {
    margin-top: 48px;
    margin-bottom: 48px;
  }
  .desktop-ml-12 {
    margin-left: 48px;
  }
  .desktop-ml-12-i {
    margin-left: 48px !important;
  }
  .desktop-mr-12 {
    margin-right: 48px;
  }
  .desktop-mr-12-i {
    margin-right: 48px !important;
  }
  .desktop-mb-12 {
    margin-bottom: 48px;
  }
  .desktop-mt-12 {
    margin-top: 48px;
  }
  .desktop-p-12 {
    padding: 48px;
  }
  .desktop-p-12-i {
    padding: 48px !important;
  }
  .desktop-px-12 {
    padding-left: 48px;
    padding-right: 48px;
  }
  .desktop-px-12-i {
    padding-left: 48px !important;
    padding-right: 48px !important;
  }
  .desktop-py-12 {
    padding-top: 48px;
    padding-bottom: 48px;
  }
  .desktop-pl-12 {
    padding-left: 48px;
  }
  .desktop-pr-12 {
    padding-right: 48px;
  }
  .desktop-pl-12-i {
    padding-left: 48px !important;
  }
  .desktop-pr-12-i {
    padding-right: 48px !important;
  }
  .desktop-pb-12 {
    padding-bottom: 48px;
  }
  .desktop-pb-12-i {
    padding-bottom: 48px !important;
  }
  .desktop-pt-12 {
    padding-top: 48px;
  }
  .desktop-m-13 {
    margin: 52px;
  }
  .desktop-mx-13 {
    margin-left: 52px;
    margin-right: 52px;
  }
  .desktop-my-13 {
    margin-top: 52px;
    margin-bottom: 52px;
  }
  .desktop-ml-13 {
    margin-left: 52px;
  }
  .desktop-ml-13-i {
    margin-left: 52px !important;
  }
  .desktop-mr-13 {
    margin-right: 52px;
  }
  .desktop-mr-13-i {
    margin-right: 52px !important;
  }
  .desktop-mb-13 {
    margin-bottom: 52px;
  }
  .desktop-mt-13 {
    margin-top: 52px;
  }
  .desktop-p-13 {
    padding: 52px;
  }
  .desktop-p-13-i {
    padding: 52px !important;
  }
  .desktop-px-13 {
    padding-left: 52px;
    padding-right: 52px;
  }
  .desktop-px-13-i {
    padding-left: 52px !important;
    padding-right: 52px !important;
  }
  .desktop-py-13 {
    padding-top: 52px;
    padding-bottom: 52px;
  }
  .desktop-pl-13 {
    padding-left: 52px;
  }
  .desktop-pr-13 {
    padding-right: 52px;
  }
  .desktop-pl-13-i {
    padding-left: 52px !important;
  }
  .desktop-pr-13-i {
    padding-right: 52px !important;
  }
  .desktop-pb-13 {
    padding-bottom: 52px;
  }
  .desktop-pb-13-i {
    padding-bottom: 52px !important;
  }
  .desktop-pt-13 {
    padding-top: 52px;
  }
  .desktop-m-14 {
    margin: 56px;
  }
  .desktop-mx-14 {
    margin-left: 56px;
    margin-right: 56px;
  }
  .desktop-my-14 {
    margin-top: 56px;
    margin-bottom: 56px;
  }
  .desktop-ml-14 {
    margin-left: 56px;
  }
  .desktop-ml-14-i {
    margin-left: 56px !important;
  }
  .desktop-mr-14 {
    margin-right: 56px;
  }
  .desktop-mr-14-i {
    margin-right: 56px !important;
  }
  .desktop-mb-14 {
    margin-bottom: 56px;
  }
  .desktop-mt-14 {
    margin-top: 56px;
  }
  .desktop-p-14 {
    padding: 56px;
  }
  .desktop-p-14-i {
    padding: 56px !important;
  }
  .desktop-px-14 {
    padding-left: 56px;
    padding-right: 56px;
  }
  .desktop-px-14-i {
    padding-left: 56px !important;
    padding-right: 56px !important;
  }
  .desktop-py-14 {
    padding-top: 56px;
    padding-bottom: 56px;
  }
  .desktop-pl-14 {
    padding-left: 56px;
  }
  .desktop-pr-14 {
    padding-right: 56px;
  }
  .desktop-pl-14-i {
    padding-left: 56px !important;
  }
  .desktop-pr-14-i {
    padding-right: 56px !important;
  }
  .desktop-pb-14 {
    padding-bottom: 56px;
  }
  .desktop-pb-14-i {
    padding-bottom: 56px !important;
  }
  .desktop-pt-14 {
    padding-top: 56px;
  }
  .desktop-m-15 {
    margin: 60px;
  }
  .desktop-mx-15 {
    margin-left: 60px;
    margin-right: 60px;
  }
  .desktop-my-15 {
    margin-top: 60px;
    margin-bottom: 60px;
  }
  .desktop-ml-15 {
    margin-left: 60px;
  }
  .desktop-ml-15-i {
    margin-left: 60px !important;
  }
  .desktop-mr-15 {
    margin-right: 60px;
  }
  .desktop-mr-15-i {
    margin-right: 60px !important;
  }
  .desktop-mb-15 {
    margin-bottom: 60px;
  }
  .desktop-mt-15 {
    margin-top: 60px;
  }
  .desktop-p-15 {
    padding: 60px;
  }
  .desktop-p-15-i {
    padding: 60px !important;
  }
  .desktop-px-15 {
    padding-left: 60px;
    padding-right: 60px;
  }
  .desktop-px-15-i {
    padding-left: 60px !important;
    padding-right: 60px !important;
  }
  .desktop-py-15 {
    padding-top: 60px;
    padding-bottom: 60px;
  }
  .desktop-pl-15 {
    padding-left: 60px;
  }
  .desktop-pr-15 {
    padding-right: 60px;
  }
  .desktop-pl-15-i {
    padding-left: 60px !important;
  }
  .desktop-pr-15-i {
    padding-right: 60px !important;
  }
  .desktop-pb-15 {
    padding-bottom: 60px;
  }
  .desktop-pb-15-i {
    padding-bottom: 60px !important;
  }
  .desktop-pt-15 {
    padding-top: 60px;
  }
  .desktop-m-16 {
    margin: 64px;
  }
  .desktop-mx-16 {
    margin-left: 64px;
    margin-right: 64px;
  }
  .desktop-my-16 {
    margin-top: 64px;
    margin-bottom: 64px;
  }
  .desktop-ml-16 {
    margin-left: 64px;
  }
  .desktop-ml-16-i {
    margin-left: 64px !important;
  }
  .desktop-mr-16 {
    margin-right: 64px;
  }
  .desktop-mr-16-i {
    margin-right: 64px !important;
  }
  .desktop-mb-16 {
    margin-bottom: 64px;
  }
  .desktop-mt-16 {
    margin-top: 64px;
  }
  .desktop-p-16 {
    padding: 64px;
  }
  .desktop-p-16-i {
    padding: 64px !important;
  }
  .desktop-px-16 {
    padding-left: 64px;
    padding-right: 64px;
  }
  .desktop-px-16-i {
    padding-left: 64px !important;
    padding-right: 64px !important;
  }
  .desktop-py-16 {
    padding-top: 64px;
    padding-bottom: 64px;
  }
  .desktop-pl-16 {
    padding-left: 64px;
  }
  .desktop-pr-16 {
    padding-right: 64px;
  }
  .desktop-pl-16-i {
    padding-left: 64px !important;
  }
  .desktop-pr-16-i {
    padding-right: 64px !important;
  }
  .desktop-pb-16 {
    padding-bottom: 64px;
  }
  .desktop-pb-16-i {
    padding-bottom: 64px !important;
  }
  .desktop-pt-16 {
    padding-top: 64px;
  }
  .desktop-m-17 {
    margin: 68px;
  }
  .desktop-mx-17 {
    margin-left: 68px;
    margin-right: 68px;
  }
  .desktop-my-17 {
    margin-top: 68px;
    margin-bottom: 68px;
  }
  .desktop-ml-17 {
    margin-left: 68px;
  }
  .desktop-ml-17-i {
    margin-left: 68px !important;
  }
  .desktop-mr-17 {
    margin-right: 68px;
  }
  .desktop-mr-17-i {
    margin-right: 68px !important;
  }
  .desktop-mb-17 {
    margin-bottom: 68px;
  }
  .desktop-mt-17 {
    margin-top: 68px;
  }
  .desktop-p-17 {
    padding: 68px;
  }
  .desktop-p-17-i {
    padding: 68px !important;
  }
  .desktop-px-17 {
    padding-left: 68px;
    padding-right: 68px;
  }
  .desktop-px-17-i {
    padding-left: 68px !important;
    padding-right: 68px !important;
  }
  .desktop-py-17 {
    padding-top: 68px;
    padding-bottom: 68px;
  }
  .desktop-pl-17 {
    padding-left: 68px;
  }
  .desktop-pr-17 {
    padding-right: 68px;
  }
  .desktop-pl-17-i {
    padding-left: 68px !important;
  }
  .desktop-pr-17-i {
    padding-right: 68px !important;
  }
  .desktop-pb-17 {
    padding-bottom: 68px;
  }
  .desktop-pb-17-i {
    padding-bottom: 68px !important;
  }
  .desktop-pt-17 {
    padding-top: 68px;
  }
  .desktop-m-18 {
    margin: 72px;
  }
  .desktop-mx-18 {
    margin-left: 72px;
    margin-right: 72px;
  }
  .desktop-my-18 {
    margin-top: 72px;
    margin-bottom: 72px;
  }
  .desktop-ml-18 {
    margin-left: 72px;
  }
  .desktop-ml-18-i {
    margin-left: 72px !important;
  }
  .desktop-mr-18 {
    margin-right: 72px;
  }
  .desktop-mr-18-i {
    margin-right: 72px !important;
  }
  .desktop-mb-18 {
    margin-bottom: 72px;
  }
  .desktop-mt-18 {
    margin-top: 72px;
  }
  .desktop-p-18 {
    padding: 72px;
  }
  .desktop-p-18-i {
    padding: 72px !important;
  }
  .desktop-px-18 {
    padding-left: 72px;
    padding-right: 72px;
  }
  .desktop-px-18-i {
    padding-left: 72px !important;
    padding-right: 72px !important;
  }
  .desktop-py-18 {
    padding-top: 72px;
    padding-bottom: 72px;
  }
  .desktop-pl-18 {
    padding-left: 72px;
  }
  .desktop-pr-18 {
    padding-right: 72px;
  }
  .desktop-pl-18-i {
    padding-left: 72px !important;
  }
  .desktop-pr-18-i {
    padding-right: 72px !important;
  }
  .desktop-pb-18 {
    padding-bottom: 72px;
  }
  .desktop-pb-18-i {
    padding-bottom: 72px !important;
  }
  .desktop-pt-18 {
    padding-top: 72px;
  }
  .desktop-m-19 {
    margin: 76px;
  }
  .desktop-mx-19 {
    margin-left: 76px;
    margin-right: 76px;
  }
  .desktop-my-19 {
    margin-top: 76px;
    margin-bottom: 76px;
  }
  .desktop-ml-19 {
    margin-left: 76px;
  }
  .desktop-ml-19-i {
    margin-left: 76px !important;
  }
  .desktop-mr-19 {
    margin-right: 76px;
  }
  .desktop-mr-19-i {
    margin-right: 76px !important;
  }
  .desktop-mb-19 {
    margin-bottom: 76px;
  }
  .desktop-mt-19 {
    margin-top: 76px;
  }
  .desktop-p-19 {
    padding: 76px;
  }
  .desktop-p-19-i {
    padding: 76px !important;
  }
  .desktop-px-19 {
    padding-left: 76px;
    padding-right: 76px;
  }
  .desktop-px-19-i {
    padding-left: 76px !important;
    padding-right: 76px !important;
  }
  .desktop-py-19 {
    padding-top: 76px;
    padding-bottom: 76px;
  }
  .desktop-pl-19 {
    padding-left: 76px;
  }
  .desktop-pr-19 {
    padding-right: 76px;
  }
  .desktop-pl-19-i {
    padding-left: 76px !important;
  }
  .desktop-pr-19-i {
    padding-right: 76px !important;
  }
  .desktop-pb-19 {
    padding-bottom: 76px;
  }
  .desktop-pb-19-i {
    padding-bottom: 76px !important;
  }
  .desktop-pt-19 {
    padding-top: 76px;
  }
  .desktop-m-20 {
    margin: 80px;
  }
  .desktop-mx-20 {
    margin-left: 80px;
    margin-right: 80px;
  }
  .desktop-my-20 {
    margin-top: 80px;
    margin-bottom: 80px;
  }
  .desktop-ml-20 {
    margin-left: 80px;
  }
  .desktop-ml-20-i {
    margin-left: 80px !important;
  }
  .desktop-mr-20 {
    margin-right: 80px;
  }
  .desktop-mr-20-i {
    margin-right: 80px !important;
  }
  .desktop-mb-20 {
    margin-bottom: 80px;
  }
  .desktop-mt-20 {
    margin-top: 80px;
  }
  .desktop-p-20 {
    padding: 80px;
  }
  .desktop-p-20-i {
    padding: 80px !important;
  }
  .desktop-px-20 {
    padding-left: 80px;
    padding-right: 80px;
  }
  .desktop-px-20-i {
    padding-left: 80px !important;
    padding-right: 80px !important;
  }
  .desktop-py-20 {
    padding-top: 80px;
    padding-bottom: 80px;
  }
  .desktop-pl-20 {
    padding-left: 80px;
  }
  .desktop-pr-20 {
    padding-right: 80px;
  }
  .desktop-pl-20-i {
    padding-left: 80px !important;
  }
  .desktop-pr-20-i {
    padding-right: 80px !important;
  }
  .desktop-pb-20 {
    padding-bottom: 80px;
  }
  .desktop-pb-20-i {
    padding-bottom: 80px !important;
  }
  .desktop-pt-20 {
    padding-top: 80px;
  }
  .desktop-m-25 {
    margin: 100px;
  }
  .desktop-mx-25 {
    margin-left: 100px;
    margin-right: 100px;
  }
  .desktop-my-25 {
    margin-top: 100px;
    margin-bottom: 100px;
  }
  .desktop-ml-25 {
    margin-left: 100px;
  }
  .desktop-ml-25-i {
    margin-left: 100px !important;
  }
  .desktop-mr-25 {
    margin-right: 100px;
  }
  .desktop-mr-25-i {
    margin-right: 100px !important;
  }
  .desktop-mb-25 {
    margin-bottom: 100px;
  }
  .desktop-mt-25 {
    margin-top: 100px;
  }
  .desktop-p-25 {
    padding: 100px;
  }
  .desktop-p-25-i {
    padding: 100px !important;
  }
  .desktop-px-25 {
    padding-left: 100px;
    padding-right: 100px;
  }
  .desktop-px-25-i {
    padding-left: 100px !important;
    padding-right: 100px !important;
  }
  .desktop-py-25 {
    padding-top: 100px;
    padding-bottom: 100px;
  }
  .desktop-pl-25 {
    padding-left: 100px;
  }
  .desktop-pr-25 {
    padding-right: 100px;
  }
  .desktop-pl-25-i {
    padding-left: 100px !important;
  }
  .desktop-pr-25-i {
    padding-right: 100px !important;
  }
  .desktop-pb-25 {
    padding-bottom: 100px;
  }
  .desktop-pb-25-i {
    padding-bottom: 100px !important;
  }
  .desktop-pt-25 {
    padding-top: 100px;
  }
  .desktop-m-30 {
    margin: 120px;
  }
  .desktop-mx-30 {
    margin-left: 120px;
    margin-right: 120px;
  }
  .desktop-my-30 {
    margin-top: 120px;
    margin-bottom: 120px;
  }
  .desktop-ml-30 {
    margin-left: 120px;
  }
  .desktop-ml-30-i {
    margin-left: 120px !important;
  }
  .desktop-mr-30 {
    margin-right: 120px;
  }
  .desktop-mr-30-i {
    margin-right: 120px !important;
  }
  .desktop-mb-30 {
    margin-bottom: 120px;
  }
  .desktop-mt-30 {
    margin-top: 120px;
  }
  .desktop-p-30 {
    padding: 120px;
  }
  .desktop-p-30-i {
    padding: 120px !important;
  }
  .desktop-px-30 {
    padding-left: 120px;
    padding-right: 120px;
  }
  .desktop-px-30-i {
    padding-left: 120px !important;
    padding-right: 120px !important;
  }
  .desktop-py-30 {
    padding-top: 120px;
    padding-bottom: 120px;
  }
  .desktop-pl-30 {
    padding-left: 120px;
  }
  .desktop-pr-30 {
    padding-right: 120px;
  }
  .desktop-pl-30-i {
    padding-left: 120px !important;
  }
  .desktop-pr-30-i {
    padding-right: 120px !important;
  }
  .desktop-pb-30 {
    padding-bottom: 120px;
  }
  .desktop-pb-30-i {
    padding-bottom: 120px !important;
  }
  .desktop-pt-30 {
    padding-top: 120px;
  }
  .desktop-m-35 {
    margin: 140px;
  }
  .desktop-mx-35 {
    margin-left: 140px;
    margin-right: 140px;
  }
  .desktop-my-35 {
    margin-top: 140px;
    margin-bottom: 140px;
  }
  .desktop-ml-35 {
    margin-left: 140px;
  }
  .desktop-ml-35-i {
    margin-left: 140px !important;
  }
  .desktop-mr-35 {
    margin-right: 140px;
  }
  .desktop-mr-35-i {
    margin-right: 140px !important;
  }
  .desktop-mb-35 {
    margin-bottom: 140px;
  }
  .desktop-mt-35 {
    margin-top: 140px;
  }
  .desktop-p-35 {
    padding: 140px;
  }
  .desktop-p-35-i {
    padding: 140px !important;
  }
  .desktop-px-35 {
    padding-left: 140px;
    padding-right: 140px;
  }
  .desktop-px-35-i {
    padding-left: 140px !important;
    padding-right: 140px !important;
  }
  .desktop-py-35 {
    padding-top: 140px;
    padding-bottom: 140px;
  }
  .desktop-pl-35 {
    padding-left: 140px;
  }
  .desktop-pr-35 {
    padding-right: 140px;
  }
  .desktop-pl-35-i {
    padding-left: 140px !important;
  }
  .desktop-pr-35-i {
    padding-right: 140px !important;
  }
  .desktop-pb-35 {
    padding-bottom: 140px;
  }
  .desktop-pb-35-i {
    padding-bottom: 140px !important;
  }
  .desktop-pt-35 {
    padding-top: 140px;
  }
  .desktop-m-50 {
    margin: 200px;
  }
  .desktop-mx-50 {
    margin-left: 200px;
    margin-right: 200px;
  }
  .desktop-my-50 {
    margin-top: 200px;
    margin-bottom: 200px;
  }
  .desktop-ml-50 {
    margin-left: 200px;
  }
  .desktop-ml-50-i {
    margin-left: 200px !important;
  }
  .desktop-mr-50 {
    margin-right: 200px;
  }
  .desktop-mr-50-i {
    margin-right: 200px !important;
  }
  .desktop-mb-50 {
    margin-bottom: 200px;
  }
  .desktop-mt-50 {
    margin-top: 200px;
  }
  .desktop-p-50 {
    padding: 200px;
  }
  .desktop-p-50-i {
    padding: 200px !important;
  }
  .desktop-px-50 {
    padding-left: 200px;
    padding-right: 200px;
  }
  .desktop-px-50-i {
    padding-left: 200px !important;
    padding-right: 200px !important;
  }
  .desktop-py-50 {
    padding-top: 200px;
    padding-bottom: 200px;
  }
  .desktop-pl-50 {
    padding-left: 200px;
  }
  .desktop-pr-50 {
    padding-right: 200px;
  }
  .desktop-pl-50-i {
    padding-left: 200px !important;
  }
  .desktop-pr-50-i {
    padding-right: 200px !important;
  }
  .desktop-pb-50 {
    padding-bottom: 200px;
  }
  .desktop-pb-50-i {
    padding-bottom: 200px !important;
  }
  .desktop-pt-50 {
    padding-top: 200px;
  }
  .desktop-m-65 {
    margin: 260px;
  }
  .desktop-mx-65 {
    margin-left: 260px;
    margin-right: 260px;
  }
  .desktop-my-65 {
    margin-top: 260px;
    margin-bottom: 260px;
  }
  .desktop-ml-65 {
    margin-left: 260px;
  }
  .desktop-ml-65-i {
    margin-left: 260px !important;
  }
  .desktop-mr-65 {
    margin-right: 260px;
  }
  .desktop-mr-65-i {
    margin-right: 260px !important;
  }
  .desktop-mb-65 {
    margin-bottom: 260px;
  }
  .desktop-mt-65 {
    margin-top: 260px;
  }
  .desktop-p-65 {
    padding: 260px;
  }
  .desktop-p-65-i {
    padding: 260px !important;
  }
  .desktop-px-65 {
    padding-left: 260px;
    padding-right: 260px;
  }
  .desktop-px-65-i {
    padding-left: 260px !important;
    padding-right: 260px !important;
  }
  .desktop-py-65 {
    padding-top: 260px;
    padding-bottom: 260px;
  }
  .desktop-pl-65 {
    padding-left: 260px;
  }
  .desktop-pr-65 {
    padding-right: 260px;
  }
  .desktop-pl-65-i {
    padding-left: 260px !important;
  }
  .desktop-pr-65-i {
    padding-right: 260px !important;
  }
  .desktop-pb-65 {
    padding-bottom: 260px;
  }
  .desktop-pb-65-i {
    padding-bottom: 260px !important;
  }
  .desktop-pt-65 {
    padding-top: 260px;
  }
  .desktop-m-85 {
    margin: 340px;
  }
  .desktop-mx-85 {
    margin-left: 340px;
    margin-right: 340px;
  }
  .desktop-my-85 {
    margin-top: 340px;
    margin-bottom: 340px;
  }
  .desktop-ml-85 {
    margin-left: 340px;
  }
  .desktop-ml-85-i {
    margin-left: 340px !important;
  }
  .desktop-mr-85 {
    margin-right: 340px;
  }
  .desktop-mr-85-i {
    margin-right: 340px !important;
  }
  .desktop-mb-85 {
    margin-bottom: 340px;
  }
  .desktop-mt-85 {
    margin-top: 340px;
  }
  .desktop-p-85 {
    padding: 340px;
  }
  .desktop-p-85-i {
    padding: 340px !important;
  }
  .desktop-px-85 {
    padding-left: 340px;
    padding-right: 340px;
  }
  .desktop-px-85-i {
    padding-left: 340px !important;
    padding-right: 340px !important;
  }
  .desktop-py-85 {
    padding-top: 340px;
    padding-bottom: 340px;
  }
  .desktop-pl-85 {
    padding-left: 340px;
  }
  .desktop-pr-85 {
    padding-right: 340px;
  }
  .desktop-pl-85-i {
    padding-left: 340px !important;
  }
  .desktop-pr-85-i {
    padding-right: 340px !important;
  }
  .desktop-pb-85 {
    padding-bottom: 340px;
  }
  .desktop-pb-85-i {
    padding-bottom: 340px !important;
  }
  .desktop-pt-85 {
    padding-top: 340px;
  }
  .desktop-m-100 {
    margin: 400px;
  }
  .desktop-mx-100 {
    margin-left: 400px;
    margin-right: 400px;
  }
  .desktop-my-100 {
    margin-top: 400px;
    margin-bottom: 400px;
  }
  .desktop-ml-100 {
    margin-left: 400px;
  }
  .desktop-ml-100-i {
    margin-left: 400px !important;
  }
  .desktop-mr-100 {
    margin-right: 400px;
  }
  .desktop-mr-100-i {
    margin-right: 400px !important;
  }
  .desktop-mb-100 {
    margin-bottom: 400px;
  }
  .desktop-mt-100 {
    margin-top: 400px;
  }
  .desktop-p-100 {
    padding: 400px;
  }
  .desktop-p-100-i {
    padding: 400px !important;
  }
  .desktop-px-100 {
    padding-left: 400px;
    padding-right: 400px;
  }
  .desktop-px-100-i {
    padding-left: 400px !important;
    padding-right: 400px !important;
  }
  .desktop-py-100 {
    padding-top: 400px;
    padding-bottom: 400px;
  }
  .desktop-pl-100 {
    padding-left: 400px;
  }
  .desktop-pr-100 {
    padding-right: 400px;
  }
  .desktop-pl-100-i {
    padding-left: 400px !important;
  }
  .desktop-pr-100-i {
    padding-right: 400px !important;
  }
  .desktop-pb-100 {
    padding-bottom: 400px;
  }
  .desktop-pb-100-i {
    padding-bottom: 400px !important;
  }
  .desktop-pt-100 {
    padding-top: 400px;
  }
  .desktop-d-flex {
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flexbox;
    display: -ms-flex;
    display: flex;
  }
  .desktop-d-flex-i {
    display: -webkit-flex !important;
    display: -moz-flex !important;
    display: -ms-flex !importantbox;
    display: -ms-flex !important;
    display: flex !important;
  }
  .desktop-d-block {
    display: -webkit-block;
    display: -moz-block;
    display: -ms-blockbox;
    display: -ms-block;
    display: block;
  }
  .desktop-d-grid {
    display: -webkit-grid;
    display: -moz-grid;
    display: -ms-gridbox;
    display: -ms-grid;
    display: grid;
  }
  .desktop-d-inline {
    display: -webkit-inline-flex;
    display: -moz-inline-flex;
    display: -ms-inline-flexbox;
    display: -ms-inline-flex;
    display: inline-flex;
  }
  .desktop-d-none {
    display: -webkit-none;
    display: -moz-none;
    display: -ms-nonebox;
    display: -ms-none;
    display: none;
  }
  .desktop-direction-row {
    -webkit-flex-direction: row;
    -moz-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
  }
  .desktop-directionrow-reverse {
    -webkit-flex-direction: row-reverse;
    -moz-flex-direction: row-reverse;
    -ms-flex-direction: row-reverse;
    flex-direction: row-reverse;
  }
  .desktop-direction-column {
    -webkit-flex-direction: column;
    -moz-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .desktop-direction-column-reverse {
    -webkit-flex-direction: column-reverse;
    -moz-flex-direction: column-reverse;
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse;
  }
  .desktop-wrap-wrap {
    -webkit-flex-wrap: wrap;
    -moz-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
  .desktop-wrap-nowrap {
    -webkit-flex-wrap: nowrap;
    -moz-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
  }
  .desktop-wrap-reverse {
    -webkit-flex-wrap: wrap-reverse;
    -moz-flex-wrap: wrap-reverse;
    -ms-flex-wrap: wrap-reverse;
    flex-wrap: wrap-reverse;
  }
  .desktop-justify-flex-start {
    -webkit-justify-content: flex-start;
    -moz-justify-content: flex-start;
    -ms-justify-content: flex-start;
    justify-content: flex-start;
  }
  .desktop-justify-flex-end {
    -webkit-justify-content: flex-end;
    -moz-justify-content: flex-end;
    -ms-justify-content: flex-end;
    justify-content: flex-end;
  }
  .desktop-justify-center {
    -webkit-justify-content: center;
    -moz-justify-content: center;
    -ms-justify-content: center;
    justify-content: center;
  }
  .desktop-justify-space-between {
    -webkit-justify-content: space-between;
    -moz-justify-content: space-between;
    -ms-justify-content: space-between;
    justify-content: space-between;
  }
  .desktop-justify-space-around {
    -webkit-justify-content: space-around;
    -moz-justify-content: space-around;
    -ms-justify-content: space-around;
    justify-content: space-around;
  }
  .desktop-justify-self-flex-start {
    -webkit-justify-self: flex-start;
    -moz-justify-self: flex-start;
    -ms-justify-self: flex-start;
    justify-self: flex-start;
  }
  .desktop-justify-self-flex-end {
    -webkit-justify-self: flex-end;
    -moz-justify-self: flex-end;
    -ms-justify-self: flex-end;
    justify-self: flex-end;
  }
  .desktop-justify-self-center {
    -webkit-justify-self: center;
    -moz-justify-self: center;
    -ms-justify-self: center;
    justify-self: center;
  }
  .desktop-justify-self-space-between {
    -webkit-justify-self: space-between;
    -moz-justify-self: space-between;
    -ms-justify-self: space-between;
    justify-self: space-between;
  }
  .desktop-justify-self-space-around {
    -webkit-justify-self: space-around;
    -moz-justify-self: space-around;
    -ms-justify-self: space-around;
    justify-self: space-around;
  }
  .desktop-align-content-flex-start {
    -webkit-align-content: flex-start;
    -moz-align-content: flex-start;
    -ms-align-content: flex-start;
    align-content: flex-start;
  }
  .desktop-align-content-flex-end {
    -webkit-align-content: flex-end;
    -moz-align-content: flex-end;
    -ms-align-content: flex-end;
    align-content: flex-end;
  }
  .desktop-align-content-center {
    -webkit-align-content: center;
    -moz-align-content: center;
    -ms-align-content: center;
    align-content: center;
  }
  .desktop-align-content-space-between {
    -webkit-align-content: space-between;
    -moz-align-content: space-between;
    -ms-align-content: space-between;
    align-content: space-between;
  }
  .desktop-align-content-space-around {
    -webkit-align-content: space-around;
    -moz-align-content: space-around;
    -ms-align-content: space-around;
    align-content: space-around;
  }
  .desktop-align-content-stretch {
    -webkit-align-content: stretch;
    -moz-align-content: stretch;
    -ms-align-content: stretch;
    align-content: stretch;
  }
  .desktop-align-items-flex-start {
    -webkit-align-items: flex-start;
    -moz-align-items: flex-start;
    -ms-align-items: flex-start;
    align-items: flex-start;
  }
  .desktop-align-items-flex-end {
    -webkit-align-items: flex-end;
    -moz-align-items: flex-end;
    -ms-align-items: flex-end;
    align-items: flex-end;
  }
  .desktop-align-items-center {
    -webkit-align-items: center;
    -moz-align-items: center;
    -ms-align-items: center;
    align-items: center;
  }
  .desktop-align-items-baseline {
    -webkit-align-items: baseline;
    -moz-align-items: baseline;
    -ms-align-items: baseline;
    align-items: baseline;
  }
  .desktop-align-items-stretch {
    -webkit-align-items: stretch;
    -moz-align-items: stretch;
    -ms-align-items: stretch;
    align-items: stretch;
  }
  .desktop-order-0 {
    -webkit-order: 0;
    -moz-order: 0;
    -ms-order: 0;
    order: 0;
  }
  .desktop-order-1 {
    -webkit-order: 1;
    -moz-order: 1;
    -ms-order: 1;
    order: 1;
  }
  .desktop-order-2 {
    -webkit-order: 2;
    -moz-order: 2;
    -ms-order: 2;
    order: 2;
  }
  .desktop-order-3 {
    -webkit-order: 3;
    -moz-order: 3;
    -ms-order: 3;
    order: 3;
  }
  .desktop-order-4 {
    -webkit-order: 4;
    -moz-order: 4;
    -ms-order: 4;
    order: 4;
  }
  .desktop-order-5 {
    -webkit-order: 5;
    -moz-order: 5;
    -ms-order: 5;
    order: 5;
  }
  .desktop-order-6 {
    -webkit-order: 6;
    -moz-order: 6;
    -ms-order: 6;
    order: 6;
  }
  .desktop-order-7 {
    -webkit-order: 7;
    -moz-order: 7;
    -ms-order: 7;
    order: 7;
  }
  .desktop-order-8 {
    -webkit-order: 8;
    -moz-order: 8;
    -ms-order: 8;
    order: 8;
  }
  .desktop-order-9 {
    -webkit-order: 9;
    -moz-order: 9;
    -ms-order: 9;
    order: 9;
  }
  .desktop-order-10 {
    -webkit-order: 10;
    -moz-order: 10;
    -ms-order: 10;
    order: 10;
  }
  .desktop-grow-0 {
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-grow: 0;
    flex-grow: 0;
  }
  .desktop-grow-1 {
    -webkit-flex-grow: 1;
    -moz-flex-grow: 1;
    -ms-flex-grow: 1;
    flex-grow: 1;
  }
  .desktop-grow-2 {
    -webkit-flex-grow: 2;
    -moz-flex-grow: 2;
    -ms-flex-grow: 2;
    flex-grow: 2;
  }
  .desktop-grow-3 {
    -webkit-flex-grow: 3;
    -moz-flex-grow: 3;
    -ms-flex-grow: 3;
    flex-grow: 3;
  }
  .desktop-grow-4 {
    -webkit-flex-grow: 4;
    -moz-flex-grow: 4;
    -ms-flex-grow: 4;
    flex-grow: 4;
  }
  .desktop-grow-5 {
    -webkit-flex-grow: 5;
    -moz-flex-grow: 5;
    -ms-flex-grow: 5;
    flex-grow: 5;
  }
  .desktop-grow-6 {
    -webkit-flex-grow: 6;
    -moz-flex-grow: 6;
    -ms-flex-grow: 6;
    flex-grow: 6;
  }
  .desktop-grow-7 {
    -webkit-flex-grow: 7;
    -moz-flex-grow: 7;
    -ms-flex-grow: 7;
    flex-grow: 7;
  }
  .desktop-grow-8 {
    -webkit-flex-grow: 8;
    -moz-flex-grow: 8;
    -ms-flex-grow: 8;
    flex-grow: 8;
  }
  .desktop-grow-9 {
    -webkit-flex-grow: 9;
    -moz-flex-grow: 9;
    -ms-flex-grow: 9;
    flex-grow: 9;
  }
  .desktop-grow-10 {
    -webkit-flex-grow: 10;
    -moz-flex-grow: 10;
    -ms-flex-grow: 10;
    flex-grow: 10;
  }
  .desktop-shrink-0 {
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-shrink: 0;
    flex-shrink: 0;
  }
  .desktop-shrink-1 {
    -webkit-flex-shrink: 1;
    -moz-flex-shrink: 1;
    -ms-flex-shrink: 1;
    flex-shrink: 1;
  }
  .desktop-shrink-2 {
    -webkit-flex-shrink: 2;
    -moz-flex-shrink: 2;
    -ms-flex-shrink: 2;
    flex-shrink: 2;
  }
  .desktop-shrink-3 {
    -webkit-flex-shrink: 3;
    -moz-flex-shrink: 3;
    -ms-flex-shrink: 3;
    flex-shrink: 3;
  }
  .desktop-shrink-4 {
    -webkit-flex-shrink: 4;
    -moz-flex-shrink: 4;
    -ms-flex-shrink: 4;
    flex-shrink: 4;
  }
  .desktop-shrink-5 {
    -webkit-flex-shrink: 5;
    -moz-flex-shrink: 5;
    -ms-flex-shrink: 5;
    flex-shrink: 5;
  }
  .desktop-shrink-6 {
    -webkit-flex-shrink: 6;
    -moz-flex-shrink: 6;
    -ms-flex-shrink: 6;
    flex-shrink: 6;
  }
  .desktop-shrink-7 {
    -webkit-flex-shrink: 7;
    -moz-flex-shrink: 7;
    -ms-flex-shrink: 7;
    flex-shrink: 7;
  }
  .desktop-shrink-8 {
    -webkit-flex-shrink: 8;
    -moz-flex-shrink: 8;
    -ms-flex-shrink: 8;
    flex-shrink: 8;
  }
  .desktop-shrink-9 {
    -webkit-flex-shrink: 9;
    -moz-flex-shrink: 9;
    -ms-flex-shrink: 9;
    flex-shrink: 9;
  }
  .desktop-shrink-10 {
    -webkit-flex-shrink: 10;
    -moz-flex-shrink: 10;
    -ms-flex-shrink: 10;
    flex-shrink: 10;
  }
  .desktop-align-self-flex-start {
    -webkit-align-self: flex-start;
    -moz-align-self: flex-start;
    -ms-align-self: flex-start;
    align-self: flex-start;
  }
  .desktop-align-self-flex-end {
    -webkit-align-self: flex-end;
    -moz-align-self: flex-end;
    -ms-align-self: flex-end;
    align-self: flex-end;
  }
  .desktop-align-self-center {
    -webkit-align-self: center;
    -moz-align-self: center;
    -ms-align-self: center;
    align-self: center;
  }
  .desktop-align-self-baseline {
    -webkit-align-self: baseline;
    -moz-align-self: baseline;
    -ms-align-self: baseline;
    align-self: baseline;
  }
  .desktop-align-self-stretch {
    -webkit-align-self: stretch;
    -moz-align-self: stretch;
    -ms-align-self: stretch;
    align-self: stretch;
  }
}
.small-star {
  width: 30px !important;
  height: 20px !important;
}
.small-star .medium {
  width: 30px !important;
  height: 20px !important;
}
.small-star .rating {
  justify-content: start !important;
}

.invisible {
  opacity: 0;
  pointer-events: none;
}

body .crisp-client .cc-1brb6[data-position-reverse=true] .cc-1yy0g .cc-1m2mf, body .crisp-client .cc-1brb6[data-position-reverse=true] .cc-1yy0g .cc-1no03 {
  bottom: 45px !important;
  left: 75px !important;
}