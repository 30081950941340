.invisible {
  opacity: 0;
  pointer-events: none;
}

body{
  .crisp-client .cc-1brb6[data-position-reverse=true] .cc-1yy0g .cc-1m2mf, .crisp-client .cc-1brb6[data-position-reverse=true] .cc-1yy0g .cc-1no03{
    bottom: 45px !important;
    left: 75px !important;
  }
}

