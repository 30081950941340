@import "variables";

*{
  overscroll-behavior: none;

  ::-webkit-scrollbar {
    width: 10px;
    height: 10px;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
  
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888;
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
}

.dialog-rounded-xl{
  .mat-mdc-dialog-surface {
    border-radius: 10px !important;
  }
}
.circle{
  border-radius: 50%;
  width: 18px;
  height: 18px;
}
.fit-cover{
  object-fit: cover;
}
.fit-contain{
  object-fit: contain;
}

.small-circle{
  border-radius: 50%;
  width: 10px;
  height: 10px;
}

.container {
  display: flex;

  padding: 40px;

  &_column {
    flex-direction: column;
  }

  &_center {
    justify-content: center;
  }
}

.loader-container{
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0px;
    left: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $spinner-overlay;
    
    .css-loader {
      width: 55px;
      height: 55px;
      border: 5px solid #9F74F6;
      border-bottom-color: transparent;
      border-radius: 50%;
      display: inline-block;
      box-sizing: border-box;
      animation: rotation 1s linear infinite;
    }
    
  @keyframes rotation {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }
}

.list-container {
  padding: 24px 24px 0 32px;
  background-color: #FAF8FF !important;
  position: sticky;
  top: 25px;
}

.default-tabs {
  background-color: #e2e2e2;
  border: none;
  padding: 0 14px;
  mat-ink-bar {
    background-color: #ffffff !important;
  }
  .mat-tab-label-active {
    background-color: #cecece;
  }
  .mat-tab-link {
    color: black;
    min-width: fit-content;
    text-transform: uppercase;
  }
}

.page-container {
  max-width: 1152px;
  padding: 0 24px;
  padding-top: 32px;
  margin: auto;
}

.border {
  border: 1px solid #e2e2e2;
}

.set-categories-wrap {
  border: 1px solid #ECEAF1 !important;
  border-top-left-radius: 0px !important;
  border-top-right-radius: 0px !important;
  box-shadow: none !important;
}

.background-opacity-white {
  background: rgba(255, 255, 255, 0.7);
}
.hidden{
  width: 0;
  margin: 0;
  padding: 0;
  overflow: hidden;
  height: 0;
}
.overlay-pane-select-style{
  width: auto !important;
  min-width: 200px !important;
}
.confirm-container{
  border-radius: 20px !important;
}
.confirm-dialog{
  width: 455px !important;
  height: 232px !important;
  border-radius: 20px !important;
  box-shadow: 0px 0px 20px 0px #737373;
  overflow: hidden;
}

.custom-tooltip {
  background-color: #fff !important;
  border-radius: 5px;
  color: #3F394E;
  box-shadow: 0px 0px 15px 0px #00000033;
  width: 415px !important;
  right: 200px !important;
  padding: 6px;
  *{
    width: 100% !important;
    max-width: 100% !important;
  }
}

.import-dialog{
  border: 1px solid #ECEAF1;
  border-radius: 20px !important;
  width: 510px !important;
  max-height: 464px !important;
  overflow: hidden;
}

.import-dialog-backdrop{
  background: #00000091;
}

.outlet {
  background-color: white;
}
.small-star{
  .medium{
    width: 30px !important;
  }
  .rating{
    justify-content: start !important;
  }
}
.status-item {
  border-radius: 2px;
  font-size: 14px;
  font-weight: 500;
  padding: 4px;
  &.status-red {
    background-color: #FFDADA;
    color: #FA3838;
  }
  &.status-green {
    background-color: #E5FFE0;
    color: #34A621;
  }
}