.text-center {
  text-align: center;
}
* {
  &:not(mat-icon){
      font-family: 'Poppins', sans-serif;
  }
}
.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.underline {
  border-bottom: 1px solid $underline;
}

.title {
  font-size: 30px;
}

.page-title {
  font-weight: 500;
  font-size: 24px;
  line-height: 32px;
  margin-bottom: 10px;
}

.subtitle {
  font-size: 18px;
  font-weight: bold;

  &_underline {
    border-bottom: 1px solid $black;
  }

  &_padding {
    padding-bottom: 15px;
  }
}

.small-title {
  font-size: 14px;
  font-weight: bold;
}

h2 {
  font-weight: 500;
}

.word-break {
  word-break: break-word;
}

.slate-text-color {
  color: var(--slate-color);
}
.primary-text-color {
  color: var(--primary-color);
}
.list-table-color {
  color: #3F394E;
}
.slate-gray-text {
  color: var(--slate-gray-color);
}

.midnight-text {
  color: var(--midnight-color);
}

.ocean-text {
  color: var(--ocean-color);
}