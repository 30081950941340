@import "variables";

.w-full {
  width: 100%;
}
.w-3\/4 {
  width: 75%;
}
.w-2\/3 {
  width: 66%;
}
.w-1\/2 {
  width: 50%;
}
.w-1\/3 {
  width: 33%;
}
.w-1\/4 {
  width: 25%;
}
.w-1\/5 {
  width: 20%;
}
.w-2\/5 {
  width: 40%;
}
.w-3\/5 {
  width: 60%;
}
.w-1\/6 {
  width: 16.6%;
}
.max-w-fit {
  max-width: fit-content !important;
}
.w-fit{
  width: fit-content;
}
.break-word{
  word-break: break-all;
}
.border-rounded{
  border-radius: 5px;
}
.border-rounded-xl{
  border-radius: 10px !important;
}
.border-rounded-xxl{
  border-radius: 15px !important;
}
.text-center{
  text-align: center;
}
.text-center-i{
  text-align: center !important;
}
.text-right{
  text-align: right !important;
}
.text-underline {
  text-decoration: underline;
}
.txt-error{
  color: #f3545494 !important;
}
.border-error{
  border-radius: 5px;
  border: 1px solid #f3545494 !important;
}
.w-auto{
  width: auto !important;
}
.max-height-full-screen{
  max-height: 100vh;
}
.cursor-pointer{
  cursor: pointer;
}
.cursor-default{
  cursor: default !important;
}
.h-fit { 
  height: fit-content;
}
.h-full {
  height: 100%;
}
.h-full-without-header-footer {
  height: calc(100% - 48px - 80px);
}
.min-h-full {
  min-height: 100%;
}
.min-w-unset {
  min-width: unset !important;
}
.shadow{
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}
.text-bold{
  font-weight: 600;
}
.m-auto{
  margin: auto;
}
.absolute {
  position: absolute;
}
.absolute-i {
  position: absolute !important;
}
.relative {
  position: relative;
}
.relative-i {
  position: relative !important;
}
.cursor-pointer {
  cursor: pointer;
}
.overflow-hidden {
  overflow: hidden;
}
.overflow-auto {
  overflow: auto;
}
.overflow-y-auto {
  overflow-y: auto;
}
.overflow-y-hidden {
  overflow-y: hidden;
}
.overflow-x-auto {
  overflow-x: auto;
}
.overflow-x-hidden {
  overflow-x: hidden;
}
.fixed {
  position: fixed;
}
.bottom-0 {
  bottom: 0px;
}
.right-0 {
  right: 0px;
}
.text-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
}
.bg-white {
  background-color: white !important;
}
.bg-ocean-gray {
  background-color: $ocean-gray !important;
}
.bg-primary-color {
  background-color: $primary-color !important;
}
.no-wrap {
  white-space: nowrap;
}

.text-capitalize {
  text-transform: capitalize;
}

.text-uppercase {
  text-transform: uppercase;
}

.no-shadow {
  box-shadow: none !important;
}

.txt-ocean-color{
  color: $ocean;
}

.txt-ocean-light-color{
  color: $ocean-light;
}

.text-slate{
  color: $slate;
}
.text-white {
  color: $white;
}
.text-grey {
  color: $grey;
}

.text-info {
  color: $info-color;
}

.text-accent-color {
  color: $accent-color;
}

.text-grey-important {
  color: $grey !important;
}

.color-medium-grey {
  color: $medium-grey;
}
.color-grey2 {
  color: $grey2;
}

.color-ocean {
  color: $ocean;
}

.font-weight-500 {
  font-weight: 500;
}

.font-weight-600 {
  font-weight: 600;
}

.font-weight-700 {
  font-weight: 700;
}

.font-weight-800 {
  font-weight: 800;
}

.pointer-events-none{
  pointer-events: none;
}

.text-decoration-none{
  text-decoration: none;
  *{
    text-decoration: none;
  }
}

$media-sizes: (
  "": "min-width: 0em",
  "mobile-": "max-width: 767px",
  "tablet-": "min-width: 768px",
  "desktop-": "min-width: 1201px",
);

$sizesValue: (0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 25, 30, 35, 50, 65, 85, 100);

@each $size, $i in $media-sizes {
  @media (#{$i}) {
    // ICON SIZE
    @each $iconSize in $sizesValue {
      .#{$size}icon-size-#{$iconSize} {
        font-size: #{$iconSize * 2}px;
        line-height: #{$iconSize * 2}px;
        height: #{$iconSize * 2}px !important;
        width: #{$iconSize * 2}px !important;
      }
    }

    // FONT SIZE
    @each $textsize in $sizesValue {
      .#{$size}text-size-#{$textsize} {
        font-size: #{$textsize * 2}px;
      }
    }

    // WIDTH/height
    @each $widthSize in $sizesValue {
      .#{$size}w-#{$widthSize} {
        width: #{$widthSize * 4}px;
      }
      .#{$size}h-#{$widthSize} {
        height: #{$widthSize * 4}px;
      }
      .#{$size}h-#{$widthSize}-i {
        height: #{$widthSize * 4}px !important;
      }
      .#{$size}min-h-#{$widthSize} {
        min-height: #{$widthSize * 4}px;
      }
      .#{$size}min-w-#{$widthSize} {
        min-width: #{$widthSize * 4}px !important;
      }
    }

    @each $space in $sizesValue {
      .#{$size}m-#{$space} {
        margin: #{$space * 4}px;
      }
      .#{$size}mx-#{$space} {
        margin-left: #{$space * 4}px;
        margin-right: #{$space * 4}px;
      }
      .#{$size}my-#{$space} {
        margin-top: #{$space * 4}px;
        margin-bottom: #{$space * 4}px;
      }
      .#{$size}ml-#{$space} {
        margin-left: #{$space * 4}px;
      }
      .#{$size}ml-#{$space}-i {
        margin-left: #{$space * 4}px !important;
      }
      .#{$size}mr-#{$space} {
        margin-right: #{$space * 4}px;
      }
      .#{$size}mr-#{$space}-i {
        margin-right: #{$space * 4}px !important;
      }
      .#{$size}mb-#{$space} {
        margin-bottom: #{$space * 4}px;
      }
      .#{$size}mt-#{$space} {
        margin-top: #{$space * 4}px;
      }
      .#{$size}p-#{$space} {
        padding: #{$space * 4}px;
      }
      .#{$size}p-#{$space}-i {
        padding: #{$space * 4}px !important;
      }
      .#{$size}px-#{$space} {
        padding-left: #{$space * 4}px;
        padding-right: #{$space * 4}px;
      }
      .#{$size}px-#{$space}-i {
        padding-left: #{$space * 4}px !important;
        padding-right: #{$space * 4}px !important;
      }
      .#{$size}py-#{$space} {
        padding-top: #{$space * 4}px;
        padding-bottom: #{$space * 4}px;
      }
      .#{$size}pl-#{$space} {
        padding-left: #{$space * 4}px;
      }
      .#{$size}pr-#{$space} {
        padding-right: #{$space * 4}px;
      }
      .#{$size}pl-#{$space}-i {
        padding-left: #{$space * 4}px !important;
      }
      .#{$size}pr-#{$space}-i {
        padding-right: #{$space * 4}px !important;
      }
      .#{$size}pb-#{$space} {
        padding-bottom: #{$space * 4}px;
      }
      .#{$size}pb-#{$space}-i {
        padding-bottom: #{$space * 4}px !important;
      }
      .#{$size}pt-#{$space} {
        padding-top: #{$space * 4}px;
      }
    }

    // Flex Display
    $flex-display-properties: (
      "-flex": flex,
      "-flex-i": flex !important,
      "-block": block,
      "-grid": grid,
      "-inline": inline-flex,
      "-none": none,
    );
    @each $label, $property in $flex-display-properties {
      .#{$size + "d" + $label} {
        display: -webkit- + $property;
        display: -moz- + $property;
        display: -ms- + $property + box; // IE10 uses -ms-flexbox
        display: -ms- + $property; // IE11
        display: $property;
      }
    }

    // Flex Direction
    $flex-direction-properties: (
      "-row": row,
      "row-reverse": row-reverse,
      "-column": column,
      "-column-reverse": column-reverse,
    );
    @each $label, $property in $flex-direction-properties {
      .#{$size + "direction" + $label} {
        -webkit-flex-direction: #{$property};
        -moz-flex-direction: #{$property};
        -ms-flex-direction: #{$property};
        flex-direction: #{$property};
      }
    }

    // Flex Wrap
    $flex-wrap-properties: (
      "-wrap": wrap,
      "-nowrap": nowrap,
      "-reverse": wrap-reverse,
    );
    @each $label, $property in $flex-wrap-properties {
      .#{$size + "wrap" + $label} {
        -webkit-flex-wrap: #{$property};
        -moz-flex-wrap: #{$property};
        -ms-flex-wrap: #{$property};
        flex-wrap: #{$property};
      }
    }

    // Justify Content
    $justify-content-properties: (
      "-flex-start": flex-start,
      "-flex-end": flex-end,
      "-center": center,
      "-space-between": space-between,
      "-space-around": space-around,
    );
    @each $label, $property in $justify-content-properties {
      .#{$size + "justify" + $label} {
        -webkit-justify-content: #{$property};
        -moz-justify-content: #{$property};
        -ms-justify-content: #{$property};
        justify-content: #{$property};
      }
    }

    // Justify self
    $justify-content-properties: (
      "-flex-start": flex-start,
      "-flex-end": flex-end,
      "-center": center,
      "-space-between": space-between,
      "-space-around": space-around,
    );
    @each $label, $property in $justify-content-properties {
      .#{$size + "justify-self" + $label} {
        -webkit-justify-self: #{$property};
        -moz-justify-self: #{$property};
        -ms-justify-self: #{$property};
        justify-self: #{$property};
      }
    }

    // Align Content
    $align-content-properties: (
      "-flex-start": flex-start,
      "-flex-end": flex-end,
      "-center": center,
      "-space-between": space-between,
      "-space-around": space-around,
      "-stretch": stretch,
    );
    @each $label, $property in $align-content-properties {
      .#{$size + "align-content" + $label} {
        -webkit-align-content: #{$property};
        -moz-align-content: #{$property};
        -ms-align-content: #{$property};
        align-content: #{$property};
      }
    }

    // Align Items
    $align-items-properties: (
      "-flex-start": flex-start,
      "-flex-end": flex-end,
      "-center": center,
      "-baseline": baseline,
      "-stretch": stretch,
    );
    @each $label, $property in $align-items-properties {
      .#{$size + "align-items" + $label} {
        -webkit-align-items: #{$property};
        -moz-align-items: #{$property};
        -ms-align-items: #{$property};
        align-items: #{$property};
      }
    }

    // Flex Order
    $flex-order-properties: (0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10);
    @each $property in $flex-order-properties {
      .#{$size + "order-" + $property} {
        -webkit-order: #{$property};
        -moz-order: #{$property};
        -ms-order: #{$property};
        order: #{$property};
      }
    }

    // Flex Grow
    $flex-grow-properties: (0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10);
    @each $property in $flex-grow-properties {
      .#{$size + "grow-" + $property} {
        -webkit-flex-grow: #{$property};
        -moz-flex-grow: #{$property};
        -ms-flex-grow: #{$property};
        flex-grow: #{$property};
      }
    }

    // Flex Shrink
    $flex-shrink-properties: (0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10);
    @each $property in $flex-grow-properties {
      .#{$size + "shrink-" + $property} {
        -webkit-flex-shrink: #{$property};
        -moz-flex-shrink: #{$property};
        -ms-flex-shrink: #{$property};
        flex-shrink: #{$property};
      }
    }

    // Align self
    $align-self-properties: (
      "-flex-start": flex-start,
      "-flex-end": flex-end,
      "-center": center,
      "-baseline": baseline,
      "-stretch": stretch,
    );
    @each $label, $property in $align-self-properties {
      .#{$size + "align-self" + $label} {
        -webkit-align-self: #{$property};
        -moz-align-self: #{$property};
        -ms-align-self: #{$property};
        align-self: #{$property};
      }
    }
  }
}
