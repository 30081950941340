.default {
  border-radius: 15px !important;
  border-color: transparent;
  height: 50px !important;
  color: #fff !important;
  font-weight: 600;
  font-size: 16px;
  line-height: 25.5px;
  &[disabled]{
    background-color: #D3D3D3 !important;
    color: #7A7A7A !important;
  }
}

.medium {
  height: 40px;
  font-size: 12px;
  font-weight: 400;
  min-width: 90px !important;
  border-radius: 4px !important;
  white-space: nowrap;
}

.link {
  font-weight: 500;
  color: $accent-color;
}

button.mat-ocean {
  background: $ocean;
  color: white;
}

button.mat-accent-green {
  background: $accent-green;
  color: white;
}


button.clear {
  background: none;
  padding: 0;
  outline: none;
  border: none;
  cursor: pointer;
}

mat-slide-toggle {
  button.mdc-switch {
    .mdc-switch__track {
      height: 20px !important;
      width: 35px !important;
      border-radius: 12px !important;
    }

    &:enabled .mdc-switch__track::after {
      background: var(--mdc-switch-selected-track-color, #29B912) !important;
    }

    &:enabled .mdc-switch__track::before {
      background: var(--mdc-switch-selected-track-color, #CBCBCB) !important;
    }

    &.mdc-switch--selected:enabled .mdc-switch__handle::after {
      background: #ffffff !important;
    }
    &.mdc-switch--selected:enabled .mdc-switch__handle::before {
      background: #ffffff !important;
    }
    &.mdc-switch--unselected:enabled .mdc-switch__handle::after {
      background: #ffffff !important;
    }
    .mdc-switch__handle {
      width: 16px !important;
      height: 16px !important;
      left: 2px !important;
    }
  }
}
.button {
  height: 48px !important;
  border-radius: 12px !important;
  min-width: 188px !important;
  font-size: 12px!important;
  font-weight: 500!important;
  line-height: 16.88px!important;
  letter-spacing: 0.375px!important;
  text-align: center!important;
}
button.set-category-btn.mat-mdc-raised-button.mat-accent {
  height: 50px!important;
  min-width: 200px!important;
  border: 1px solid #7E54D4!important;
  color: #7E54D4!important;
  background-color: #fff!important;
  border-radius: 8px!important;
  p{
    line-height: 14px;
    font-size: 14px;
  }
  .mdc-button__label {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    justify-content: space-between;
  }
}
button.categorys-btn {
  color: #7A728D;
  background: #BDA5F536;
  min-width: 115px;
  height: 25px;
  border-radius: 2px;
  font-weight: 500;
  border: none;
  font-size: 14px;
}
.large-checkbox {
  width: 50px;
  height: 50px;
  border-radius: 8px;
}