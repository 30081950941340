@import 'theme';
@import "~normalize.css";
@import 'node_modules/css-star-rating/scss/star-rating.scss';

* {
  box-sizing: border-box;
}

.cdk-overlay-pane {
  max-height: unset !important;
}

.link {
  transition: color .23s ease;
  cursor: pointer;

  &,
  &:active,
  &:visited {
    color: #0A8FEB;
    text-decoration: none;
  }

  &:hover {
    color: #66bfff;
  }
}

.flex-initial {
  display: flex;
  align-items: center;
  justify-content: center;
}
.mat-mdc-tab-body-wrapper {
  height: 100%;
}

app-container-operations {
  background: #faf8ff;
}