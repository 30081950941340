
.small-star{
  width: 30px !important;
  height: 20px !important;
  .medium{
    width: 30px !important;
    height: 20px !important;
  }
  .rating{
    justify-content: start !important;
  }
}